import React, { useState, useEffect, useCallback } from "react";
import "./assets/css/style.css";
import {Form, Input, Button, Row, Col, Radio, DatePicker, Card,notification,} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { listAccount } from "./redux/actions/accountActions";
import Axios from "axios"
import PrintFf from "./print/PrintFf"


  // compte Corporate pour test 0201510107887225

  // compte Particulier pour test 0202820100346719

export default function FraisF() {
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

  const accountList = useSelector((state) => state.accountList);

  const { comptes, error } = accountList;
  const { Search } = Input;
  
  const [type_client, settype_client] = useState("")

  const nom_client =(type_client==='C' && comptes.length !== 0) ? comptes[0].RAISON_SACIALE : (type_client==='P' && comptes.length !== 0) ? comptes[0].PRENOM : "";

  const prenoms_client = (type_client==='C' && comptes.length !== 0) ? comptes[0].PRENOM_PROMOTEUR : (type_client==='P' && comptes.length !== 0) ? comptes[0].LAST_NAME : "";
  
  const numero_compte = comptes.length !== 0 ? comptes[0].COMPTE : "";

  const email = comptes.length !== 0 ? comptes[0].EMAIL : "";

  const cni = (type_client==='C' && comptes.length !== 0) ? comptes[0].CNI_PROMOTEUR : (type_client==='P' && comptes.length !== 0) ? comptes[0].CARTE_ID : "";

  const telephone = (type_client==='C' && comptes.length !== 0) ? comptes[0].PHONE : (type_client==='P' && comptes.length !== 0) ? comptes[0].MOBILE : "";
  
  
 const adresse_postale = (type_client==='C' && comptes.length !== 0) ? comptes[0].ADRESSE_POSTALE : (type_client==='P' && comptes.length !== 0) ? comptes[0].ADRESSE_POSTAL : "";

  const activite = comptes.length !== 0 ? comptes[0].ACTIVITE : "";

  const ville = (type_client==='C' && comptes.length !== 0) ? comptes[0].LIEU_NAISS_PROMOTEUR : (type_client==='P' && comptes.length !== 0) ? comptes[0].VILLE : "";
 
  //const ville = comptes.length !== 0 ? comptes[0].VILLE : "";

 // const DateNai = comptes.length !== 0 ? comptes[0].DATE_NAISSANCE : "";

  const DateNai = (type_client==='C' && comptes.length !== 0) ? comptes[0].DATE_NAISS_PROMOTEUR : (type_client==='P' && comptes.length !== 0) ? comptes[0].DATE_NAISSANCE : "";

  const code_agence = userInfo ? userInfo[0].code_agence : "";
  const code_banque = userInfo ? userInfo[0].code_banque : "";
  const libelle_agence = userInfo ? userInfo[0].libelle_agence : "";
  const nom_agent = userInfo ? userInfo.nom : "";

  const [dateeffet, setdateeffet] = useState("");
  const [benef1, setbenef1] = useState("")
  const [pourcentage1, setpourcentage1] = useState("")   
  const [relation1, setrelation1] = useState("")
  const [benef2, setbenef2] = useState("");
  const [pourcentage2, setpourcentage2] = useState("");
  const [relation2, setrelation2] = useState("");
  const [benef3, setbenef3] = useState("");
  const [pourcentage3, setpourcentage3] = useState(""); 
  const [relation3, setrelation3] = useState("");
  const [option, setoption] = useState("OPTION_I")
  
  const [numeroUnique, setnumeroUnique] = useState("")
  const [nom_prenoms_assure, setnom_prenoms_assure] = useState("")
  

  const [nomApporteur, setnomApporteur] = useState("")

  const [printSee, setprintSee] = useState(false);
  const [saved, setsaved] = useState(false);

  const [numero_police, setnumero_police] = useState("");

  // 18-11-23   11-45
  const [numAdhesion, setnumAdhesion] = useState("")
  const [socialReason, setsocialReason] = useState("")
  const [subscriberPhone, setsubscriberPhone] = useState("")
  const [subscriberPostal, setsubscriberPostal] = useState("")
  const [subscriberCity, setsubscriberCity] = useState("")
  const [employerShare, setemployerShare] = useState("")
  const [employeeShare, setemployeeShare] = useState("")
  const [grossSalary, setgrossSalary] = useState("")
  const [monthlyContribution, setmonthlyContribution] = useState("")
  const [authorizedCollection, setauthorizedCollection] = useState(false)
  const [numeroPolice, setnumeroPolice] = useState("")
  const [insuredCapital, setinsuredCapital] = useState("")
  const [insuredFunction, setinsuredFunction] = useState("")
  const [dateBirth, setdateBirth] = useState("")
  const [dateHiring, setdateHiring] = useState("")
  const [activity, setactivity] = useState("")
  const [souscripteurName, setsouscripteurName] = useState(nom_client)
  
  const [addressbenef1, setaddressbenef1] = useState("")
  const [addressbenef2, setaddressbenef2] = useState("")
  const [addressbenef3, setaddressbenef3] = useState("")  
  

  //const options = option === OPTION_I ? OPTION_I : OPTION_II;

  const [options, setoptions] = useState("");

  useEffect( ()=>{

   
    const fetchData  = async () => {
      
      const OPTION_I = {
        capital_nfc: 250000,
        capital_benef: 250000,
        prime_ttc: 5963,
      };
    
      const OPTION_II = {
          capital_nfc: 500000,
          capital_benef: 500000,
          prime_ttc: 11925,
      };

      const OPTION_III = {
        capital_nfc: 750000,
        capital_benef: 750000,
        prime_ttc: 17888,
      };
    
      const OPTION_IV = {
          capital_nfc: 1000000,
          capital_benef: 1000000,
          prime_ttc: 23850,
      };

      const OPTION_V = {
        capital_nfc: 2500000,
        capital_benef: 2500000,
        prime_ttc: 59625,
      };
    
      const OPTION_VI = {
          capital_nfc: 5000000,
          capital_benef: 5000000,
          prime_ttc: 119250,
      };

      var optionss = option === 'OPTION_I' ? OPTION_I : option === 'OPTION_II' ? OPTION_II : option === 'OPTION_III' ? OPTION_III : option === 'OPTION_IV' ? OPTION_IV : option === 'OPTION_V' ? OPTION_V : option === 'OPTION_VI' ? OPTION_VI:OPTION_VI;
      
           
       
      await setoptions(optionss);

      console.log('options:'+JSON.stringify(optionss));

      console.log('option:'+option);
    }

    fetchData()
    // make sure to catch any error
    .catch(console.error)

    
   
   
  }, [option])

  // useEffect( ()=>{ 
    
  //   // 0201510204671109

  //   console.log('nom_client:'+nom_client);

  //   console.log('prenom_client:'+prenoms_client);

  //   console.log('dateeffet:'+dateeffet); 

  //   //this.nom_client='';
   
  // }, [type_client] 
  // )


  const listBenef = {
    pourcentage1,
    relation1,
    benef1,
    pourcentage2,
    relation2,
    benef2,
    pourcentage3,
    relation3,
    benef3,
  };
  
  const formData = {
    options,
    nom_client,
    prenoms_client,
    numero_compte,
    email,
    cni,
    telephone,
    adresse_postale,
    activite,
    ville,
    numeroUnique,
    nom_prenoms_assure,
    DateNai,
    code_agence,
    code_banque,
    libelle_agence,
    nom_agent,
    dateeffet,
    option,
    type_operation: "c",
    listBenef,
    type_client: "parraine",
    nomApporteur,
  };

  const printData = {
    nom_client,
    prenoms_client,
    numero_compte,
    email,
    cni,
    telephone,
    adresse_postale,
    activite,
    ville,
    numeroUnique,
    nom_prenoms_assure,
    DateNai,
    code_agence,
    libelle_agence,
    nom_agent,
    dateeffet,
    pourcentage1,
    relation1,
    benef1,
    addressbenef1,
    pourcentage2,
    relation2,
    benef2,
    addressbenef2,
    pourcentage3,
    relation3,
    benef3,
    addressbenef3,
    option,
    printSee,
    nomApporteur,
    numero_police,
    numAdhesion,
    souscripteurName,
    socialReason,
    subscriberPhone,
    subscriberPostal,
    subscriberCity,
    employerShare,
    employeeShare,
    grossSalary,
    monthlyContribution,
    authorizedCollection,
    numeroPolice,
    insuredCapital,
    insuredFunction,
    dateBirth,
    activity,
  };

  const openNotificationWithIcon = () => {
    notification.success({
      message: "Ajout contrat",
      description: "Contrat ajouter avec success.",
      style: {
        marginTop: 50,
      },
    });
  };

  const openNotificationWithIconError = () => {
    notification.error({
      message: "Echec Ajout contrat",
      description: "Contrat non Enregistré ; Merci de recommencer.",
      style: {
        marginTop: 50,
      },
    });
  };

 

  const sendForm = () => {
    // console.log(formData);
    Axios({
      method: "post",
      url: "/prubli_ma/ss/save_contrat_cp_new.php",
      data: formData,
     // headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        openNotificationWithIcon();
        setsaved(true);
        setprintSee(true);      
        
        setnumero_police(response['data'][0].numero_police);

      })
      .catch(function (response) {
        
        setsaved(true);

        openNotificationWithIconError();  

        console.log('yes error:'+response);      

        // setTimeout(() => {
        //   window.location.reload(true);  0201510104671190
        // }, 5000);

      });
  };

  const onSearch = (value) => {
    const accountData = new FormData();
    accountData.append("numero_compte", value);
    accountData.append("type_client", type_client);
    accountData.append("type_recherche", "C");
    dispatch(listAccount(accountData));

    setdateeffet('0');
    
  };

  return (
    <>
      <h2 className="text-center mb-3">Frais Funéraires</h2>     
      <Col className="mb-3 d-flex" span={24}>
        <div className="col-6 d-flex">
        
          <h5>Type De Compte</h5>                    
                  
          <div
            className="col-6 d-flex"
            onChange={(e) => settype_client(e.target.value)}
          >
            <div class="col-6">
              <strong>Corporate</strong>{" "}
              <input
                type="radio"
                id="type_client"
                name="type_client"
                value="C"
              />
            </div>
            {/* <div class="col-6">
              <strong>Particulier</strong>{" "}
              <input
                type="radio"
                id="type_client"
                name="type_client"
                value="P"
              />
            </div> */}
           
        </div>
        </div>
        <div className="col-3 d-flex">        
        <h5>Numéro De Compte</h5>{" "}
      
        </div>
        <div className="col-3 d-flex">        
          <Search
            placeholder="input search text"
            onSearch={onSearch}
            allowClear
            enterButton
          />
        </div>
      </Col>
     
      <div class="">
      
        <Card style={{ minHeight: "60vh" }}>
          {error ? (
            <h1>{error} </h1>
          ) : (
            <>
              {comptes.length !== 0 && (
                <>
                  {" "}
                  <h5 className="text-center mb-3" style={{color: "red"}}>{comptes.message_contrat_c}</h5>

                     
                  <div class="mb-3">
                    <div class="row">
                      <div class="col-4">
                        Chargé de clientèle: <strong>{nom_agent}</strong>
                      </div>
                      <div class="col-4">
                        Agence: <strong>{libelle_agence}</strong>
                      </div>
                      <div class="col-4">
                        Code:<strong>{code_agence}</strong>{" "}
                      </div>
                      <div class="col-4 italic">Account Manager</div>
                      <div class="col-4 italic">Branch</div>
                    </div>
                  </div>

                  {" "}



                  <div class="col-12 text-center mb-5 ">
                    <strong>
                      INFORMATION SOUSCRIPTEUR/
                      <span class="italic_main">
                        {" "}
                        SUBSCRIBER INFORMATION.
                      </span>
                    </strong>
                  </div>

                  <Row>
                    <Col className="mr-2" span={7}>
                        {" "}     

                        <Form.Item
                          label="Police N"
                          name="Police N"
                        >
                        <Input
                          id="numeroPolice"
                          value={numeroPolice}
                          type="text"
                          placeholder="Police N"
                          onChange={(e) => setnumeroPolice(e.target.value)}
                        />
                        </Form.Item>

                        {/* <Form.Item
                        label="Nom souscripteur"
                        name="Nom souscripteur"
                        >
                        <Input
                          id="souscripteurName"
                          value={souscripteurName}
                          type="text"
                          placeholder="Nom souscripteur"
                          onChange={(e) => setsouscripteurName(e.target.value)}
                        />
                        </Form.Item> */}

                        <Form.Item label="Nom Souscripteur" name="Nom du client">
                          <strong>{nom_client}</strong>
                        </Form.Item>

                        {/* <Form.Item
                        label="Raison sociale"
                        name="Raison sociale"
                        >
                        <Input
                          id="socialReason"
                          value={socialReason}
                          type="text"
                          placeholder="Raison sociale"
                          onChange={(e) => setsocialReason(e.target.value)}
                        />
                        </Form.Item> */}

                       

                    </Col>

                    <Col className="mr-4 ml-5" span={7}>
                        {" "}     

                        <Form.Item
                        label="Adhésion N°"
                        name="Adhésion N°"
                        >
                        <Input
                          id="numAdhesion"
                          value={numAdhesion}
                          type="text"
                          placeholder="Adhésion N°"
                          onChange={(e) => setnumAdhesion(e.target.value)}
                        />
                        </Form.Item>

                        {/* <Form.Item
                          label="Secteur d'Activité"
                          name="Secteur d'Activité"
                        >
                        <Input
                          id="idUnique"
                          value={numeroUnique}
                          type="text"
                          placeholder="Secteur d'Activité"
                          onChange={(e) => setnumeroUnique(e.target.value)}
                        />
                        </Form.Item>                        */}

                        <Form.Item label="Activité" name="Activité">
                          <strong>{activite}</strong>
                          {/* <Input
                            id="activity"
                            value={activite}
                            type="text"
                            placeholder="Activité"
                            onChange={(e) => setactivity(e.target.value)}
                          /> */}
                        </Form.Item>

                        {/* <Form.Item
                        label="Ville"
                        name="Ville"
                        >
                        <Input
                          id="subscriberCity"
                          value={subscriberCity}
                          type="text"
                          placeholder="Ville"
                          onChange={(e) => setsubscriberCity(e.target.value)}
                        />
                        </Form.Item> */}

                    </Col>

                    <Col className="mr-4 ml-5" span={7}>
                        {" "}     

                        <Form.Item
                        label="Capital Assuré"
                        name="apital Assuré"
                        >
                        <Input
                          id="insuredCapital"
                          value={insuredCapital}
                          type="number"
                          placeholder="Capital Assuré"
                          onChange={(e) => setinsuredCapital(e.target.value)}
                        />
                        </Form.Item>

                        {/* <Form.Item
                          label="Tél"
                          name="Tél"
                        >
                        <Input
                          id="subscriberPhone"
                          value={subscriberPhone}
                          type="text"
                          placeholder="Tél"
                          onChange={(e) => setsubscriberPhone(e.target.value)}
                        />
                        </Form.Item>                        */}

                        <Form.Item label="Tél" name="Tél">
                          <strong>{telephone}</strong>
                          {/* <Input
                          id="subscriberPhone"
                          value={subscriberPhone}
                          type="text"
                          placeholder="Tél"
                          onChange={(e) => setsubscriberPhone(e.target.value)}
                        /> */}
                        </Form.Item>

                        {/* <Form.Item
                        label="N RC"
                        name="N RC"
                        >
                        <Input
                          id="nomApporteur"
                          value={nomApporteur}
                          type="text"
                          placeholder="N RC"
                          onChange={(e) => setnomApporteur(e.target.value)}
                        />
                        </Form.Item> */}

                    </Col>

                    {/*  */}
               
                  </Row>

                  <div class="col-12 text-center mb-5">
                    <strong>
                      ASSURE/
                      <span class="italic_main">
                        {" "}
                        INSURED...
                      </span>
                    </strong>
                  </div>


               
                  <Row>
                    <Col className="mr-2" span={7}>

                    <Form.Item
                        label="Nom Et Prenoms"
                        name="Nom Et Prenoms"
                      >
                        <Input
                          id="idUnique"
                          value={nom_prenoms_assure}
                          type="text"
                          placeholder="Nom Et Prenoms"
                          onChange={(e) => setnom_prenoms_assure(e.target.value)}
                        />
                        </Form.Item>

                    <Form.Item
                            label=" Date Embauche /Date Hiring"
                            name="Date Embauche /Date Hiring"
                            rules={[
                              {
                                required: true,
                                message: " Date de Naissance/Date of birth ",
                              },
                            ]}
                          > {" "}
                            <Input
                              id="dateHiring"
                              type="date"
                              value={dateHiring}
                              onChange={(e) => setdateHiring(e.target.value)}
                              placeholder="Date Embauche/Date of Hiring"
                            />
                          </Form.Item>{" "}

                       <Form.Item
                            label=" Date Naissance /Date Birth"
                            name="Date Naissance /Date Birth"
                            rules={[
                              {
                                required: true,
                                message: " Date de Naissance/Date of birth ",
                              },
                            ]}
                          > {" "}
                            <Input
                              id="dateBirth"
                              type="date"
                              value={dateBirth}
                              onChange={(e) => setdateBirth(e.target.value)}
                              placeholder="Date Naissance/Date of Birth"
                            />
                          </Form.Item>{" "}

                    </Col>

                    {/*  */}

                    <Col className="mr-4 ml-5" span={7}>
                      {" "}
                                     
                      <Form.Item
                        label="Fonction"
                        name="Fonction"
                      >
                        <Input
                          id="insuredFunction"
                          value={insuredFunction}
                          type="text"
                          placeholder="Fonction"
                          onChange={(e) => setinsuredFunction(e.target.value)}
                        />
                        </Form.Item>


                      <Form.Item label="N° du compte" name="compte">
                        <strong>{comptes[0].COMPTE}</strong>
                      </Form.Item>  

                       <Form.Item
                        label="Nombre D'enfant"
                        name="Nombre D'enfant"
                      >
                        <Input
                          id="idUnique"
                          value={numeroUnique}
                          type="text"
                          placeholder="Nombre D'enfant"
                          onChange={(e) => setnumeroUnique(e.target.value)}
                        />
                        </Form.Item>                   
                      
                   
                    </Col>

                    <Col className="mr-4 ml-5" span={7}>
                      {" "}
                  
                      <Form.Item
                        label="Situation Familiale"
                        name="Situation Familiale"
                      >
                        <Input
                          id="idUnique"
                          value={numeroUnique}
                          type="text"
                          placeholder="Situation Familiale"
                          onChange={(e) => setnumeroUnique(e.target.value)}
                        />
                        </Form.Item>

                       


                        <Form.Item
                        label="Nom Apporteur"
                        name="Nom Apporteur"
                      >
                        <Input
                          id="nomApporteur"
                          value={nomApporteur}
                          type="text"
                          placeholder="Nom Apporteur"
                          onChange={(e) => setnomApporteur(e.target.value)}
                        />

                      </Form.Item>
                    </Col>
                  </Row>

                  <div class="">
                    <div class="row">
                      <div class="col-12 text-center mb-5">
                        <strong>
                          Bénéficiaire(s) en cas de décés ou d’invalidité /
                          <span class="italic_main">
                            {" "}
                            Beneficiaries in event of Death or Invalidity.
                          </span>
                        </strong>
                      </div>
                      <Row>
                        {" "}
                        <Col className="mr-3" span={6}>
                          {" "}
                          <Form.Item
                            label="Nom et Prénoms / Name and Given names"
                            name="Nom et Prénoms"
                            rules={[
                              {
                                required: true,
                                message: " Nom et Prénoms ",
                              },
                            ]}
                          >
                            <Input
                              id="idUnique"
                              type="text"
                              value={benef1}
                              onChange={(e) => setbenef1(e.target.value)}
                              placeholder="Nom et Prénoms / Name and Given names"
                            />
                          </Form.Item>{" "}

                          
                        </Col>{" "}
                        <Col className="mr-3" span={5}>
                          {" "}
                          <Form.Item
                            label=" % de réparation /Portion in %"
                            name="réparation"
                            rules={[
                              {
                                required: true,
                                message: " réparation ",
                              },
                            ]}
                          >
                            <Input
                              id="idUnique"
                              type="number"
                              value={pourcentage1}
                              onChange={(e) => setpourcentage1(e.target.value)}
                              placeholder="% de réparation /Portion in %"
                            />
                          </Form.Item>{" "}

                          
                        </Col>{" "}
                        <Col className="mr-3" span={5}>
                          {" "}
                          <Form.Item
                            label="Date de Naissance /Date of birth"
                            name="Date de Naissance /Date of birth"
                          >
                            <Input
                              id="idUnique"
                              type="date"
                              value={relation1}
                              onChange={(e) => setrelation1(e.target.value)}
                              placeholder="Date de Naissance /Date of birth"
                            />
                          </Form.Item>{" "}                          
                        </Col>{" "}
                        <Col  className="mr-3" span={5}>
                          {" "}
                          <Form.Item
                            label=" Adresse / Address"
                            name="Adresse / Address"
                            rules={[
                              {
                                required: true,
                                message: " Adresse /Address ",
                              },
                            ]}
                          >
                            <Input
                              id="idUnique"
                              type="text"
                              value={addressbenef1}
                              onChange={(e) => setaddressbenef1(e.target.value)}
                              placeholder="Adresse /Address"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        {/*  */}
                        <Col className="mr-3" span={6}>
                          {" "}
                          <Form.Item name="Nom et Prénoms">
                            <Input
                              id="idUnique"
                              type="text"
                              value={benef2}
                              onChange={(e) => setbenef2(e.target.value)}
                              placeholder="Nom et Prénoms / Name and Given names"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col className="mr-3" span={5}>
                          {" "}
                          <Form.Item name="réparation">
                            <Input
                              id="idUnique"
                              type="number"
                              value={pourcentage2}
                              onChange={(e) => setpourcentage2(e.target.value)}
                              placeholder="% de réparation /Portion in %"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col span={5}>
                          {" "}
                          <Form.Item name="Date de Naissance /Date of birth">
                            <Input
                              id="idUnique"
                              type="date"
                              value={relation2}
                              onChange={(e) => setrelation2(e.target.value)}
                              placeholder="Date de Naissance /Date of birth"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col className="mr-3" span={5}>
                          {" "}
                          <Form.Item   name="Adresse / Address"                         
                          >
                            <Input
                              id="idUnique"
                              type="text"
                              value={addressbenef2}
                              onChange={(e) => setaddressbenef2(e.target.value)}
                              placeholder="Adresse /Address"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        {/*  */}
                        <Col className="mr-3" span={6}>
                          {" "}
                          <Form.Item name="Nom et Prénoms">
                            <Input
                              id="idUnique"
                              type="text"
                              value={benef3}
                              onChange={(e) => setbenef3(e.target.value)}
                              placeholder="Nom et Prénoms / Name and Given names"
                            />
                          </Form.Item>{" "}
                          
                        </Col>{" "}
                        <Col className="mr-3" span={5}>
                          {" "}
                          <Form.Item name="réparation">
                            <Input
                              id="idUnique"
                              type="number"
                              value={pourcentage3}
                              onChange={(e) => setpourcentage3(e.target.value)}
                              placeholder="% de réparation /Portion in %"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col span={5}>
                          {" "}
                          <Form.Item name="Date de Naissance /Date of birth">
                            <Input
                              id="idUnique"
                              type="date"
                              value={relation3}
                              onChange={(e) => setrelation3(e.target.value)}
                              placeholder="Date de Naissance /Date of birth"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col className="mr-3" span={5}>
                          {" "}
                          <Form.Item   name="Adresse / Address"                         
                          >
                            <Input
                              id="idUnique"
                              type="text"
                              value={addressbenef3}
                              onChange={(e) => setaddressbenef3(e.target.value)}
                              placeholder="Adresse /Address"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                      </Row>
                    </div>
                  </div>

                  <div class="">
                  
                  {/* <div class="col-12 text-center mb-5">
                        <strong>
                          GARANTIES/
                          <span class="italic_main">
                            {" "}
                            GUARANTIEE
                          </span>
                        </strong>
                      </div> */}

             
{/* 
                    <div class="row">
                      <Form.Item
                        label="Date d'effet de la garantie"
                        name="Date de garantie"
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          className=""
                          id="date"
                          onChange={(date, dateString) =>
                            setdateeffet(dateString)
                          }
                          format={dateFormatList}
                        />
                      </Form.Item>{" "}
                    </div>
                   */}

                    {/* <Row>
                    <Col className="mr-3" span={22}>
                        {" "}     

                        <Form.Item
                          label="J’autorise mon employeur à prélever sur mon salaire mensuel, ma quote-part de: (FCFA/mois)"
                          name="J’autorise mon employeur à prélever sur mon salaire mensuel, ma quote-part de (FCFA/mois)"
                        >
                        <Input
                          id="authorizedCollection"
                          value={authorizedCollection}
                          type="checkbox"
                          placeholder="J’autorise mon employeur à prélever sur mon salaire mensuel, ma quote-part de: (FCFA/mois)"
                          onChange={(e) => setauthorizedCollection(e.target.checked)}
                        />
                        </Form.Item>

                        <Form.Item
                          label="Montant Cotisation Brute"
                          name="Montant Cotisation Brute"
                        >
                        <Input
                          id="monthlyContribution"
                          value={monthlyContribution}
                          type="number"
                          placeholder="Montant Cotisation Brute"
                          onChange={(e) => setmonthlyContribution(e.target.value)}
                        />
                        </Form.Item>

                        <Form.Item
                          label="salaire brut"
                          name="salaire brut"
                        >
                        <Input
                          id="grossSalary"
                          value={grossSalary}
                          type="number"
                          placeholder="salaire brut"
                          onChange={(e) => setgrossSalary(e.target.value)}
                        />
                        </Form.Item>

                        <Form.Item
                          label="Part employeur"
                          name="Part employeur"
                        >
                        <Input
                          id="employerShare"
                          value={employerShare}
                          type="number"
                          placeholder="Part employeur"
                          onChange={(e) => setemployerShare(e.target.value)}
                        />
                        </Form.Item>

                        <Form.Item
                          label="Part employé"
                          name="Part employé"
                        >
                        <Input
                          id="employeeShare"
                          value={employeeShare}
                          type="number"
                          placeholder="Part employé"
                          onChange={(e) => setemployeeShare(e.target.value)}
                        />
                        </Form.Item>       

                    </Col>

                 

                   
               
                  </Row> */}


                  </div>
               
               
                  <div className="row mt-5">
                    <div className="col-10"></div>
                    <div className="d-flex col-2 justify-content-around">
                      {" "}
                      {(souscripteurName !== undefined || nom_client !== undefined ) && (dateeffet.length > 5) && 
                        ((souscripteurName.length) > 5 || (nom_client.length) > 5) && (<Button    //   compte Particulier pour test 0202820100346719
                        onClick={sendForm}
                        type="primary"
                        className="login-form-button "
                        disabled={saved}
                      >
                        Enregistrer
                      </Button> )}
                      <PrintFf printData={printData} />
                      {/* printData */}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </Card>
      </div>
    </>
  );
}
