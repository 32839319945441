import React, { useState, useEffect } from "react";
import { Form, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { listUsers } from "./redux/actions/userActions";
import { exportdon } from "./redux/actions/userActions";
import { notification } from "antd";
import { clearSuccess } from "./redux/actions/userActions";

export default function ExportData() {

  const [datedebut, setdatedebut] = useState("");
  const [datefin, setdatefin] = useState("");


  const formData = new FormData();
  formData.append("datedebut", datedebut);
  formData.append("datefin", datefin);

  const clearState = () => {

    setTimeout(function () {
   /*   window.location.reload(1);*/
    }, 5000);
  };

  const openNotificationWithIcon = () => {
    notification.success({
      message: "Exportation Reussie",
      description: "Export Data.",
      style: {
        marginTop: 50,
      },
    });
  };

  const userSignin = useSelector((state) => state.userSignin);
  //const userList = useSelector((state) => state.usersList);
  const userRegister = useSelector(state => state.userRegister)
  const { messageR } = userRegister;

  useEffect(() => {
    dispatch(listUsers());
  }, []);


  const { loading } = userSignin;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(exportdon(formData));

  };

  useEffect(() => {
    //dispatch(exportdon());
    if (messageR) {
      openNotificationWithIcon();
      clearState();
      dispatch(clearSuccess());
    }
  }, [dispatch, messageR]);

  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

  return (
    <>
      <section class="signup-content">
        <div className="signup-box" style={{ "width": "50%" }}>
          <form onSubmit={submitHandler}>
            <h5 className="login-head">
              <i className="fa fa-lg fa-fw fa-user"></i>Exporter Les Données
            </h5>

            <div className="col-12">
            </div>

            <div className="col-12">
              {" "}
              <Form.Item
                label="Date debut"
                name="Date debut"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  className=""
                  value={datedebut}
                  onChange={(date, dateString) => setdatedebut(dateString)}
                  format={dateFormatList}
                />
              </Form.Item>{" "}
              <Form.Item
                label="Date de fin"
                name="Date de fin"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  className=""
                  id="date"
                  onChange={(date, dateString) => setdatefin(dateString)}
                  format={dateFormatList}
                />
              </Form.Item>
            </div>
            <div className="form-group btn-container">
              <button className="btn btn-primary btn-block" htmltype="submit">
                {loading && (
                  <div>
                    <i className="fa fa-refresh fa-spin mr-2"></i> Exporter Données
                  </div>
                )}
                {!loading && <div>Exporter Données</div>}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
