import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import Axios from "axios";
import { listAgence } from "./redux/actions/agenceActions";
import { useDispatch, useSelector } from "react-redux";
import { listUsers } from "./redux/actions/userActions";




export default function TransfertUser({ users, id }) {
  const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [agencechange, setagence] = useState("")
  const dispatch = useDispatch();
  const agenceList = useSelector((state) => state.agenceList);
  const { agence } = agenceList;

  const details = users.filter((user, index) => {
    return user.id === id;
  });
  const showModal = () => {
    setVisible(true);
  };
      const userData = new FormData();
      userData.append("code_utilisateur", details[0].code_utilisateur);
      userData.append("code_agence", agencechange);

  const changeAgence = () => {
    Axios({
      method: "post",
      url: "/prubli_ma/ss/maj_transfert_utilisateur.php",
      data: userData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const handleOk = () => {
      setConfirmLoading(true);
      changeAgence();
    setTimeout(() => {
        setVisible(false);
        dispatch(listUsers());
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };
  useEffect(() => {
    dispatch(listAgence());
  }, [dispatch]);

  return (
    <>
      <Button
        style={{
          backgroundColor: "blue",
          color: "white",
        }}
        shape="circle"
        size="small"
        onClick={showModal}
      >
        {" "}
        T
      </Button>
      <Modal
        title="Changer agence"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        Nom: <h4> {details[0].nom} </h4>
        Code agence: <h4>{details[0].code_agence} </h4>
        Code utilisateur: <h4> {details[0].code_utilisateur} </h4>
        <label className="control-label mt-3">Transfert vers: </label>
        <select
          className="select_compte mb-4"
          id="compte"
          name="compte"
          defaultValue={details[0].code_agence}
          value={agencechange}
          onChange={(e) => setagence(e.target.value)}
        >
          {agence.root_liste_agence &&
            agence.root_liste_agence.map((item) => {
              return (
                <option key={item.code_agence} value={item.code_agence}>
                  {item.libelle_agence}
                </option>
              );
            })}
        </select>
      </Modal>
    </>
  );
};
