import React,{ useState, useEffect } from "react";
import { Form} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { validproduction } from "./redux/actions/userActions";
import { notification } from "antd";
import { clearSuccess } from "./redux/actions/userActions";
import modelvalidation_csv from "./assets/modelvalidation.csv";  
// import corporate_csv from "./assets/corporate.csv";   

export default function ValidContrat() {

  const [optionchoisie, setoptionchoisie] = useState(0);
  const [file, setFile] = useState();
  const [charge, setCharge] = useState(0);

  const formData = new FormData();
  formData.append("optionchoisie", optionchoisie);
  formData.append("file", file);

  const clearState = () => {

    setTimeout(function () {
   /*   window.location.reload(1);*/
    }, 5000);
  };

  const openNotificationWithIcon = () => {
    notification.success({
      message: localStorage.getItem('message'),
      description: "Import Data.",
      style: {
        marginTop: 50,
      },
    });
  };

  const userSignin = useSelector((state) => state.userSignin);
  const userRegister = useSelector(state => state.userRegister)
  const { messageR } = userRegister;

  // useEffect(() => {
  //   console.log('valeur choisie:'+optionchoisie)
  // }, [optionchoisie]);

  const { loading } = userSignin;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(validproduction(formData));
    setCharge(0);
    
  };

  useEffect(() => {
    //dispatch(importdon());
    if (messageR) {
      openNotificationWithIcon();
      clearState();
      dispatch(clearSuccess());
    }
  }, [dispatch, messageR]);

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
      setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
      e.preventDefault();

      if (file) {
        fileReader.onload = function (event) {
            const csvOutput = event.target.result;

            console.log('csvOutput:'+csvOutput);

            console.log('bouton charger cliqué');

            setFile(file);

            setCharge(1);
        };
        if(file != null && file.size > 0)
        {
          fileReader.readAsText(file);
        }             
      }
  };

  useEffect(()=>{
    console.log('file:'+JSON.stringify(file));    
  }, [file]) 

  return (
    <>
      <section class="signup-content">
        <div className="signup-box" style={{ "width": "50%" }}>
          <form onSubmit={submitHandler}>
            <h5 className="login-head">
              <i className="fa fa-lg fa-fw fa-user"></i>Valider Les Contrats
            </h5>


            <div className="col-12">
              {" "}       
             

                <Form.Item
                  label="Modèle de Fichier Pour Validation"
                  name="Modèle de Fichier Validation"
                >
                <a href={modelvalidation_csv}  download>Télécharger</a>

                </Form.Item>

              {" "}

                               
              {/* <div className="col-12">
            </div>     */}
            </div>
            
            <div style={{ textAlign: "left" } }>
                {/* <h5>Choisir le Fichier et Cliquer Ensuite sur Import Csv</h5> */}
                {/* <form > */}
                    <input
                        type={"file"}
                        id={"csvFileInput"}
                        accept={".csv"}
                        onChange={handleOnChange}
                    />

                    <button
                        onClick={(e) => {
                            handleOnSubmit(e);
                        }}
                    >
                        IMPORT CSV
                    </button>
                {/* </form> */}
              </div>    

            {" "}

            {(charge===1)&&<div className="form-group btn-container" style={{ marginTop: "10px" } }>
              <button className="btn btn-primary btn-block" htmltype="submit">
               
                  <div>
                    Mise à jour des Contrats
                  </div>
               
                {/* {!loading && <div> Creer compte</div>} */}
              </button>
            </div>}

            <div className="form-group btn-container" style={{ marginTop: "10px" } }>
            {(charge===1)&&(optionchoisie==='corporate'||optionchoisie==='particulier')&&<button className="btn btn-primary btn-block" htmltype="submit">
                {/* {charge && ( */}
                  {/* <div> */}
                    {/* <i className="fa fa-refresh fa-spin mr-2"></i> Importer Données */}
                  {/* </div> */}
                {/* )} */}
                {!loading && <div>Importer Données</div>}
              </button> }
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
