import React, { useState } from "react";
import { Modal, Form, Input, notification } from "antd";
import Axios from "axios";

export default function ChangePwd() {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [login, setlogin] = useState("");
  const [pwd, setpwd] = useState("");
  const [newPwdCheck, setnewPwdCheck] = useState("");
  const [newPwd, setnewPwd] = useState("");


  const clearState = () => {
      
      setlogin("");
      setpwd("");
      setnewPwdCheck("");
      setnewPwd("");
    };


  const showModal = () => {
    setVisible(true);
  };
    const formData = new FormData();
    formData.append("login", login);
    formData.append("new_password", newPwd);
  formData.append("old_password", pwd);
  
  const isOk =
    pwd !== "" && newPwd !== "" && newPwdCheck !== "" && newPwd === newPwdCheck;

const openNotificationWithIcon = (type) => {
  notification[type]({
    message: "Notification ",
    description: "Mot de passe changer avec succès",
    placement: "bottomRight",
  });
};
  const changePwd = () => {
    Axios({
      method: "post",
      url: "/prubli_ma/ss/reset_password.php",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        openNotificationWithIcon("success");
              

        // console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const handleOk = () => {
    changePwd();
    setConfirmLoading(true);
      
    setTimeout(() => {
      clearState();
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  return (
    <>
      <p style={{ cursor: "pointer" }} onClick={showModal}>
        <i className="mr-3 fa fa-key"></i>Changer mot de passe
      </p>
      <Modal
        title="Changer de mot de passe"
        visible={visible}
        onOk={handleOk}
        okButtonProps={{ disabled: !isOk}}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form {...layout} name="basic">
          <Form.Item
            label="Votre login"
            name="login"
            rules={[{ required: true, message: "Please input your login!" }]}
          >
            <Input value={login} onChange={(e) => setlogin(e.target.value)} />
          </Form.Item>
          <Form.Item
            label="Votre mot de passe"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              value={pwd}
              onChange={(e) => setpwd(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="Nouveau mot de passe"
            name="new password"
            rules={[
              { required: true, message: "Please input your new password!" },
            ]}
          >
            <Input.Password
              value={newPwd}
              onChange={(e) => setnewPwd(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Verifier mot de passe"
            name="check password"
            rules={[
              { required: true, message: "Please input your new password!" },
            ]}
          >
            <Input.Password
              value={newPwdCheck}
              onChange={(e) => setnewPwdCheck(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
