import React, { useState, useEffect} from "react";
import {Form, Input, Row, Col} from "antd";

export default function RegisterBeneficiaires({listBenef,updatelistBenefvalue}) {

  // 0302820102345739   EASY LIFE / EASY LIFE FLEX / EASY LIFE PLUS

  const [benef1, setbenef1] = useState("")
  const [pourcentage1, setpourcentage1] = useState("")
  const [relation1, setrelation1] = useState("")

  const [benef2, setbenef2] = useState("");
  const [pourcentage2, setpourcentage2] = useState("");
  const [relation2, setrelation2] = useState("");

  const [benef3, setbenef3] = useState("");
  const [pourcentage3, setpourcentage3] = useState(""); 
  const [relation3, setrelation3] = useState("");
  
  useEffect(()=>{

    updatelistBenefvalue({
      benef1,
      pourcentage1,
      relation1,
      benef2,
      pourcentage2,
      relation2,
      benef3,
      pourcentage3,
      relation3,      
    })

  }, [benef1,pourcentage1,relation1,benef2,pourcentage2,relation2,benef3,pourcentage3,relation3])
 
  return (
    <>   {/* Les fragments nous permettent de grouper une liste d'enfants sans ajouter de nœud supplémentaire au DOM. */}
  
    <div class="col-12 text-center mb-5">

      <strong>
          Bénéficiaire(s) en cas de décés ou d’invalidité /
        <span class="italic_main">
        {" "}
          Beneficiaries in event of Death or Invalidity.
        </span>
      </strong>

    </div>

    <div class="col-12 text-center mb-5">


    <Row>
                        {" "}
                        <Col className="mr-3" span={7}>
                          {" "}
                          <Form.Item
                            label="Nom et Prénoms / Name and Given names"
                            name="Nom et Prénoms"
                            rules={[
                              {
                                required: true,
                                message: " Nom et Prénoms ",
                              },
                            ]}
                          >
                            <Input
                              id="idUnique"
                              type="text"
                              value={benef1}
                              onChange={(e) => setbenef1(e.target.value)}
                              placeholder="Nom et Prénoms / Name and Given names"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col className="mr-3" span={7}>
                          {" "}
                          <Form.Item
                            label=" % de réparation /Portion in %"
                            name="réparation"
                            rules={[
                              {
                                required: true,
                                message: " réparation ",
                              },
                            ]}
                          >
                            <Input
                              id="idUnique"
                              type="number"
                              value={pourcentage1}
                              onChange={(e) => setpourcentage1(e.target.value)}
                              placeholder="% de réparation /Portion in %"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col span={7}>
                          {" "}
                          <Form.Item
                            label=" Filiation /Relationship"
                            name="Filiation /Relationship"
                            rules={[
                              {
                                required: true,
                                message: " Filiation /Relationship ",
                              },
                            ]}
                          >
                            <Input
                              id="idUnique"
                              type="text"
                              value={relation1}
                              onChange={(e) => setrelation1(e.target.value)}
                              placeholder="Filiation /Relationship"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                       
                        <Col className="mr-3" span={7}>
                          {" "}
                          <Form.Item name="Nom et Prénoms">
                            <Input
                              id="idUnique"
                              type="text"
                              value={benef2}
                              onChange={(e) => setbenef2(e.target.value)}
                              placeholder="Nom et Prénoms / Name and Given names"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col className="mr-3" span={7}>
                          {" "}
                          <Form.Item name="réparation">
                            <Input
                              id="idUnique"
                              type="number"
                              value={pourcentage2}
                              onChange={(e) => setpourcentage2(e.target.value)}
                              placeholder="% de réparation /Portion in %"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col span={7}>
                          {" "}
                          <Form.Item name="Filiation /Relationship">
                            <Input
                              id="idUnique"
                              type="text"
                              value={relation2}
                              onChange={(e) => setrelation2(e.target.value)}
                              placeholder="Filiation /Relationship"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                     
                        <Col className="mr-3" span={7}>
                          {" "}
                          <Form.Item name="Nom et Prénoms">
                            <Input
                              id="idUnique"
                              type="text"
                              value={benef3}
                              onChange={(e) => setbenef3(e.target.value)}
                              placeholder="Nom et Prénoms / Name and Given names"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col className="mr-3" span={7}>
                          {" "}
                          <Form.Item name="réparation">
                            <Input
                              id="idUnique"
                              type="number"
                              value={pourcentage3}
                              onChange={(e) => setpourcentage3(e.target.value)}
                              placeholder="% de réparation /Portion in %"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col span={7}>
                          {" "}
                          <Form.Item name="Filiation /Relationship">
                            <Input
                              id="idUnique"
                              type="text"
                              value={relation3}
                              onChange={(e) => setrelation3(e.target.value)}
                              placeholder="Filiation /Relationship"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                      </Row>

                      </div>

                         
     </>        );  }