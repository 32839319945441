import React from 'react'

export default function Footer() {
    return (
      <>
        <footer>
          <small>
            © 2021 
          </small>
          <br />
          <div className="social-bagdes">
        
          </div>
        </footer>
      </>
    );
}
