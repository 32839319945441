import React from 'react'
import "../assets/css/style.css";
import pbf from "../assets/pbg_insurance.jpg";
import nfc from "../assets/nfc1.png";


export default function SafeCardDetails({ contrat, userInfo}) {
  const xaf = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "XAF",
    minimumFractionDigits: 0,
  });
  var today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
            today.getDate();
   
  const option1 = {
    garantie1: 'Frais réels avec un maximum de XAF 250 000 par événement et par année/ Real expenses with a maximum of XAF 250 000 per event and per year.',
    garantie2: 'Frais réels avec un maximumde XAF 30 000 par évènement et par année/Real expenses with a maximum of XAF 30 000  per event and per year.',
    garantie3: 'Frais réels avec un maximumde XAF 100 000 par événement et par année. CNI/NIC:	10 000 Permis/Driving licence: 30 000 Passeport:/Passport	60 000',
    garantie4: 'NON/NO',
    prime_ht: 5000,
    tva: 962,
    prime_ttc: 5962,
  };
    const option2 = {
      garantie1:'Frais réels  avec  un maximum de XAF 500 000 par évènement et par année./Real expenses with a maximum of XAF 500 000 per event and per year',
      garantie2:'Frais réels avec  un maximum de XAF 35 000 par évènement et par année/Real expenses with a maximum of XAF 35 000 per event and per year',
      garantie3:'Frais réels avec un Maximum de XAF 110 000 Par évènement et par année/ Real expenses with a maximum of XAF 110 000 per event and per year CNI/NIC:	10 000 Permis:/Driving licence	30 000 Passeport:/Passport	70000',
      garantie4:'NON/NO',
      prime_ht: 10000,
      tva: 1925	,
      prime_ttc: 11925,  
  };
  const option3 = {
      garantie1:'Frais réels avec un maximum de XAF 1 000 000 par évènement et  par année /Real expenses with a maximum of XAF 1 000 000 per event and per year.',
      garantie2:'Frais réels avec un maximum de XAF 40 000 par évènement et par année/Real expenses with a maximum of XAF 40 000 per event and per year.',
      garantie3:'Frais réels avec un maximum de XAF 110 000 Par évènement et par année/Real expenses with a maximum of XAF 110 000 per event and per year.CNI/ NIC:	10 000 Permis/Driving licence:30 000 Passeport:/Passport 70 000',
      garantie4:'Décès/Death 250 000, Invalidité/Disability: 250 000 and  Frais Médicaux/Medical expenses: 25 000',
      prime_ht: 30000,
      tva: 5775,
      prime_ttc: 35775,
};

const options = 'option'+contrat.code_type_qm === "option1" ? option1 : 'option'+contrat.code_type_qm === "option2" ? option2 : option3;

  return (
    <>
      <div className="container">
        <header>
          <div class="row">
            <div class="col-3" id="logo_nfcBank">
              <img src={nfc} alt="NFC bank" />
            </div>
            <div class="col-6 text-center text-primary">
              <h1 style={{ color:'#73797C'}}>
                <strong>
                  NFC BANK 
                  <br />
                  SAFE CARD{" "}
                </strong>
              </h1>
              <strong style={{ color:'#73797C'}}>
                Formulaire d’adhésion tenant lieu d’Attestation d’Assurance
                <br />
                <span class="italic_title">
                  Individual subscription form, serves as Insurance Attestation
                </span>
              </strong>
            </div>
            <div class="col-3" id="logo_beneficial">
              <img src={pbf} alt="beneficial" />
              <span>
                <span class="text-primary">N°:</span>{" "}
                <strong class="text-danger">{contrat.numero_police}</strong>{" "}
              </span>
            </div>
          </div>
        </header>
        <main>
          <div class="boder">
            <div class="row">
              <div class="col-12">
                <strong>
                  CADRE RESERVE A NFC BANK/{" "}
                  <span class="italic_main">RESERVED FOR NFC BANK</span>
                </strong>
              </div>
              <div class="col-4">
                Chargé de clientèle:<strong>{userInfo.nom}</strong>{" "}
              </div>
              <div class="col-4">
                Agence: <strong>{userInfo[0].libelle_agence}</strong>{" "}
              </div>
              <div class="col-4">
                Code:<strong>{userInfo[0].code_agence}</strong>{" "}
              </div>
              <div class="col-4 italic">Account Manager</div>
              <div class="col-4 italic">Branch</div>
              <div class="col-4 italic">Code</div>
            </div>
          </div>

          <div class="boder">
            <div class="row">
              <div class="col-12 underline">
                <strong>
                  CONTRACTANT/ <span class="italic_main">SURSCRIBER</span>
                </strong>
              </div>
              <div class="col-2">
                Mme/<span class="italic">Mrs</span>{" "}
                {/* <input type="checkbox" checked="1" /> */}
              </div>
              <div class="col-4">
                Nom/<span class="italic">Surname:</span>{" "}
                <strong>{contrat.nom_client} </strong>
              </div>
              <div class="col-6">
                Prénoms/<span class="italic">Given Names:</span>
                <strong>{contrat.prenoms_client} </strong>
              </div>

              <div class="col-2">
                Mlle/<span class="italic">Miss</span>
              </div>
              <div class="col-4">
                Date et lieu de naissance/<span class="italic">Date:</span>{" "}
                <strong>{contrat.date_naissance} </strong>
              </div>
              <div class="col-6">
                {/* ,à/<span class="italic">at:</span> */}
              </div>
              <div class="col-12">
                M./<span class="italic">Mr</span>
              </div>

              <div class="col-2">N° de Compte:</div>
              <div class="col-2">
                {<strong>{contrat.numero_compte} </strong>}
              </div>
              <div class="col-2"></div>
              <div class="col-2"></div>
              <div class="col-4"></div>
              <div class="col-2 italic">Account Number</div>
              <div class="col-2">
                <span class="italic"></span>
              </div>
              <div class="col-2">
                <span class="italic"></span>
              </div>
              <div class="col-2">
                <span class="italic"></span>
              </div>
              <div class="col-4 italic"></div>

              <span class="col-4">
                CNI N°/<span class="italic">ID: </span>{" "}
                <strong>{contrat.cni} </strong>
              </span>
              <span class="col-4">
                {/* du/<span class="italic">from: </span> hghgdhbdg */}
              </span>
              <span class="col-4">
                {/* à/<span class="italic">at: </span> 12/02/2022 */}
              </span>

              {/* <div class="col-4">Situation matrimoniale: </div>
                <div class="col-2">
                  Célibataire <input type="checkbox" checked="1" />
                </div>
                <div class="col-2">
                  Marié(e) <input type="checkbox" />
                </div>
                <div class="col-2">
                  Divorcé(e) <input type="checkbox" />
                </div>
                <div class="col-2">
                  Veuf(ve) <input type="checkbox" />
                </div>
                <div class="col-4 italic">Marital Status</div>
                <div class="col-2 italic">Single</div>
                <div class="col-2 italic">Married</div>
                <div class="col-2 italic">Divorced</div>
                <div class="col-2 italic">Window(er)</div> */}

              <div class="col-4">
                Adresse postale: <strong>{contrat.adresse_postale} </strong>
              </div>
              <div class="col-4">
                Ville: <strong>{contrat.ville} </strong>
              </div>
              <div class="col-4">
                Cellulaire: <strong>{contrat.telephone} </strong>
              </div>
              <div class="col-4 italic">Postal Address</div>
              <div class="col-4 italic">Town</div>
              <div class="col-4 italic">Cellular</div>

              <div class="col-4">
                Occupation: <strong>{contrat.profession} </strong>
              </div>
              <div class="col-4">
                Email: <strong>{contrat.email} </strong>
              </div>
              {/* <div class="col-4">Téléphone domicile: 1515145</div>
                <div class="col-8 italic"></div>
                <div class="col-4 italic">Home phone</div> */}

              {/* <div class="col-4">Employeur:rgerger</div>
                <div class="col-8">Tél:545644</div>
                <div class="col-4 italic">Employer</div>
                <div class="col-4 italic">Phone</div> */}
              {/* <div class="col-12">
                  <strong>
                    N° CONTRIBUABLE/{" "}
                    <span class="italic_main">Tax payer number: </span>
                  </strong>{" "}
                  thtthtrhrthtrhtr
                </div> */}
            </div>
          </div>

          <div class="boder">
              <div class="row">
                <div class="col-12 text-center">
                  <strong>
                  PLAFONDS D’INDEMNISATION PAR TYPE DE CARTE /
                    <span class="italic_main">
                      {" "}
                      COMPENSATION CEILINGS PER CARD TYPE
                    </span>
                  </strong>
                </div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">
                        Garantie/{" "}
                        <span class="italic_main">Garantie</span>
                      </th>
                      <th scope="col">
                        Valeur/{" "}
                        <span class="italic_main">Valeur</span>
                      </th>                     
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <th>1. Vols et débits frauduleux. Soustraction frauduleuse de fonds/Theft and fraudulent debit.</th>
                      <th>{options.garantie1}</th>                    
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <th>2. Frais d’opposition et d’établissement d’une nouvelle carte ou chéquier/Expenses for opposition and establishment of a new card cheque booklet.</th>
                      <th>{options.garantie2}</th>                   
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <th>Frais de remboursement de documents égarés ou volés en même temps que la carte bancaire
                          ou du chéquier/Expenses for the reimbursement of documents lost or stolen together with the bank card or cheque booklet.</th>
                      <th>{options.garantie3}</th>                   
                    </tr>
                    <tr>
                      <th scope="row">4</th>
                      <th>4. Individuelle accidents/ Personal accident.
                          Décès accidentel, invalidité permanente, partielle ou totale/Accidental death, permanent, partial or total disability.</th>   
                      <th>{options.garantie4}</th>             
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
          <div class="boder">
            <div class="row">
              <div class="col-12 text-center">
                <strong>
                  EFFET - GARANTIE - PRIMES /
                  <span class="italic_main">
                    {" "}
                    EFFECTIVE DATE - COVERAGE - PREMIUMS
                  </span>
                </strong>
              </div>
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <th scope="row">
                      <strong>
                        Date d’effet /{" "}
                        <span class="italic_main">Effective date :</span>{" "}
                      </strong>
                    </th>
                    {/* <th>
                        {" "}
                        Le/ <span class="italic_main">On:</span> {dateeffet}
                      </th> */}
                  </tr>
                  <tr>
                    <th scope="row">
                      Durée de la.garantie/
                      <strong>
                        {" "}
                        <span class="italic_main"> Coverage:</span>
                        <br /> period:
                      </strong>
                    </th>
                    <th>
                      {" "}
                      UN (1) an renouvelable par tacite reconduiction /{" "}
                      <span class="italic_main">
                        ONE (1) year renewable by tacit agreement
                      </span>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="boder">
              <div class="row">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" rowspan="2" class="text-center">
                        DETAIL DE LA PRIME / <span class="italic_main">DETAILS OF THE PREMIUM</span>
                      </th>
                      <th scope="col" colspan="1" class="text-center">
                        MONTANT/ <span class="italic_main">AMOUNT</span>
                      </th>
                    </tr>                   
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        Prime Ht /{" "}                     
                        <span class="italic_main">
                          Premium Ht{" "}                       
                        </span>
                      </th>
                      <th>{options.prime_ht}</th>
                    </tr>
                    <tr>
                      <th scope="row">
                        Tva/
                        <span class="italic_main">
                          {" "}
                          Vat
                        </span>                       
                      </th>
                      <th>{options.tva}</th>
                    </tr>
                    <tr>
                      <th scope="row">PRIME ANNUELLE TTC (TVA 19,25%) / <span class="italic_main"> {" "}  ANNUAL PREMIUM TTC (VAT 19.25%) </span> 
                      </th>
                      <th>{options.prime_ttc}</th>         
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row">
              <div class="col-10">
                l’option choisie /{" "}
                <span class="italic">the option Chosen :</span>{" "}
              </div>{" "}
              {console.log(JSON.stringify(contrat))}
              <strong class="col-2">{'option'+contrat.code_type_qm}</strong>
            </div>

          <div class="boder">
            <div class="row">
              <div class="col-12">
                <strong>
                  NB: Conformément aux dispositions des articles 13-1 et 73 du
                  code CIMA, la prise d’effet des garanties du présent contrat
                  est subordonnée au paiement de la prime ou de la premiére
                  fraction de prime.
                </strong>
                <br />
                <span class="italic">
                  In conformity with the dispositions of articles 13-1 and 73 of
                  the CIMA code, the guarantiee of the present contract is
                  effective at the payment of the premium or first part of it.
                </span>
              </div>
              <div class="col-12">
                <ul>
                  <li class="italic">
                    Par le présent formulaire, j’autorise{" "}
                    <strong>NFC BANK</strong> à débiter de mon compte, le
                    montant de la prime ci-dessus indiqué au profil de{" "}
                    <strong>PRUDENTIAL BENEFICIAL LIFE INSURANCE</strong>
                  </li>
                  <li class="italic">
                    I hereby authorize <strong>NFC BANK</strong> to pay from my
                    account the above premium to{" "}
                    <strong>PRUDENTIAL BENEFICIAL LIFE INSURANCE</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div class="row">
            <div class="col-6"></div>
            <div class="col-3">
              Fait à/<span class="italic_main">Done at: </span>
            </div>
            <div class="col-3">
              {/* le/<span class="italic_main">on: </span> <strong>{date}</strong> */}
            </div>

            <div class="col-6 underline">
              Signature de l’Adhérent /{" "}
              <span class="italic_main">Signature of Subscriber</span>
            </div>
            <div class="col-6 underline">
              Signature de <strong>NFC RANK</strong>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
