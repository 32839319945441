import {
  CALCULP_LIST_REQUEST,
  CALCULP_LIST_SUCCESS,
  CALCULP_LIST_FAIL,
  CLEAR_SUCCESS,
} from "../constants/calculPretConst";


export const calculPretReducer = (state = {}, action) => {
  switch (action.type) {
    case CALCULP_LIST_REQUEST:
      return { loading: true };
    case CALCULP_LIST_SUCCESS:
      console.log("bychrisme look tauxPrime : ", action.payload)
      return {
        loading: false,
        success: true,
        tauxPrime: action.payload,
      };
    case CLEAR_SUCCESS:
      return {
        ...state,
        success: false,
      };

    case CALCULP_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
