import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register } from "./redux/actions/userActions";
import {listAgence} from "./redux/actions/agenceActions"
import {notification } from "antd";
import {  clearSuccess } from "./redux/actions/userActions";

export default function CreerCompte() {
  const [nom, setnom] = useState("");
  const [password, setPassword] = useState("");
  const [login, setlogin] = useState("");
  const [prenoms, setprenoms] = useState("");
  const [code_agence, setcode_agence] = useState("010");
  const [email, setemail] = useState("");
  const [roleuser, setroleuser] = useState(3);

  const code_banque = "NF";
  const formData = new FormData();
  formData.append("email", email);
  formData.append("login", login);
  formData.append("password", password);
  formData.append("nom", nom);
  formData.append("prenoms", prenoms);
  formData.append("code_agence", code_agence);
  formData.append("code_banque", code_banque);
  formData.append("code_type_utilisateur", roleuser);  

  const clearState = () => {
    setnom("");
    setPassword("");
    setlogin("");
    setprenoms("");
    setemail("");
  };

  const openNotificationWithIcon = () => {
    notification.success({
      message: "Créer utilisateur",
      description: "Utilisateur créer avec success.",
      style: {
        marginTop: 50,
      },
    });
  };

  const userSignin = useSelector((state) => state.userSignin);
  const agenceList = useSelector((state) => state.agenceList);
  const userRegister = useSelector(state => state.userRegister)
  const { messageR } = userRegister;

  const { agence } = agenceList;
  const { userInfo, loading } = userSignin;
 
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(register(formData));
   
  };

  useEffect(() => { 
    dispatch(listAgence());
    if (messageR) {
      openNotificationWithIcon();
      dispatch(clearSuccess());
      clearState();
    }
  }, [dispatch, messageR]);

  return (
    <>
      <section class="signup-content">
        <div className="signup-box">
          <form onSubmit={submitHandler}>
            <h5 className="login-head">
              <i className="fa fa-lg fa-fw fa-user"></i>Créer compte
            </h5>
            <div className="d-flex">
              <div className="col-6">
                {" "}
                <div className="form-group">
                  <label className="control-label">NOM</label>
                  <input
                    className="form-control"
                    htmltype="text"
                    placeholder="nom"
                    value={nom}
                    onChange={(e) => setnom(e.target.value)}
                  />
                </div>{" "}
                <div className="form-group">
                  <label className="control-label">PRENOM</label>
                  <input
                    className="form-control"
                    htmltype="text"
                    placeholder="prenoms"
                    value={prenoms}
                    onChange={(e) => setprenoms(e.target.value)}
                  />
                </div>{" "}
                <div className="form-group">
                  <label className="control-label">LOGIN</label>
                  <input
                    className="form-control"
                    htmltype="text"
                    placeholder="login"
                    value={login}
                    onChange={(e) => setlogin(e.target.value)}
                  />
                  <label className="control-label mt-3">Rôle utilisateur</label>

                  <select
                    className="form-control"
                    value={roleuser}
                    onChange={(e) => setroleuser(e.target.value)}
                    id="compte"
                    name="compte"
                    required
                  >
                    {(userInfo
                      ? userInfo.code_type_utilisateur === "1"
                      : null) &&
                      !loading && (
                        <>
                          <option selected value={3}>
                            Utilisateur
                          </option>
                          <option value={2}>Chef d'agence</option>
                          <option value={5}>Gestionnaire crédit</option>
                          <option value={4}>Autres</option>
                          <option value={7}>3C S</option>
                        </>
                      )}

                    {(userInfo
                      ? userInfo.code_type_utilisateur === "0"
                      : null) &&
                      !loading && (
                        <>
                          <option value={1}>Administrateur</option>
                          <option value={6}>gestionnaire bénéficial</option>
                        </>
                      )}
                  </select>
                </div>{" "}
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label className="control-label">MOT DE PASSE</label>
                  <input
                    className="form-control"
                    htmltype="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>{" "}
                <div className="form-group">
                  <label className="control-label">EMAIL</label>
                  <input
                    className="form-control"
                    htmltype="email"
                    placeholder="email"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                </div>{" "}
                <div className="form-group">
                  {(userInfo ? userInfo.code_type_utilisateur === "1" : null) &&
                    !loading && (
                      <>
                        <label className="control-label">CODE AGENCE</label>
                        <select
                          className="form-control"
                          id="compte"
                          name="compte"
                          value={code_agence}
                          onChange={(e) => setcode_agence(e.target.value)}
                        >
                          {agence.root_liste_agence &&
                            agence.root_liste_agence.map((item) => {
                              return (
                                <option
                                  key={item.code_agence}
                                  selected
                                  value={item.code_agence}
                                >
                                  {item.libelle_agence}
                                </option>
                              );
                            })}
                        </select>
                      </>
                    )}
                </div>{" "}
              </div>
            </div>

            <div className="form-group btn-container">
              <button className="btn btn-primary btn-block" htmltype="submit">
                {loading && (
                  <div>
                    <i className="fa fa-refresh fa-spin mr-2"></i> Création du
                    compte
                  </div>
                )}
                {!loading && <div> Creer compte</div>}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
