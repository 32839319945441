import React, { useState } from "react";
import { Modal, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {listFm} from "./redux/actions/fmActions"
import Axios from "axios";
// import QMedicalPBli from "./QMedicalPBli";


export default function HistoriqueEtat({ record }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
 
  const formData = new FormData();
  formData.append("numero_police", record.numero_police);
  // formData.append("code_type_qm", record.code_type_qm);

  const listeHistorique = () => {
    Axios({
      method: "post",
      url: "/prubli_ma/ss/afficher_histo_contrat.php",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {

        localStorage.setItem('heGet',JSON.stringify(response.data.root_historique_etat)) ;

        // console.log('data_news : '+ JSON.stringify(response.data.root_historique_etat))
        
      })
      .catch(function (response) {
        
        localStorage.setItem("heGet",response.data) ;

      });
  };

  listeHistorique();

  const dispatch = useDispatch();

  const showModal = () => {
   listeHistorique()
    setTimeout(() => {
      setIsModalVisible(true);
    }, 500);
    
  };


  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // console.log('localStorage.getItem.heGet:'+JSON.stringify(localStorage.getItem("heGet")));

  var Hetat = localStorage.getItem('heGet') ? 
  JSON.stringify(localStorage.getItem('heGet'))? 
  JSON.stringify(localStorage.getItem('heGet')):
                ['']:
                [''];


//  console.log('localStorage.getItem(heGet):'+localStorage.getItem('heGet'));

//  console.log('var Hetat :'+Hetat);

//  console.log('var Hetat :'+Object.keys(Hetat));

 return (
    <>
      <Button
        style={{
          backgroundColor: "Green",
          color: "white",
        }}
        shape="circle"
        size="small"
        onClick={showModal}
      >
        HE
      </Button>
      <Modal
        width={1000}
        title="Differents Etats du Dossier"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* {Hetat.length !== 0 ? ( */}
          <>

          { Hetat.toString().replace('"',' ')}

        


          {/* {console.log('data_news : '+ JSON.stringify(Hetat))}; */}
          
            {/* {record.code_type_qm === "qb" && (
              <div>
                <h1>
                  {" "}
                  <strong> {Hetat[0].numero_police} </strong>
                </h1>
                <div class="form-group row">
                  <div class="col-sm-4  mb-sm-0">
                    <p>
                      <label for="poids">
                        Poids<span>/Weight (kg) </span> :
                      </label>

                      <strong> {Hetat[0].poid} Kg</strong>
                    </p>
                  </div>

                  <div class="col-sm-4 mb-3 mb-sm-0">
                    <label for="assurance">
                      Avez-vous actuellement ne assurance vie ?
                      <span>/Do you currently have life insurance?</span> :
                    </label>
                    <strong> {Hetat[0].assurance_vie} </strong>
                  </div>
                  <div class="col-sm-4 mb-3 mb-sm-0">
                    <label>
                      Suivez-vous actuellement un traitement regulier?
                      <span>/Are you currently on regular treatment?</span>
                    </label>
                    <div>
                      {" "}
                      <strong> {Hetat[0].traitement_regulier} </strong>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-4  mb-sm-0">
                    <label for="taille">
                      Taille<span>/Height (cm) </span> :
                    </label>
                    <strong> {Hetat[0].taille} cm </strong>
                  </div>
                  <div class="col-sm-4 mb-3 mb-sm-0">
                    <label for="yes">
                      Si oui,à quelle compagnie et pour quelle montant?
                      <span>
                        /if so, to which company and for what amount?
                      </span>{" "}
                      :
                    </label>
                    <strong> {Hetat[0].compagnie_assurance}</strong>
                  </div>

                  <div class="col-sm-4 mb-3 mb-sm-0">
                    <label>
                      Durant les 3 dernieres annees, Avez-vous du interrompe
                      votre travail pour des raisons medicales pendant plus de 3
                      semaines
                      <p>
                        /During the past 3 years, have you had to interrupt your
                        work for medical reasons for more than 3 weeks?
                      </p>
                    </label>
                    <strong>{Hetat[0].interruption_travail}</strong>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-4 mb-3 mb-sm-0">
                    <label for="mois">
                      Depuis 6 mois/Since 6 months
                      <span>/Avez-vous/Have you</span> :
                    </label>
                    <strong>{Hetat[0].grossi}</strong>
                  </div>
                  <div class="col-sm-4 mb-3 mb-sm-0">
                    <label for="maladie">
                      Avez-vous souffert ou souffrez-vous d'une maladie
                      chronique ou d'une infirmité importante ?
                      <span>
                        /Have you suffered or do you siffer from chronic illness
                        or significant disability?
                      </span>{" "}
                      :
                    </label>
                    <strong>{Hetat[0].maladie_chronique}</strong>
                  </div>
                  <div class="col-sm-4 mb-3 mb-sm-0">
                    <label for="yes">
                      Si oui,lequel et depuis quand?
                      <span>/if so, which one and since when?</span> :
                    </label>
                    <strong> {Hetat[0].interruption_observation}</strong>
                  </div>
                </div>

                <div>
                  <p>
                    je certifie avoir répondu sincèrement, sans aucune réticence
                    et n'avoir pas rien dissimilé sur mon état de santé passé ou
                    actuel et prends acte que toute reticence ou fausse
                    déclaration de ma part entrainera la nullité du contrat.
                    J'autorise expressément la compagnie à prendre toutes les
                    informations demandés qu'elle jugerait utiles et necessaire
                    aupres des médecins qui m'ont soigné.j'autorise ces médecins
                    à communiquer à la compagnie tous les renseignements
                    demandés.
                  </p>
                  <p>
                    I certify that i answered sincerely, without any reluctance
                    and that
                  </p>
                </div>
              </div>
            )} */}

          
          </>
      
          {/* <h1> {error}</h1> */}
        {/* )} */}
      </Modal>
    </>
  );
};
