import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { listContrat } from './redux/actions/ContratActions';

export default function Dashboard() {
  const dispatch = useDispatch();
  const listContrats = useSelector((state) => state.listContrat);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const { contrats } = listContrats;
  const datax = contrats ? contrats.root_liste_contrat ? contrats.root_liste_contrat : [] : [];

  const data = datax.filter((value) => {
    if (['2', '3', '4', '7'].includes(userInfo.code_type_utilisateur)) {
      return value.code_agence === userInfo[0].code_agence;
    }
    else {
      return value.code_agence;
    }

  });

  let particulier = data.filter((value) => {
    return value.type_client === "particulier";
  });

  let corporate = data.filter((value) => {
    return value.type_client === "corporate";
  });
  let parraine = data.filter((value) => {
    return value.type_client === "parraine";
  });
  let safe_card = data.filter((value) => {
    return value.type_client === "safe_card";
  });

 
  useEffect(() => {
    dispatch(listContrat());
  }, [dispatch]);

  function choixProduit(name,libelleProduit) {
    localStorage.setItem('choixProduit',name);
    localStorage.setItem('libelleChoixProduit',libelleProduit);
  }


  return (
    <>
      <div className="row">
        <div className="col-md-4 col-lg-3">
          {/* <Link to="/mainapp/reportingNew"  onClick={() => choixProduit('corporate','Emprunteur Corporate')} style={{ textDecoration: "none" }}> */}
          <Link to="/mainapp/reporting"  onClick={() => choixProduit('corporate','Emprunteur Corporate')} style={{ textDecoration: "none" }}>
            {" "}
            <div className="widget-small primary coloured-icon">
              <i className="icon fa fa-users fa-3x"></i>
              <div className="info">
                <h6>Emprunteur corporate</h6>
                <p>
                  <b>{corporate.length} </b>
                </p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-4 col-lg-3">
          <Link to="/mainapp/reportingNew" onClick={() => choixProduit('particulier','Emprunteur Particulier')}  style={{ textDecoration: "none" }}>
            {" "}
            <div className="widget-small primary coloured-icon">
              <i className="icon fa fa-user fa-3x"></i>
              <div className="info">
                <h6>Emprunteur particulier</h6>
                <p>
                  <b>{particulier.length} </b>
                </p>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-md-6 col-lg-3">
          <Link to="/mainapp/reportingNew" onClick={() => choixProduit('parraine','Compte Parrainé')} style={{ textDecoration: "none" }}>
            <div className="widget-small info coloured-icon">
              <i className="icon fa fa-envelope fa-3x"></i>
              <div className="info">
                <h6>Compte parrainé</h6>
                <p>
                  <b>{parraine.length} </b>
                </p>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-md-6 col-lg-3">
          <Link to="/mainapp/reportingNew" onClick={() => choixProduit('safe_card','Safe Card')} style={{ textDecoration: "none" }}>
            <div className="widget-small danger coloured-icon">
              <i className="icon fa fa-address-card-o fa-3x"></i>
              <div className="info">
                <h6>Safe Card</h6>
                <p>
                  <b>{safe_card.length} </b>
                </p>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-md-6 col-lg-3">
          <Link to="/mainapp/reportingNew" onClick={() => choixProduit('retraite_comple','Retraite Complementaire')} style={{ textDecoration: "none" }}>
            <div className="widget-small info coloured-icon">
              <i className="icon fa fa-money fa-3x"></i>
              <div className="info">
                <h6>Retraite Complémentaire</h6>
                <p>
                  {/* <b>{safe_card.length} </b> */}
                </p>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-md-6 col-lg-3">
          <Link to="/mainapp/reportingNew" onClick={() => choixProduit('frais_funeraire','Frais Funeraires')} style={{ textDecoration: "none" }}>
            <div className="widget-small info coloured-icon">
              <i className="icon fa fa-money fa-3x"></i>
              <div className="info">
                <h6>Frais Funeraire</h6>
                <p>
                  {/* <b>{safe_card.length} </b> */}
                </p>
              </div>
            </div>
          </Link>
        </div>

      </div>
    </>
  );
}
