import React from 'react'
  const xaf = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "XAF",
    minimumFractionDigits: 0,
  });
export default function Simulateur(props) {

    const {
      age,
      prime_brute,
      accessoires,
      prime_ht,
      montant_tva,
      prime_ttc,
      quote_part_partenaire,
      quote_part_prubli,
    } = props.simulData;

    return (
      <>
        <h5 style={{ color: "gray" }}>Numéro de souscription:</h5>
        <div className="row">
          <div className="col-6">
            <h5 style={{ color: "gray" }}> Age du client:</h5>
          </div>
          <div className="col-6">
            <h4>{age} ans</h4>
          </div>
        </div>
        {/* <div className="row">
                        <div className="col-6">
                          <h5 style={{ color: "gray" }}>Prime {t3}:</h5>
                        </div>
                        <div className="col-6">
                          <h4> {xaf.format(prime)} </h4>
                        </div>
                      </div> */}
        <div className="row">
          <div className="col-6">
            <h5 style={{ color: "gray" }}>Prime de base:</h5>
          </div>
          <div className="col-6">
            <h4> {xaf.format(prime_brute)} </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <h5 style={{ color: "gray" }}>Accessoires:</h5>
          </div>
          <div className="col-6">
            <h4> {xaf.format(accessoires)} </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <h5 style={{ color: "gray" }}>Prime hors taxe:</h5>
          </div>
          <div className="col-6">
            <h4>{xaf.format(prime_ht)}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <h5 style={{ color: "gray" }}>Montant TVA :</h5>
          </div>
          <div className="col-6">
            <h4> {xaf.format(montant_tva)} </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <h5 style={{ color: "gray" }}>Prime TTC:</h5>
          </div>
          <div className="col-6">
            <h4> {xaf.format(prime_ttc)} </h4>
          </div>
        </div>
        <table style={{ width: "100%" }} className="mb-4  tabl">
          <tbody>
            <tr>
              <td className="tabl">
                <h5>Quote part NFC</h5>
              </td>
              <td className="tabl">
                <h5>Quote part PBLI</h5>
              </td>
            </tr>
            <tr>
              <td className="tabl">
                <h4> {xaf.format(quote_part_partenaire)} </h4>
              </td>
              <td className="tabl">
                <h4> {xaf.format(quote_part_prubli)} </h4>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
}
