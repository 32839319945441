import React, { useState } from "react";
import { Modal, Button, Form, Input, Col, Row } from "antd";
import {
  updatePrimeDec,
    listPrimeDec,
} from "./redux/actions/tauxPrimeActions";
import { useDispatch } from "react-redux";

export default function PrimeDecouvertEdit(props) {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { primeD, primeId } = props;

  const dispatch = useDispatch();

  const details = primeD.root_tarif_decouvert.filter((prime, index) => {
    return prime.id === primeId;
  });

  console.log(details)
  const [ageMin, setagemin] = useState(`${details[0].age_max}`);
  const [ageMax, setagemax] = useState(`${details[0].age_min}`);
  const [tauxClient, settauxclient] = useState(`${details[0].taux_client}`);
  const [tauxPersonnel, settauxpersonnel] = useState(`${details[0].taux_personnel}`);


  const contratData = new FormData();
  contratData.append("id", primeId);
  contratData.append("age_max", ageMax);
  contratData.append("age_min", ageMin);
  contratData.append("taux_client",tauxClient);
  contratData.append("taux_personnel", tauxPersonnel);


//   const id = primeId;

  // console.log(id)
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    dispatch(updatePrimeDec(contratData));

    setTimeout(() => {
      setVisible(false);
      dispatch(listPrimeDec());

      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  return (
    <>
      <Button
        style={{
          backgroundColor: "#1B9CFC",
          color: "white",
        }}
        shape="circle"
        size="small"
        onClick={showModal}
      >
        {" "}
        E
      </Button>
      <Modal
        // width={1000}
        title="Prime edit"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div>
          <Row>
            <Col span={24}>
              <Form.Item label="Age min">
                <Input
                  value={ageMin}
                  type="number"
                  onChange={(e) => setagemin(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Age max">
                <Input
                  value={ageMax}
                  type="number"
                  onChange={(e) => setagemax(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Taux client">
                <Input
                  value={tauxClient}
                  type="number"
                  onChange={(e) => settauxclient(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Taux personnel">
                <Input
                  value={tauxPersonnel}
                  type="number"
                  onChange={(e) => settauxpersonnel(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
