import React, { useState,useEffect } from "react";
import { Modal, Form, Input, Button,DatePicker,Select,} from "antd";

export default function Garant({ garFunc, garState }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const { Option } = Select;
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];


  const {
    setrefdossier,
    setdureedifere,
    setdateeffet,
    setdatemiseplace,
    setmontantecheance,
    setdatedernier,
    setdatepremiere,
    setperiodicite,
  } = garFunc;
  // 
    const {
      refdossier,
      dureedifere,
      // dateeffet,
      // datemiseplace,
      montantecheance,
      datepremiere,
      datedernier,
      periodicite,
      dureePret,
    } = garState;

   const onFinishFailed = (errorInfo: any) => {
     console.log("Failed:", errorInfo);
   };

   useEffect(()=>{
   

    var nbre_mois_global=Number(datepremiere.substring(3,5))+Number(dureePret)+Number(dureedifere);

    nbre_mois_global=nbre_mois_global-1;

    console.log("datepremiere : "+ datepremiere+ 'Durée:'+dureePret+'Differé:'+dureedifere+'nbre de mois:'+nbre_mois_global);
    // trouvons le nombre d'anne

    var quotient=0;

    var remainder=nbre_mois_global% 12;

    if(nbre_mois_global===12)
    {
        remainder=12; 
    }
    else if (remainder===0)
    {
        quotient = Math.floor((nbre_mois_global-12)/12);

        remainder = 12;

    }else
    {   
        quotient = Math.floor(nbre_mois_global/12);

        remainder = nbre_mois_global% 12;
    }
    
    var year=Number(datepremiere.substring(6,10));

    var month=Number(datepremiere.substring(3,4));

    var day=Number(datepremiere.substring(0,2));

    console.log('Nombre de mois Max:'+nbre_mois_global+' avec pour quotien: '+quotient+' et reste de division: '+remainder);

    if(quotient>=1)
    {
      year=Number(year)+Number(quotient);
    }
    if(remainder>=1)
    {
      month=month+remainder;
    }

    console.log(day+'/'+month+'/'+year);

    if(month===0){month='01'}

     setdatedernier(day+'/'+month+'/'+year);    

  }, [datepremiere,dureePret,dureedifere,setdatedernier])

 

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Garanties
      </Button>
      <Modal
        width={1000}
        title="Garanties"
        visible={isModalVisible}
        // onOk={handleOk}
        //onCancel={handleCancel}
        footer={[
          // <Button key="back" onClick={handleCancel}>
          //   Annuler
          // </Button>,
        ]}
      >
        <Form onFinish={handleOk} onFinishFailed={onFinishFailed}>
          <div className="d-flex">
            <div className="col-4">
              {/* <Form.Item label="Opération">
              <Select style={{ width: 200 }}>
                <Option value={1}>Prêt</Option>
                <Option value={0}>Découvert</Option>
              </Select>
            </Form.Item> */}
              <Form.Item label="Reférence du dossier">
                <Input
                  value={refdossier}
                  onChange={(e) => setrefdossier(e.target.value)}
                  type="text"
                  placeholder="Reférence"
                />
              </Form.Item>
              {/* <Form.Item label="Montant">
              <Input value="" type="number" placeholder="Montant" />
            </Form.Item> */}
              {/* <Form.Item label="Durée de remboursement prêt">
              <Input
                value=""
                type="number"
                placeholder="Durée de remboursement"
              />
            </Form.Item>{" "} */}
              <Form.Item label="Durée du différé">
                <Input
                  value={dureedifere}
                  onChange={(e) => setdureedifere(e.target.value)}
                  type="number"
                  defaultValue='0'
                  placeholder="Durée du différé"
                />
              </Form.Item>{" "}
              <Form.Item label="Durée du prêt">
                {parseInt(dureedifere) + parseInt(dureePret)}{" "}
              </Form.Item>
            </div>
            <div className="col-4">
              {" "}
              <Form.Item
                label="Date d'effet de la garantie"
                name="Date de garantie"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  className=""
                  id="date"
                  onChange={(date, dateString) => setdateeffet(dateString)}
                  format={dateFormatList}
                />
              </Form.Item>{" "}
              <Form.Item
                label="Date de mise en place"
                name="Date de mise en place"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  className=""
                  id="date"
                  onChange={(date, dateString) => setdatemiseplace(dateString)}
                  format={dateFormatList}
                />
              </Form.Item>
              <Form.Item label="Montant d'une échéance">
                <Input
                  value={montantecheance}
                  onChange={(e) => setmontantecheance(e.target.value)}
                  type="number"
                  placeholder="Montant d'une échéance"
                />
              </Form.Item>
            </div>
            <div className="col-4">
              <Form.Item
                label="Date première échéance "
                name="Date première"
                rules={[
                  { required: true, message: "Choisir une date SVP !!!" },
                ]}
              >
                <DatePicker
                  style={{ width: "110%" }}
                  className=""
                  id="date"
                  value={datepremiere}
                  onChange={(date, dateString) => setdatepremiere(dateString)}
                  format={dateFormatList}
                />
              </Form.Item>{" "}
              
                 <Form.Item label="Date dernière échéance">
                <Input
                  style={{ width: "110%" }}
                  disabled='true'
                  value={datedernier}
                  onChange={setdatedernier}
                  type="text"
                  placeholder="Date dernière échéance"
                />
                </Form.Item>
              {/*<Form.Item
                label="Date dernière échéance "
                name="Date dernière"
                /*rules={[{ required: true, message: "Choisir une date SVP !!!" },]}
              >
              </Form.Item>
                <DatePicker
                  style={{ width: "110%" }}
                  className=""
                  disabled='true'
                  id="date"
                  //value={datedernier}
                  onChange={setdatedernier}
                  //format={dateFormatList}
                />
              </Form.Item>*/}
              {/* <Form.Item label="Taux d'intérêt (%TPS)">
              <Input value="" type="number" placeholder="Taux d'intérêt" />
            </Form.Item>{" "} */}
              <Form.Item label="Périodicité remboursement">
                <Select
                  value={periodicite}
                  onChange={(value) => setperiodicite(value)}
                  style={{ width: 200 }}
                >
                  <Option value="mensuel">Mensuel</Option>
                  <Option value="trimestriel">Trimestriel</Option>
                  <Option value="semestriel">Semestriel</Option>
                  <Option value="annuel">Annuel</Option>
                </Select>
              </Form.Item>

              <Button style={{ marginLeft:230 }} className="mt-3" type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

