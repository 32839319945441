import React, { Component } from "react";
import "../assets/css/style.css";
import pbf from "../assets/beneficial 1.png";
import nfc from "../assets/nfc1.png";
import test from "../assets/test.png";

//import { connect } from "react-redux";

const xaf = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "XAF",
  minimumFractionDigits: 0,
});

export default class EmpruntDirectPrint extends Component {
  render() {
    const { printData } = this.props;  
    const {
      nom_client,
      prenoms_client,
      numero_compte,
      email,
      cni,
      niu,
      telephone,
      date_naissance,
      prime,
      prime_brute,
      prime_ht,
      accessoires,
      montant_tva,
      prime_ttc,
      profession,
      quote_part_partenaire,
      quote_part_prubli,
      ville,
      typeoperation,
      montantcapital,
      typecompte,
      //agence
      code_agence,
      libelle_agence,
      usernom,
      userprenom,
      //client
      nomClient,
      numeroPolice,
      printSee,
      adresse_postale,
      DateNai,
      //
      //
      refdossier,
      dureedifere,
      dateeffet,
      datemiseplace,
      montantecheance,
      datepremiere,
      datedernier,
      periodicite,
      dureePret,
    } = printData;
  
     var today = new Date(),
       date =
         today.getFullYear() +
         "-" +
         (today.getMonth() + 1) +
         "-" +
         today.getDate();
    return (
      <>
        <div
          className="container"
          style={{
            backgroundImage: !printSee ? `url(${test})` : null,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <header>
            <div className="row">
              <div className="col-3" id="logo_nfcBank">
                <img
                  style={{ height: 130, width: 130 }}
                  src={nfc}
                  alt="NFC bank"
                />
              </div>
              <div className="col-6 text-center">
                <h1>
                  <strong>
                    FORMULAIRE NFC BANK <br />
                    EMPRUNTEUR
                    {!printSee && <h3 style={{ color: "red" }}>simulation</h3>}
                  </strong>
                </h1>
                <strong>
                  Formulaire d'Adhèsion tenant lieu d'Attestation d'Assurance
                </strong>
                <br />
                Individual subscription form, serves as Insurance Attestation
              </div>
              <div className="col-3" id="logo_beneficial">
                <img
                  style={{ height: 150, width: 270 }}
                  src={pbf}
                  alt="beneficial"
                />
                <span>
                  N°: <strong>{numeroPolice}</strong>
                </span>
              </div>
            </div>
          </header>

          <main style={{ padding: "12px" }}>
            <div className="row">
              <span className="col-12 mb-3">
                <strong>
                  CADRE RESERVE A NFC BANK / RESERVED FOR NFC BANK{" "}
                </strong>
              </span>
              <span className="col-6">
                Chargé de clientèle/Account manager:
                <strong>{userprenom}</strong>
                <strong>{usernom} </strong>
              </span>
              <span className="col-3">
                Agence/<span className=" italic">Branch: </span>
                <strong>{libelle_agence}</strong>
              </span>
              <span className="col-3">
                Code Agence /{" "}
                <span className="italic">
                  Branch Code: <strong>{code_agence}</strong>{" "}
                </span>
              </span>
            </div>

            <hr />

            <div className="row">
              <span className="col-12 underline mb-3">
                <strong>1. CONTRACTANT/SUBSCRIBER</strong>
              </span>
              <span className="col-4">
                Nom/Surname: <strong>{nom_client}</strong>{" "}
              </span>
              <span className="col-4">
                Prénom/Given Names:
                <strong>{prenoms_client}</strong>
              </span>
              <span className="col-8">
                Date et lieu de naissance/Date and place of Birth:
                <strong>{DateNai}</strong>
              </span>
              <span className="col-4">à/at: </span>
              <span className="col-6">
                N° de compte/Account number: <strong>{numero_compte}</strong>
              </span>
              {/* <span className="col-6">Intitulé de compte: 456 </span>

                      <span className="col-6 italic">Account Name</span> */}
              <span className="col-4">
                CNI N°: <strong>{cni}</strong>
              </span>
              {/* <span className="col-4">du 12/02/2016</span>
                      <span className="col-4">à 12/02/2026</span> */}
              {/* <span className="col-4 italic">ID</span> */}
              {/* <span className="col-4 italic">of</span>
                      <span className="col-4 italic">at</span> */}
              {/* <span className="col-4">Situation matrimoniale:</span> */}
              {/* <span className="col-2">
                        Célibataire <input type="checkbox" checked />
                      </span> */}
              {/* <span className="col-2">
                        Marié(e) <input type="checkbox" disabled />
                      </span> */}
              {/* <span className="col-2">
                        Divorcé(e) <input type="checkbox" disabled />
                      </span> */}
              {/* <span className="col-2">
                        Veuf(ve) <input type="checkbox" disabled />
                      </span> */}
              {/* <span className="col-4">Matrimoniale status</span> */}
              {/* <span className="col-2 italic">Single</span>
                      <span className="col-2 italic">Maried</span>
                      <span className="col-2 italic">Divorced</span>
                      <span className="col-2 italic">Widow(er)</span> */}
              <span className="col-4">
                Adresse postal/Postal adress: <strong>{adresse_postale}</strong>{" "}
              </span>
              <span className="col-4">
                Ville/Town: <strong>{ville}</strong>
              </span>
              <span className="col-4">
                Cellulaire/Mobile: <strong>{telephone}</strong>
              </span>

              <span className="col-6">
                Profession/Occupation: <strong>{profession}</strong>{" "}
              </span>
              <span className="col-4">
                E-mail: <strong>{email}</strong>{" "}
              </span>
              <span className="col-4">
                N° contribuable/N° ID: <strong>{niu}</strong>{" "}
              </span>
            </div>

            <hr />

            <div className="row">
              <span className="col-12 underline">
                <strong>2.BENEFICIARES/BENEFICIARIES</strong>
              </span>{" "}
              <br />
              <span className="col-12">
                <strong>NFC BANK</strong> est le beneficiare principal et ne
                peut pas être retiré ou remplacé sans son avis écrit.
              </span>
              <span className="col-12">
                <strong>NFC BANK</strong>{" "}
                <span className="italic">
                  {" "}
                  is the main beneficiary and may not be removed or replaced
                  without her written consent.
                </span>
              </span>
            </div>

            <hr />

            <div className="row">
              <span className="col-12 underline mb-2">
                <strong>3.GARANTIES/GUARANTIES</strong>
              </span>{" "}
              <br />
              <span className="col-4">
                Type opération:{" "}
                <strong className="italic"> {typeoperation} </strong>{" "}
              </span>
              <span className="col-4">
                Montant du capital:
                <strong> {xaf.format(montantcapital)}</strong>
              </span>
              {/* <span className="col-12 italic">File reference</span> */}
              {/* <span className="col-6"> 03</span> */}
              <span className="col-4">
                Date de mise en place: <strong>{datemiseplace} </strong>
              </span>
              {/* <span className="col-6 italic">Duration of loan repayment</span> */}
              {/* <span className="col-6 italic">Starting date:</span> */}
              <span className="col-4">
              Durée du differé: <strong>{dureedifere} Mois</strong>
              </span>
              {/* <span className="col-6 italic">Duration of differed payment</span>
              <span className="col-6 italic">Amount of an instalment:</span> */}
              <span className="col-4">
                Montant d'une échéance: <strong>{montantecheance} FCFA</strong>
              </span>
              <span className="col-4">
                Date de la dernière échéance:
                <strong> {datedernier} </strong>
              </span>
              <span className="col-4">
                Durée de remboursement du prêt:{" "}
                <strong>{dureePret} Mois</strong>
              </span>
              <span className="col-4">
                Date de la première échéance:
                <strong> {datepremiere} </strong>
              </span>
              {/* <span className="col-6 italic">
                Loan duration (reimbursement - deferred)
              </span> */}
              {/* <span className="col-6 italic">First instalment date</span> */}
              <span className="col-4">
                Date d'effet de la garantie:
                <strong> {dateeffet} </strong>
              </span>
              <span className="col-4">
                Durée du prêt (Remboursement - differé):{" "}
                <strong>
                  {" "}
                  {parseInt(dureedifere) + parseInt(dureePret)} Mois{" "}
                </strong>
              </span>
              <span className="col-4">
                Reférence dossier: <strong>{refdossier}</strong>
              </span>
              <span className="col-4">
                Périodicité du remboursement:
                <strong> {periodicite}</strong>
              </span>
              {/* <span className="col-6 italic">
                Effective date of the guarantee
              </span> */}
              {/* <span className="col-6 italic">Last instalment date</span> */}
              <p className="col-12 mt-3">
                En cas de décés de l'assuré ou en cas d'invalidité absolue et
                definitive avant le terme du contract, le capital garanti est
                égal à l'encours régulier du prêt dans la limite des montants
                indiqués au tableau d'amortissement joint au formulaire. <br />
                <span className="italic">
                  In the event of the death of the insured or in the event of
                  absolute and definitive disability before the end of the
                  contract, the guaranteed capital is equal to the regular
                  outstanding loan within the limit of the amounts indicated in
                  the amortization table attached to the form.
                </span>
              </p>
            </div>

            <hr />

            <div className="row">
              <span className="col-12 mb-3">
                <strong>4.PRIME/PREMIUM</strong>
              </span>
              {/* <span className="col-12">
                        Mode de paiement/
                        <span className="italic">Mode of payment</span>: &nbsp;
                        Prime unique/
                        <span className="italic">Single premium</span> : &nbsp;{" "}
                        <input type="checkbox" checked />{" "}
                      </span> */}

              <span className="col-4">
                Montant standard/Standard Amount:
                <strong>{xaf.format(prime_ht)}</strong>
              </span>
              <span className="col-3">
                Surprime/Extra-premium:
                <strong> {xaf.format(0)}</strong>
              </span>
              <span className="col-3 ">
                accessoires/accessories:
                <strong> {xaf.format(accessoires)}</strong>
              </span>
              <span className="col-2">
                TVA/VAT:
                <strong>
                  <strong>{xaf.format(prime_ht * 0.1925)}</strong>
                </strong>
              </span>
              <span className="col-4">
                Prime TTC/Total Premium VAT :{" "}
                <strong>{xaf.format(prime_ttc)}</strong>
              </span>
            </div>

            <hr />

            <div className="row">
              <div className="col-12">
                NB: Pour tout Assuré dont le capital est supérieur à DIX
                MILLIONS DE FRANCS (10 000 000 FCFA). L'acceptation de la
                couverture est soumise à
                <strong>PRUDENTIAL BENEFICIAL LIFE</strong>. Au dela de VINGT
                MILLIONS DE FRANCS (20.000.000 FCFA) et en fonction de la durée
                du prêt, il est exigé une visite médicale pratiquée par un
                médecin conseil agrée par{" "}
                <strong>PRUDENTIAL BENEFICIAL LIFE</strong> <br />
                <span className="italic">
                  NB: For any Insured whose capital is greater than TEN MILLION
                  FRANCS (10 000 000 FCFA). the approval of coverage is submited
                  to <strong>PRUDENTIAL BENEFICIAL LIFE</strong>. For each
                  insured whose capital is greate tah TWENTY MILLION FRANCS (20
                  000 000 FCFA), a medical exams is carry out by a doctor
                  designated by <strong>PRUDENTIAL BENEFICIAL LIFE</strong>.{" "}
                  <br />
                </span>
                Conformément aux dispositions des articles 13-1 et 73 du code
                CIMA, la prise d'effet des garanties du présent contract est
                subordonnées au paiement de la prime ou de la première fraction
                de prime.
                <br />
                <span className="italic">
                  In accordance with the provisions of articles 13-1 and 73 of
                  the CIMA code, the effective date od the guaantees of this
                  contract is subjet to payement of the premium or the first
                  fraction of the premium. <br />
                </span>
                Par le présent formulaire, j'autoise <strong>NFC BANK</strong> à
                débiter de mon compte le montant de la prime ci-dessus indiquée
                au profit de
                <strong>PRUDENTIAL BENEFICIAL LIFE</strong> <br />
                <span className="italic">
                  I hereby autorise <strong>NFC BANK</strong> to pay from my
                  account the above premium to{" "}
                  <strong>PRUDENTIAL BENEFICIAL LIFE</strong>
                </span>
              </div>
            </div>
          </main>

          <footer>
            <div className="row">
              <span className="col-8"></span>
              <span className="col-4">
                Fait à/at : {""}Douala {""}le/on {""}
                <strong>{date}</strong>
                {/* {""} {""} {""} {contrat.date_enregistrement} */}
              </span>
            </div>{" "}
            <br />
            <div className="row">
              <span className="col-5 underline">
                Signature du contractant/
                <span className="italic">Subscriber's Signature</span>
              </span>
              <span className="col-3">
                <span className="underline">Signature</span>{" "}
                <strong>NFC BANK</strong>
              </span>
              <span className="col-4 underline">
                Signature de <strong>PRUDENTIAL BENEFICIAL LIFE</strong>
              </span>
              <span className="col-5 footer_size">
                Lu et approuvé certifié sincére et exact. (Apposé à la main)
              </span>
              <span className="col-3 footer_size">
                <strong>NFC BANK</strong>{" "}
                <span className="underline">Signature</span>{" "}
              </span>
              <span className="col-4 footer_size text-center">
                <strong>PRUDENTIAL </strong>Signature
              </span>
              <span className="col-5 italic footer_size">
                Read and approved, certified sincere and exact. (Hand written)
              </span>
              <span className="col-3"></span>
              <span className="col-4  footer_size">
                (No, requise pour capital inférieur ou égal à 10 000 000 FCFA)
              </span>

              <span className="col-8"></span>
              <span className="col-4  footer_size">
                (Not required for capital less than or eaqual to 10 000 000
                FCFA)
              </span>
              <span className="italic"></span>
            </div>
          </footer>
        </div>
      </>
    );
  }
}
