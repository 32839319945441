import React, { Component } from "react";
import { Table, Input, Button, Space } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { listUsers } from "./redux/actions/userActions";
import TransfertUser from "./TransfertUser";
import RolesUser from "./RolesUser";
import { Parser } from "@json2csv/plainjs";
import { DownloadOutlined } from "@ant-design/icons";
import XLSX from "xlsx";
import { saveAs } from "file-saver";

class gesUser extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  componentDidMount() {
    this.props.listUsers();
  }

  render() {
    const { users, loading } = this.props.usersList;

    const data = users ? users.root_liste_users : [];

    let data2 = users.root_liste_users ?? [];


    const { userInfo } = this.props.userSignin;

    const columns = [
      {
        title: "Nom",
        dataIndex: "nom",
        key: "nom",
        width: "20%",
        ...this.getColumnSearchProps("nom"),
      },
      {
        title: "Prenom",
        dataIndex: "prenoms",
        key: "prenom",
        width: "20%",
        ...this.getColumnSearchProps("prenom"),
      },
      {
        title: "Login",
        dataIndex: "login",
        key: "login",
        width: "20%",
        ...this.getColumnSearchProps("login"),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ...this.getColumnSearchProps("email"),
      },
      {
        title: "Code banque",
        dataIndex: "code_banque",
        key: "code_banque",
        ...this.getColumnSearchProps("code_banque"),
      },
      {
        title: "Code agence",
        dataIndex: "code_agence",
        key: "code_agence",
        ...this.getColumnSearchProps("code_agence"),
      },
      {
        title: "Rôle",
        dataIndex: "libelle_type_utilisateur",
        key: "libelle_type_utilisateur",
        ...this.getColumnSearchProps("libelle_type_utilisateur"),
      },
      {
        title: "Cree Par",
        dataIndex: "code_parent",
        key: "code_parent",
        ...this.getColumnSearchProps("code_parent"),
      },
      {
        title: "Etat Activite",
        dataIndex: "etat_activite",
        key: "etat_activite",
        ...this.getColumnSearchProps("etat_activite"),
      },
      {
        title: "Transfert",
        fixed: "right",
        key: "action",
        width: "6%",
        render: (text, record) => (
          <div>
            {userInfo.code_type_utilisateur === "1" && (
              <TransfertUser users={data} id={record.id} />
            )}
          </div>
        ),
      },
      {
        title: "Roles",
        fixed: "right",
        key: "roles",
        width: "6%",
        render: (text, record) => (
          <div className="delete">
            {userInfo.code_type_utilisateur === "1" && (
              <RolesUser users={data} id={record.id} />
            )}
          </div>
        ),
      },
    ];

    const handleExport = () => {
      const data = [
        columns.map((col) => col.title),
        ...data2.map((item) => columns.map((col) => item[col.dataIndex])),
      ];
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "table.xlsx");
    };

    return (
      <>
        <h1 className="my-3"> Liste utilisateurs</h1>

        <div>
          <Table dataSource={data} columns={columns} />
          <Button
            onClick={handleExport}
            icon={<DownloadOutlined />}
            type="primary"
          >
            Export
          </Button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usersList: state.usersList,
    userSignin: state.userSignin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listUsers: () => dispatch(listUsers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(gesUser);
