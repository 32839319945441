import React, { useState } from "react";
import { Modal, Button } from "antd";

export default function FormMed(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    poid,
    taille,
    grossi,
    assurance_vie,
    compagnie_assurance,
    maladie_chronique,
    traitement_regulier,
    interruption_travail,
    interruption_observation,
  } = props.fmData;
  const isOk =
    poid !== "" &&
    taille !== "" &&
    grossi !== "" &&
    assurance_vie !== "" &&
    maladie_chronique !== "" &&
    traitement_regulier !== "" &&
    interruption_travail !== "" 
  const {
    setpoid,
    settaille,
    setgrossi,
    setassurance_vie,
    setcompagnie_assurance,
    setmaladie_chronique,
    settraitement_regulier,
    setinterruption_travail,
    setinterruption_observation,
  } = props.fmFunc;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    props.setformOk(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        QM  
      </Button>
      <Modal
        width={1200}
        title="Formulaire médical "
        visible={isModalVisible}
        onOk={handleOk}
        okButtonProps={{ disabled: !isOk }}
        onCancel={handleCancel}
        style={{ zIndex:999 }}
        
      >
        <form class="needs-validation">
          <div class="form-group row">
            <div class="col-sm-4  mb-sm-0">
              <p>
                <label for="poids">
                  Poids<span>/Weight (kg) </span> :
                </label>
                <input
                  value={poid}
                  onChange={(e) => setpoid(e.target.value)}
                  type="number"
                  min="50"
                  max="250"     
                  class="form-control"
                  id="poids"
                  name="poids"
                  required
                />
              </p>
            </div>

            <div class="col-sm-4 mb-3 mb-sm-0">
              <label for="assurance">
                Avez-vous actuellement ne assurance vie ?
                <span>/Do you currently have life insurance?</span> :
              </label>
              <div onChange={(e) => setassurance_vie(e.target.value)}>
                {" "}
                <input
                  type="radio"
                  class="from-check-label"
                  id="assurance"
                  name="assurance"
                  value="oui"
                />{" "}
                OUI&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  class=""
                  id="assurance"
                  name="assurance"
                  value="non"
                />{" "}
                NON
              </div>
            </div>
            <div class="col-sm-4 mb-3 mb-sm-0">
              <label>
                Suivez-vous actuellement un traitement regulier?
                <span>/Are you currently on regular treatment?</span>
              </label>
              <div onChange={(e) => settraitement_regulier(e.target.value)}>
                <input
                  type="radio"
                  class="from-check-label"
                  id="maladie"
                  name="maladie"
                  value="oui"
                />{" "}
                OUI&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  class=""
                  id="maladie"
                  name="maladie"
                  value="non"
                />{" "}
                NON
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-4  mb-sm-0">
              <label for="taille">
                Taille<span>/Height (cm) </span> :
              </label>
              <input
                type="number"
                class="form-control"
                id="taille"
                min="100"
                max="300"
                name="taille"
                value={taille}
                onChange={(e) => settaille(e.target.value)}
              />
            </div>
            <div class="col-sm-4 mb-3 mb-sm-0">
              <label for="yes">
                Si oui,à quelle compagnie et pour quelle montant?
                <span>/if so, to which company and for what amount?</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="yes"
                name="yes"
                value={compagnie_assurance}
                onChange={(e) => setcompagnie_assurance(e.target.value)}
              />
            </div>

            <div class="col-sm-4 mb-3 mb-sm-0">
              <label>
                Durant les 3 dernieres annees, Avez-vous du interrompe votre
                travail pour des raisons medicales pendant plus de 3 semaines
                <p>
                  /During the past 3 years, have you had to interrupt your work
                  for medical reasons for more than 3 weeks?
                </p>
              </label>
              <div onChange={(e) => setinterruption_travail(e.target.value)}>
                <input
                  type="radio"
                  class="from-check-label"
                  id="observation"
                  name="observation"
                  value="oui"
                />{" "}
                OUI&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  class=""
                  id="observation"
                  name="observation"
                  value="non"
                />{" "}
                NON
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-4 mb-3 mb-sm-0">
              <label for="mois">
                Depuis 6 mois/Since 6 months<span>/Avez-vous/Have you</span> :
              </label>
              <div onChange={(e) => setgrossi(e.target.value)}>
                {" "}
                <input
                  type="radio"
                  class="from-check-label"
                  id="mois"
                  name="mois"
                  value="oui"
                  required
                />{" "}
                GROSSI/PUT ON WEIGHT
                <input
                  type="radio"
                  class=""
                  id="mois"
                  name="mois"
                  required
                  value="non"
                />
                MAIGRI/LOST WEIGHT
              </div>
            </div>
            <div class="col-sm-4 mb-3 mb-sm-0">
              <label for="maladie">
                Avez-vous souffert ou souffrez-vous d'une maladie chronique ou
                d'une infirmité importante ?
                <span>
                  /Have you suffered or do you siffer from chronic illness or
                  significant disability?
                </span>{" "}
                :
              </label>
              <div onChange={(e) => setmaladie_chronique(e.target.value)}>
                <input
                  type="radio"
                  class="from-check-label"
                  id="chronique"
                  name="chronique"
                  value="oui"
                />{" "}
                OUI &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  type="radio"
                  class=""
                  id="chronique"
                  name="chronique"
                  value="non"
                />{" "}
                NON
              </div>
            </div>
            <div class="col-sm-4 mb-3 mb-sm-0">
              <label for="yes">
                Si oui,lequel et depuis quand?
                <span>/if so, which one and since when?</span> :
              </label>
              <input
                value={interruption_observation}
                onChange={(e) => setinterruption_observation(e.target.value)}
                type="text"
                class="form-control"
                id="LastName"
              />
            </div>
          </div>

          <div>
            <p>
              je certifie avoir répondu sincèrement, sans aucune réticence et
              n'avoir pas rien dissimilé sur mon état de santé passé ou actuel
              et prends acte que toute reticence ou fausse déclaration de ma
              part entrainera la nullité du contrat. J'autorise expressément la
              compagnie à prendre toutes les informations demandés qu'elle
              jugerait utiles et necessaire aupres des médecins qui m'ont
              soigné.j'autorise ces médecins à communiquer à la compagnie tous
              les renseignements demandés.
            </p>
            <p>
              I certify that i answered sincerely, without any reluctance and
              that
            </p>
          </div>
          <div></div>
        </form>
      </Modal>
    </>
  );
}
