import {  combineReducers } from "redux";

import {
  userSigninReducer,
  userRegisterReducer,
  usersListReducer,
} from "./userReducers";

import {
  primePretListReducer,
  primeDecListReducer,
  primePretUpdateReducer,
  primeAddReducer,
  primePretDeleteReducer,
  primeDecUpdateReducer,
  primeDecDeleteReducer,
  primeDecAddReducer,
} from "./tauxPrimeReducer";
import { calculPretReducer } from "./calculPretReducer"
import { contratAddReducer, contratListReducer } from "./contratReducer";
import { agenceListReducer } from "./agenceReducer"
import { fmAddReducer, qmGetReducer } from "./fmReducer";
import {accountListReducer}from "./accountReducer"

const reducers = combineReducers({
  accountList: accountListReducer,
  primeDec: primeDecListReducer,
  primePret: primePretListReducer,
  tauxPret: calculPretReducer,
  contratAdd: contratAddReducer,
  listContrat: contratListReducer,
  primePretUpdate: primePretUpdateReducer,
  primePretAdd: primeAddReducer,
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  primePretDelete: primePretDeleteReducer,
  primeDecUpdate: primeDecUpdateReducer,
  primeDecDelete: primeDecDeleteReducer,
  primeDecAdd: primeDecAddReducer,
  agenceList: agenceListReducer,
  fmAdd: fmAddReducer,
  qmGet: qmGetReducer,
  usersList: usersListReducer,
});

export default reducers;
