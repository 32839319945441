import React, { Component,useState,useEffect} from "react";
import { connect , useDispatch, useSelector} from "react-redux";
import { listContrat } from "./redux/actions/ContratActions";
import {Table,Input, Button,Space,DatePicker,Select,Modal,Form} from "antd";
import Highlighter from "react-highlight-words";
import {ConsoleSqlOutlined, SearchOutlined} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import Axios from "axios";
import PrintContrat from "./print/PrintContrat";
import SimulateurContrat from "./SimulateurContrat";
import QMedical from "./QMedical";
import UpdateContrat from "./UpdateContrat";
import HistoriqueEtat from "./HistoriqueEtat";
import { listAgence } from "./redux/actions/agenceActions"
 

class ReportingNews extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    startDate: "",
    endDate: "",
    filter: false,
    statut: "",
    filterStatut: false,
    filterdate: false,
    showNomClient:false,
    showAgence:false,
    showNumeroPolice:false, 
    critereAdditionnel:false,
    nomClient:"",
    numeroPolice:"",
    valeurCapital:"", 
    numeroCompte:"",
    typeProduit:"",
    typeStatut:"",
    valideur:"",
    data_new:[],
    isLoading: false,
    dateNaissanceNew:"",
    codeAgenceSelect:"",    
  };

  

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  dateRange = (date, dateStrings, info) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];
    this.setState({
      startDate: startDate,
      endDate: endDate,
      //filter: true,
    });
  }; 

  datefilter = (date, dateStrings, info) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];
    this.setState({
      startDate: startDate,
      endDate: endDate,
      //filterdate: true,
    });
  };  


  


  async  chargerData () {   

    const criAdditionnel = this.critereAdditionnel;
    var valeurCriAdditionnel = '';

    switch(criAdditionnel) {
      case 'agence':
        valeurCriAdditionnel=this.state.codeAgenceSelect;  
        break;
      case 'nom_client':
        valeurCriAdditionnel=this.state.nomClient;  
        break;
      case 'numero_police':
        valeurCriAdditionnel=this.state.numeroPolice;
        break;
      case 'capital':
        valeurCriAdditionnel=this.state.valeurCapital;
        break;
      case 'numero_compte':
        valeurCriAdditionnel=this.state.numeroCompte;
        break;
      case 'type_produit':
        valeurCriAdditionnel=this.state.typeProduit;
        break;
      case 'type_statut':
        valeurCriAdditionnel=this.state.typeStatut;
        break;   
      case 'valide_par':
        valeurCriAdditionnel=this.state.valideur;
        break;  
      case 'tous':
        valeurCriAdditionnel='1'
        break;
      default: 
      break;        
    }
    
   

    const etatData = new FormData();
    etatData.append("date_debut", this.state.startDate);
    etatData.append("date_fin", this.state.endDate);
    etatData.append("critere_additionnel", this.critereAdditionnel);
    etatData.append("valeur",valeurCriAdditionnel);
    this.setState({ ...this.state, isLoading: true});

    Axios({
      method: "post",
      url: "/prubli_ma/ss/liste_contrat_new.php",
      data: etatData,
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
        this.setState({ data_new: response.data.root_liste_contrat,data_benef_new:response.data.root_ayant_droit,
           isLoading: false, filter: false, filterStatut: false,
        filterdate: false });
    }, (error) => {
      this.setState({ ...this.state, isLoading: false})
    });

    Axios({
      method: "get",
      url: "/prubli_ma/ss/afficher_liste_agence.php",
    })
    .then((response) => {
      console.log(response.data)
    })
    .catch((error) => {
      console.log(error);
    });

  
  };
  
  

  // fonctions pour selection multiple
  handleChange(record) {
  }

  listCont = () => {
    this.props.listContrats();
  };

  listAg = () => {
    this.props.listAgences();
  };

  componentDidMount() {           // Est invoqué immédiatement après le montage du composant / componentDidMount ne pas l'appeler plus d'une fois 
                                  // il ne vas pas s"executer la seconde fois
    this.props.listContrats();

    this.props.listAgences();

  }
  
 

  

  async handleTypeAgenceChange(event){ 

    console.log('event:'+event);

    await this.setState({codeAgenceSelect: event});    

  }

  async handleNomClientChange(event){ 
    await this.setState({nomClient: event.target.value});      
  }

  async handleNumeroPoliceChange(event){ 
    await this.setState({numeroPolice: event.target.value});      
  }

  async handleValeurCapitalChange(event){ 
    await this.setState({valeurCapital: event.target.value});      
  }

  async handleNumeroCompteChange(event){ 
    await this.setState({numeroCompte: event.target.value});      
  }

  async handleTypeProduitChange(value){ 
    await this.setState({typeProduit:value});      
  }

  async handleTypeStatutChange(value){ 
    await this.setState({typeStatut:value});      
  }  
  
  async handleValideur(value){ 
    await this.setState({valideur:value});      
  }  
  

   
  statutChange = (value) => {
    
    this.critereAdditionnel=value;

    switch(value) {
      case 'agence': 
        this.setState({ showAgence: true });                                         
        this.setState({ showNomClient: false });
        this.setState({ showNumeroPolice: false });
        this.setState({ showValeurCapital: false });
        this.setState({ showNumeroCompte: false });
        this.setState({ showTypeProduit: false });
        this.setState({ showTypeStatut: false }); 
        this.setState({ showGroupeUser: false });       
        break;
      case 'nom_client':
        this.setState({ showAgence: false });                                            
        this.setState({ showNomClient: true });
        this.setState({ showNumeroPolice: false });
        this.setState({ showValeurCapital: false });
        this.setState({ showNumeroCompte: false });
        this.setState({ showTypeProduit: false });
        this.setState({ showTypeStatut: false }); 
        this.setState({ showGroupeUser: false });       
        break;
      case 'numero_police':
        this.setState({ showAgence: false });  
        this.setState({ showNomClient: false });
        this.setState({ showNumeroPolice: true });
        this.setState({ showValeurCapital: false });
        this.setState({ showNumeroCompte: false });
        this.setState({ showTypeProduit: false });
        this.setState({ showTypeStatut: false }); 
        this.setState({ showGroupeUser: false });
        break;
      case 'capital':
        this.setState({ showAgence: false });  
        this.setState({ showNomClient: false });
        this.setState({ showNumeroPolice: false });
        this.setState({ showValeurCapital: true });
        this.setState({ showNumeroCompte: false });
        this.setState({ showTypeProduit: false });
        this.setState({ showTypeStatut: false }); 
        this.setState({ showGroupeUser: false });
        break;
      case 'numero_compte':
        this.setState({ showAgence: false });  
        this.setState({ showNomClient: false });
        this.setState({ showNumeroPolice: false });
        this.setState({ showValeurCapital: false });
        this.setState({ showNumeroCompte: true });
        this.setState({ showTypeProduit: false });
        this.setState({ showTypeStatut: false });
        this.setState({ showGroupeUser: false });
        break;
      case 'type_produit':
        this.setState({ showAgence: false });  
        this.setState({ showNomClient: false });
        this.setState({ showNumeroPolice: false });
        this.setState({ showValeurCapital: false });
        this.setState({ showNumeroCompte: false });
        this.setState({ showTypeProduit: true });
        this.setState({ showTypeStatut: false });
        this.setState({ showGroupeUser: false });
        break;
      case 'type_statut':
        this.setState({ showAgence: false });  
        this.setState({ showNomClient: false });
        this.setState({ showNumeroPolice: false });
        this.setState({ showValeurCapital: false });
        this.setState({ showNumeroCompte: false });
        this.setState({ showTypeProduit: false });
        this.setState({ showTypeStatut: true });
        this.setState({ showGroupeUser: false });
        break; 
      case 'valide_par':
          this.setState({ showAgence: false });  
          this.setState({ showNomClient: false });
          this.setState({ showNumeroPolice: false });
          this.setState({ showValeurCapital: false });
          this.setState({ showNumeroCompte: false });
          this.setState({ showTypeProduit: false });
          this.setState({ showTypeStatut: false });
          this.setState({ showGroupeUser: true });
          break;    
      case 'tous':
        this.setState({ showAgence: false });  
        this.setState({ showNomClient: false });
        this.setState({ showNumeroPolice: false });
        this.setState({ showValeurCapital: false });
        this.setState({ showNumeroCompte: false });
        this.setState({ showTypeProduit: false });
        this.setState({ showTypeStatut: false });
        this.setState({ showGroupeUser: false });
        break; 
      default: 
      break;        
    }
  };

  downloadTxtFile = (exportData) => {
    const element = document.createElement("a");
    const file = new Blob( exportData, {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element);
    element.click();
  };

  
  constructor(props) {
    
    super(props);
    this.handleTypeAgenceChange = this.handleTypeAgenceChange.bind(this)
    this.handleNomClientChange = this.handleNomClientChange.bind(this)
    this.handleNumeroPoliceChange = this.handleNumeroPoliceChange.bind(this)
    this.handleValeurCapitalChange = this.handleValeurCapitalChange.bind(this)
    this.handleNumeroCompteChange = this.handleNumeroCompteChange.bind(this) 
    this.handleTypeProduitChange = this.handleTypeProduitChange.bind(this) 
    this.handleTypeStatutChange = this.handleTypeStatutChange.bind(this)  
    this.handleValideur = this.handleValideur.bind(this)  
    //this.typeOperationSwitch=this.typeOperationSwitch.bind(this)    
  }
  
  render() {
      
    const { contrats, loading } = this.props.listContrat; // ici   

    const  data_new=this.state.data_new;  

    const { userInfo } = this.props.userSignin;
    const { Option } = Select;
    const data_news = data_new
      ? data_new.root_liste_contrat
        ? data_new.root_liste_contrat
        : []
      : [];
      const beneInfo = data_new
      ? data_new.root_ayant_droit
        ? data_new.root_ayant_droit
        : []
      : [];
    
    // console.log(localStorage.getItem('listAgence'));

    //console.log('data_news : '+ JSON.stringify(data_news));

    // 0	Super Admin /  1	Admin/  2	Chef D Agence/  3	Utulisateurs/  4	Autres Personnel/  5	Gestionnaire Credit /  6	Gestionnaire PruBli

    const dataxx = data_news.filter((value) => {
      if (['2', '3', '4'].includes(userInfo.code_type_utilisateur)) {  

        return value.code_agence === userInfo[0].code_agence;
        
      }
      else {      

        return value.code_agence;        

      }
  
    });

    //console.log('user connecté info :'+userInfo.code_type_utilisateur);
    
    const listAgence=JSON.parse(localStorage.getItem('listAgence'))|| [];

    console.log('listAgence:'+typeof(listAgence));

    console.log('listAgence:'+JSON.stringify(listAgence));

    const agenceAutorise = listAgence.filter((value) => {

      
      if (['2', '3', '4'].includes(userInfo.code_type_utilisateur)) {  


        return value.code_agence === userInfo[0].code_agence;
        
      }
      else {      

        return value.code_agence;        

      }
  
    });

    console.log('liste des agence a editer:'+JSON.stringify(agenceAutorise));

 
  

    

    
      
    const datax = dataxx.filter((value) => {

      if(localStorage.getItem('choixProduit')!=='all')
      {
        return value.type_client === localStorage.getItem('choixProduit'); 
      }
      else
      {
        return value.type_client;
      }
      
    });


    let libelleResult = datax.filter((value) => {
      return value.libelle_etat_contrat === this.state.statut;
    });

    let DateLibelle = libelleResult.filter((obj) => {
      return (
        obj.date_enregistrement.substr(0, 10) >= this.state.startDate &&
        obj.date_enregistrement.substr(0, 10) <= this.state.endDate
      );
    });

    const dataStatut = this.state.filterStatut ? libelleResult : datax;

    const libelleDate = this.state.filterdate ? DateLibelle : dataStatut;

   let DateResult = datax.filter((obj) => {
      return (
        obj.date_enregistrement.substr(0, 10) >= this.state.startDate &&
        obj.date_enregistrement.substr(0, 10) <= this.state.endDate
      );
    });

    
    const resultData = this.state.filter ? DateResult : datax;
    const headers = [
      { label: "Nom Clients", key: "nom_client" },
      { label: "Prenoms Clients", key: "prenoms_client" },
      { label: "Type client", key: "type_client" },
      { label: "Type operation", key: "type_operation" },
      { label: "Type option", key: "code_type_qm" },
      { label: "Capital", key: "capital" },
      { label: "Numéro du compte", key: "numero_compte" },
      { label: "Numéro CNI", key: "cni" },
      { label: "Numéro ID unique", key: "niu" },
      { label: "Numéro police", key: "numero_police" },
      { label: "Prime de base", key: "prime_brute" },
      { label: "Prime Ht", key: "prime_ht" },
      { label: "Surprime", key: "surprime" },
      { label: "Accessoires", key: "accessoires" },
      { label: "TVA", key: "montant_tva" },      
      { label: "Total premium", key: "prime_ttc" },
      { label: "Part NFC", key: "quote_part_partenaire" },
      { label: "Part PBLI", key: "quote_part_prubli" },
      { label: "Compte PBLI", key: "compte_a_crediter" },
      { label: "Code Utilisateur", key: "code_utilisateur" },
      { label: "Code Agence", key: "code_agence" },
      { label: "Date Enregistrement", key: "date_enregistrement" },
      { label: "Date Debut", key: "premiere_echeance" },
      { label: "Date Fin", key: "derniere_echeance" }, 
      { label: "Duree", key: "duree" }, 
      { label: "Apporteur", key: "apporteur" },
     
        
    ];

    //const exportData = this.state.filterStatut ? libelleDate : resultData;

   // const exportData = this.state.filterStatut ? libelleDate : resultData;
  
  

    const csvReport = {
      data: data_new,
      headers: headers,
      filename: "Rapport.csv",
    };

    const columns = [
      {
        title: "Nom Client",
        dataIndex: "nom_client",
        key: "nom_client",
        width: "12%",
        ...this.getColumnSearchProps("nom_client"),
      },
      {
        title: "Prenoms",
        dataIndex: "prenoms_client",
        key: "prenoms_client",
        width: "10%",
        ...this.getColumnSearchProps("nom_client"),
      },
      {
        title: "N° police",
        dataIndex: "numero_police",
        key: "numero_police",
        width: "10%",
        ...this.getColumnSearchProps("numero_police"),
      },
      {
        title: "Capital",
        dataIndex: "capital",
        key: "capital",
        ...this.getColumnSearchProps("capital"),
      },
      {
        title: "Numéro du compte",
        dataIndex: "numero_compte",
        key: "numero_compte",
        ...this.getColumnSearchProps("numero_compte"),
      },
      {
        title: "Type client",
        dataIndex: "type_client",
        key: "type_client",
        ...this.getColumnSearchProps("type_client"),
      },
      {
        title: "Date création",
        dataIndex: "date_enregistrement",
        key: "date_enregistrement",
        sorter: (a, b) =>
          Date.parse(a.date_enregistrement) - Date.parse(b.date_enregistrement),
      },
      {
        title: "Durée",
        dataIndex: "duree",
        key: "duree",  
      },     
      {
        title: "Statut",
        key: "statut",
        width: "6%",
         sorter: (a, b) =>
        a.libelle_etat_contrat.localeCompare(b.libelle_etat_contrat),
        render: (record) => (
          <>
            <span
              className={
                record.libelle_etat_contrat === "Validé"
                  ? "badge badge-success"
                  : record.libelle_etat_contrat === "En Attente"
                  ? "badge badge-warning"
                  : record.libelle_etat_contrat === "En Attente PruBli"
                  ? "badge badge-info "
                  : record.libelle_etat_contrat === "Analyse Medicale"       
                  ? "badge badge-info "
                  : record.libelle_etat_contrat === "Avis Réassureur"
                  ? "badge badge-info "
                  : record.libelle_etat_contrat === "En Cours"
                  ? "badge badge-info "
                  : "badge badge-danger"
              }
            >
              {" "}
              {record.libelle_etat_contrat}
            </span>
          </>
        ),
      },
      {
        title: "Action",
        fixed: "right",
        key: "action",
        width: "6%",
        render: (text, record) => {
        return(
         <div className="d-flex">
            <PrintContrat contrats={this.state.data_new} beneInfo={this.state.data_benef_new} id={record.id} userInfo={userInfo} />
            {(userInfo
              ?(['1','2','5'].includes(userInfo.code_type_utilisateur) && record.libelle_etat_contrat !== "En Attente PruBli") 
                || (userInfo.code_type_utilisateur === "6" && ['En Attente PruBli','Analyse Medicale', 'Avis Réassureur','En Cours'].includes(record.libelle_etat_contrat))
              : null) &&
              !loading && (
                <ChangeEtat
                  userInfo={userInfo}
                  record={record}
                  listContrat={this.listCont}
                />
              )
            }
            {['particulier','corporate'].includes(record.type_client) && (
              <QMedical record={record} />
            )}
             <HistoriqueEtat record={record} />
         
          </div>
        )},
      },
    ];
    const { RangePicker } = DatePicker;

    return (
      <>
        {/* <button
          onClick={() => this.downloadTxtFile(JSON.parse(exportData))}
        >
          Download
        </button> */}
        <h2 className="mb-3">Rechercher Des Polices</h2> 
        {}
        <div className="d-flex justify-content-between">
          {" "}
          <div className="col-12">
            {" "}
            <strong>Critère Principal : </strong>
            <RangePicker
              onChange={this.dateRange}
              disabled={this.state.filterStatut}
              className="mx-3"
            />
            <strong>Critère Additionnel : </strong>
            <Select
              // className="mx-3"
              defaultValue=""
              style={{ width: 170 }}
              // allowClear
              onChange={this.statutChange}
              disabled={this.state.filter}
            >
              <Option value="agence">Agence</Option>
              <Option value="nom_client">Nom Du Client</Option>
              <Option value="numero_police">Numero De police</Option>
              <Option value="capital">Capital</Option>
              <Option value="numero_compte">Numero De Compte</Option>
              <Option value="type_produit">Type Produit</Option>
              <Option value="type_statut">Statut</Option>
              <Option value="valide_par">Validé Par</Option>
              <Option value="tous">Tous (All)</Option>
          
            </Select>
            {this.state.filterStatut && (
              <RangePicker
                onChange={this.datefilter}
                disabled={this.state.filter}
                // className="mx-3"
              />
            )}
            {" "}  
            {              
              this.state.showAgence &&
              <Select    
                defaultValue="Choisir l'Agence"
                style={{ width: 220 }}
                value={this.state.codeAgenceSelect} 
                onChange={this.handleTypeAgenceChange} 
              >
                 {agenceAutorise.map((option, index) =>
                <option key={index} value={option.code_agence}>
                  {option.libelle_agence}
                </option>
                )}     
              </Select>            
            }{" "}           
            {              
              this.state.showNomClient &&
                <input 
                    //name="nomClient"       
                    type="text" 
                    placeholder='Saisir le Nom du Client'
                    style={{ width: 230 }} 
                    value={this.state.nomClient} 
                    onChange={this.handleNomClientChange}                       
                />              
            }{" "}        
            {              
              this.state.showNumeroPolice &&
                <input 
                    //id="numeroPolice"       
                    type="text" 
                    placeholder='Saisir le Numero de Police'
                    style={{ width: 230 }}
                    value={this.state.numeroPolice} 
                    onChange={this.handleNumeroPoliceChange}                         
                />              
            }{" "} 
            {              
              this.state.showValeurCapital &&
                <input 
                    //id="valeurCapital"        
                    type="number" 
                    placeholder='Saisir le Capital'
                    style={{ width: 230 }} 
                    value={this.state.valeurCapital} 
                    onChange={this.handleValeurCapitalChange}                        
                />              
            }{" "} 
            {              
              this.state.showNumeroCompte &&
                <input 
                    //id="numeroCompte"      
                    type="number" 
                    placeholder='Saisir le Numero de Compte'
                    style={{ width: 230 }} 
                    value={this.state.numeroCompte} 
                    onChange={this.handleNumeroCompteChange}                         
                />              
            }{" "} 
            {              
              this.state.showTypeProduit &&
              <Select
                //id="typeProduit"    
                defaultValue="Choisir le Produit"
                style={{ width: 230 }}
                value={this.state.typeProduit} 
                onChange={this.handleTypeProduitChange} 
              >
              <Option value="parraine">Compte Parrainé</Option>
              <Option value="corporate">Emprunteur Corporate</Option>
              <Option value="particulier">Emprunteur Particulier</Option>
              <Option value="safe_card">Safe card</Option>         
              </Select>            
            }{" "} 
            {              
              this.state.showGroupeUser &&
              <Select
                //id="typeProduit"    
                defaultValue="Choisir l utilisateur"
                style={{ width: 230 }}
                value={this.state.valideur} 
                onChange={this.handleValideur} 
              >
              <Option value="5">Groupe IT</Option>
              {/* <Option value="corporate">Emprunteur Corporate</Option>
              <Option value="particulier">Emprunteur Particulier</Option>
              <Option value="safe_card">Safe card</Option>          */}
              </Select>            
            }{" "} 
            {              
              this.state.showTypeStatut &&
              <Select    
                defaultValue="Choisir le Statut"
                style={{ width: 220 }}
                value={this.state.typeStatut} 
                onChange={this.handleTypeStatutChange} 
              >
                <Option value="-2">Annulé</Option>
                <Option value="-1">Rejeté</Option>
                <Option value="0">En Attente</Option>
                <Option value="1">Validé</Option>  
                <Option value="2">En Attente PruBli</Option>
                <Option value="3">En Cours</Option>             
                <Option value="4">Avis Réassureur</Option>
                <Option value="5">Analyse Medicale</Option>   
                <Option value="6">Payé</Option>       
              </Select>            
            }{" "} 

            <Button type="primary" onClick={async () => {await this.chargerData();} }>
              Rechercher
            </Button>
          </div>
        </div>
        <div className="mt-5 mb-1 d-flex justify-content-between">
          {" "}  
          <div>
            <Button type="primary">
              <CSVLink {...csvReport}>Export to CSV</CSVLink>
            </Button>
          </div>
          <div>
            {" "}
            <UpdateContrat />
          </div>
        </div>
        {/*<Table
          rowKey={(record) => record.id}
          //loading={loading}
          columns={columns}
          dataSource={exportData}
          // className="mt-5"
        />*****/}
        <Table
          rowKey={(record) => record.id}
          loading={this.state.isLoading}
          columns={columns}
          dataSource={this.state.data_new}
          // className="mt-5"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listContrat: state.listContrat,
    listAgence: state.listAgence,
    userSignin: state.userSignin,
  };
};



const mapDispatchToProps = (dispatch) => {
  return {
    listContrats: () => dispatch(listContrat()),
    listAgences: () => dispatch(listAgence()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportingNews);

  const ChangeEtat = ({ record, listContrat, userInfo }) => {
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [etat, setetat] = React.useState();
  const [surprime, setsurprime] = React.useState(0);
  const [dateNaissanceNew, setdateNaissanceNew] = React.useState();
  const [ageClient, setAgeClient] =useState("");
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
  const [accessoireManuel, setaccessoireManuel] = React.useState(0)
  const [dureeManuel, setdureeManuel] = React.useState(0)
  const [accessoires, setaccessoires] = React.useState()
  const [duree, setduree] = React.useState(0)

  useEffect(()=>{

    setAgeClient(GetAge(record.date_naissance))

    setduree(record.duree)    
 
  }, [])

  const typeOperationSwitch = (param) =>  {
    switch(param.toUpperCase()){
      case 'P':
        return 'PRET';
      case 'D':
        return 'DECOUVERT';
      case 'C':
          return 'COMPTE PARRAINE';
      case 'S':
        return 'SAFE CARD';
      default:
        return 'AUCUNE OPERATION';
    }
  }

  //const surprime = surprime===""? 0: parseFloat(surprime);  variant="danger">Danger
  
  //const accessoire=1500;

 

  //setaccessoire(accessoireManuel!=record.accessoire ? accessoireManuel :record.accessoire);

  const [prime_base, setprime_base] =useState(record.prime_base);
  const [primeHtf, setprimeHtf] =useState(parseFloat(record.prime_ht) + parseFloat(surprime));
  const [tvaF, settvaF]  =useState(19.25/100*(primeHtf+accessoires));
  const [primeTtcf, setprimeTtcf] =useState(primeHtf+tvaF+accessoires);
  const [quote_part_partenaire, setquote_part_partenaire] =useState(parseFloat(primeHtf*15/100).toFixed(2));
  const [quote_part_prubli, setquote_part_prubli] =useState(primeHtf-quote_part_partenaire);
  const [taux, setTaux] =useState(parseFloat(record.prime_base)/parseFloat(record.capital)*100);

  useEffect(()=>{
    setprimeHtf(parseFloat(record.prime_ht)+parseFloat(surprime));
    setprimeTtcf(parseFloat(primeHtf)+parseFloat(tvaF)+parseFloat(accessoires));
    setquote_part_partenaire(parseFloat(primeHtf*15/100).toFixed(2))
    setquote_part_prubli(primeHtf-quote_part_partenaire)   

  }, [surprime,accessoireManuel])

  useEffect(()=>{
  
    calculAccessoire();

    console.log('record.duree:'+record.duree);

  }, [accessoireManuel])

  useEffect(()=>{
    
    if(dureeManuel!=0)
    {
      setduree(dureeManuel)  
    }
    else
    {
      setduree(record.duree)
    }  

    CalculPrime();    

  }, [dureeManuel])


  useEffect(()=>{
 
    console.log('accessoires useEffect 1:'+accessoires);    

  }, [accessoires])

  useEffect(()=>{
    
    calculAccessoire()

    console.log('duree Manuelle:'+duree);    

  }, [duree])

  function calculAccessoire()
      {
        if(accessoireManuel!=0)
        {
          setaccessoires(accessoireManuel);
        }
        else if (accessoireManuel==0 && duree<=12)
        {
          setaccessoires(0);
        }
        else
        {
          setaccessoires(1500);
        }
      }

  const simul = { prime_base,surprime, primeHtf, tvaF, primeTtcf,ageClient,quote_part_partenaire,quote_part_prubli,accessoires};

  const showModal = () => {
    setVisible(true);
  };
  const { Option } = Select;
  const etatData = new FormData();
  etatData.append("numero_police", record.numero_police);
  etatData.append("code_etat_contrat", etat);
  etatData.append("accessoires", accessoires);
  etatData.append("surprime", surprime);
  etatData.append("prime_ht", primeHtf);
  etatData.append("montant_tva", tvaF);
  etatData.append("prime_ttc", primeTtcf);

  const sendEtat = () => {
    Axios({
      method: "post",
      url: "/prubli_ma/ss/validation.php",
      data: etatData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
      })
      .catch(function (response) {
        //handle error
      });
  };  

  const handleOk = () => {
    setConfirmLoading(true);
    sendEtat();    
    setTimeout(() => {
      //this.chargerData();
      listContrat();
      listAgence();
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setVisible(false);
  }; 

  const handleDateNaissanceNew= (dateNaissanceNew) =>{
 
     setAgeClient(GetAge(dateNaissanceNew));

     CalculPrime();

 }

 

 // Actualisons les montants au changements des parametres
 const CalculPrime= () => { 
  
  const paramsPrime = new FormData();
  paramsPrime.append("nbre_mois",duree);
  paramsPrime.append("type_operation", record.type_operation);
  paramsPrime.append("capital",  record.capital);
  paramsPrime.append("age_client", simul.ageClient);
  paramsPrime.append("accessoire", accessoires);
  paramsPrime.append("surprime", simul.surprime);
  paramsPrime.append("type_compte", record.type_compte);  

    Axios({
      method: "post",
      url: "/prubli_ma/ss/calcul_prime.php",
      data: paramsPrime,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log('Result Cacul Prime : '+ JSON.stringify(response));

        setprime_base(response.data.prime_base);

        calculAccessoire();

        setprimeHtf(response.data.prime_ht);

        setprimeTtcf(response.data.prime_ttc);

        setquote_part_partenaire(response.data.qp_nfc) ;

        setquote_part_prubli(response.data.qp_prubli);

        setTaux(response.data.taux);

        settvaF(response.data.montant_tva);

      })
      .catch(function (response) {
        //handle error
      });

   
}
  return (
    <>
      <Button
        style={{
          backgroundColor: "orange",
          color: "white",
        }}
        shape="circle"
        size="small"
        onClick={showModal}
      >
        {" "}
      V
      </Button>
      <Modal
        width={800}
        title="Mise à Jours des Contrats"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}             
      >
        <div className="d-flex">
          <div className="col-6">
            {" "}
            <p>Assuré: {record.nom_client+' '+record.prenoms_client} </p>
            <p>Opération:{typeOperationSwitch(record.type_operation)} -- Durée:{duree} Mois -- Taux:{taux}</p> 

            {userInfo.code_type_utilisateur === "6" && (
              <Form.Item name={["user", "dateNaissanceNew"]} label="Date de Naissance">
                <DatePicker
                    style={{ width: "100%" }}
                    className=""
                    value={dateNaissanceNew} 
                    selected={setdateNaissanceNew}
                    onChange={handleDateNaissanceNew}  
                    format={dateFormatList}
                  />               
            </Form.Item>
            )}

            {userInfo.code_type_utilisateur === "6" && (
              <Form.Item name={["user", "dureeManuel"]} label="Durée(Mois)">
                <Input
                  value={accessoireManuel}
                  onChange={(e) => setdureeManuel(e.target.value)}
                  type="number"                  
                />
            </Form.Item>
            )}

            {userInfo.code_type_utilisateur === "6" && (
              <Form.Item name={["user", "accessoireManuel"]} label="accessoire">
                <Input
                  value={accessoireManuel}
                  onChange={(e) => setaccessoireManuel(e.target.value)}
                  type="number"                  
                />
            </Form.Item>
            )}

            {userInfo.code_type_utilisateur === "6" && (
              <Form.Item name={["user", "surprime"]} label="Surprimes">
                <Input
                  value={surprime}
                  onChange={(e) => setsurprime(e.target.value)}
                  type="number"                  
                />
            </Form.Item>
            )}
            
            <Form.Item name={["user", "statut"]} label="Statut">
              <Select
                defaultValue={record.libelle_etat_contrat}
                style={{ width: 200 }}
                onChange={(value) => setetat(value)}
              >
                <Option value={-2}>Annulé</Option>
                <Option value={-1}>Rejeté</Option>
                <Option value={0}>En attente</Option>
                <Option value={1}>Validé</Option>                
                <Option value={2}>En attente PruBli</Option>                
                <Option value={3}>En Cours</Option>
                <Option value={4}>Avis Réassureur</Option>
                <Option value={5}>Analyse Medicale</Option>
                <Option value={6}>Payé</Option>
              </Select>
            </Form.Item>

            {['6'].includes(userInfo.code_type_utilisateur) &&          // seul le 6	Gestionnaire PruBli peut mettre les primes à jour
            <Button type="danger"
              onClick={CalculPrime}
             /* disabled={simulerDisable}*/
            >
              Mettre La prime à Jour
            </Button>}
          </div>
          <div className="col-6">
            <SimulateurContrat simul={simul} record={record} CalculPrime={CalculPrime}/>
          </div>
        </div>
      </Modal>

    </>
  );
};

// Mes Fonctions Js

export function GetAge(dateString) { 
  
  var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

  return age; 
}





/*

  0	Super Admin /  1	Admin/  2	Chef D Agence/  3	Utulisateurs/  4	Autres Personnel/  5	Gestionnaire Credit /  6	Gestionnaire PruBli

  0	Super Admin
  1	Admin
  2	Chef D Agence
  3	Utulisateurs
  4	Autres Personnel
  5	Gestionnaire Credit
  6	Gestionnaire PruBli

*/


