import React, { useState } from "react";
import {Modal, Form,Input,Button, Row, Col, Radio,DatePicker, Card, notification, Select,} from "antd";
import { useSelector } from "react-redux";

export default function Promoteur(props) {

  const accountList = useSelector((state) => state.accountList);

  const { comptes, error } = accountList;

  const {  identifiantPro } = props.StatePro;
  const {  setidentifiantPro, setdateNaisPro } = props.funcPro;
    
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

  return (
    <>
      <Button className="mt-3" type="primary" onClick={showModal}>
        Promoteur
      </Button>
      <Modal
        // width={1000}
        title="Information sur le Promoteur"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {error ? (
          <h1>{error} </h1>
        ) : (
          <>
            {" "}
            <div className="d-flex">
              <div className="col-6">
                <Form.Item label="Nom ">
                  <strong>{comptes[0].NOM_PROMOTEUR}</strong>
                </Form.Item>
                <Form.Item label="Prénom ">
                  <strong>{comptes[0].PRENOM_PROMOTEUR}</strong>
                </Form.Item>
                <Form.Item label="N° CNI ">
                  <strong>{comptes[0].CNI_PROMOTEUR}</strong>
                </Form.Item>
                <Form.Item label="Identifiant">
                  <strong>{comptes[0].IDENDIFIANT_UNIQUE_PROMOTEUR}</strong>
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item label="Téléphone">
                  <strong>{comptes[0].TELEPHONE_PROMOTEUR}</strong>
                </Form.Item>
                <Form.Item label="N° identifiant unique">
                  <Input
                    value={identifiantPro}
                    type="text"
                    onChange={(e) => setidentifiantPro(e.target.value)}
                    placeholder="N° identifiant unique"
                  />
                </Form.Item>
                <Form.Item label="Date de naissance" name="Date de naissance">
                  <DatePicker                    
                    style={{ width: "100%" }}
                    className=""
                    id="date"
                    onChange={(date, dateString) => {props.setdateNaisPro(dateString);console.log("bychrisme look dqteString : ", dateString)}}
                    format={dateFormatList}
                    required
                  />
                </Form.Item>{" "}
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
}
