import {
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNOUT_FAIL,
  USER_SIGNOUT_SUCCESS,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
  USER_LOAD_FAIL,
  CLEAR_ERRORS,
  CLEAR_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_CHANGE_REQUEST,
  USER_CHANGE_SUCCESS,
  USER_CHANGE_FAIL, 
  USER_RESET_SUCCESS,
} from "../constants/userConstants";



export const usersListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true, users: [] };
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload };
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const userSigninReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_SIGNIN_REQUEST:
    case USER_LOAD_REQUEST:
      return {
        loading: true,
        isAuth: false,
      };
    case USER_SIGNIN_SUCCESS:
    case USER_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuth: true,
        messageA: true,
        userInfo: action.payload,
      };
    case USER_SIGNOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuth: false,
        userInfo: null,
      };
    case USER_SIGNOUT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case USER_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        isAuth: false,
        userInfo: null,
        error: action.payload,
      };

    case USER_SIGNIN_FAIL:
      return {
        ...state,
        loading: false,
        isAuth: false,
        userInfo: null,
        error: action.payload,
      };
    case CLEAR_SUCCESS:
      return {
        ...state,
        messageA: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload, messageR: true };
    case USER_RESET_SUCCESS:
        return { loading: false, userInfo: action.payload, messageR: true };
    case CLEAR_SUCCESS:
      return {
        ...state,
        messageR: false,
      };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
