import React, { useEffect } from "react";
import { loadUser } from "./redux/actions/userActions";
import store from "./redux/store";
import NextApp from "./NextApp";

export default function App() {

  const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };


  
  var token = getCookie("token");
  const tokenData = new FormData();
  tokenData.append("token", token); 

  useEffect(() => {
    store.dispatch(loadUser(tokenData));
  }, [tokenData]);
  return (
    <>
     <NextApp/>
    </>
  );
}
