import React, { Component } from "react";
import "../assets/css/style.css";
import pbf from "../assets/beneficial 1.png";
import nfc from "../assets/nfc1.png";
import test from "../assets/test.png";
import { connect } from "react-redux";

const xaf = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "XAF",
  minimumFractionDigits: 0,
});

export default class FunerairePrint extends Component {
  render() {
    const { printData } = this.props;
    console.log("print data == ", printData);

    const {
      nom_client,
      prenoms_client,
      numero_compte,
      email,
      cni,
      telephone,
      adresse_postale,
      profession,
      ville,
      numeroUnique,
      nom_prenoms_assure,
      DateNai,
      code_agence,
      libelle_agence,
      nom_agent,
      dateeffet,
      pourcentage1,
      relation1,
      benef1,
      addressbenef1,
      pourcentage2,
      relation2,
      benef2,
      addressbenef2,
      pourcentage3,
      relation3,
      benef3,
      addressbenef3,
      option,
      printSee,
      nomApporteur,
      numero_police,
      numAdhesion,
      souscripteurName,
      socialReason,
      subscriberPhone,
      subscriberPostal,
      subscriberCity,
      employerShare,
      employeeShare,
      grossSalary,
      monthlyContribution,
      authorizedCollection,
      anciennete,
      matricule,
      numeroPolice,
      insuredCapital,
      insuredFunction,
      dateBirth,
      activity,

    } = printData;

    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    return (
      <>
        <div
          className="container"
          style={{
            backgroundImage: !printSee ? `url(${test})` : null,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <header>
            <div class="row">
              <div class="col-3" id="logo_nfcBank">
                <img src={nfc} alt="NFC bank" />
              </div>
              <div class="col-6 text-center text-primary">
                <h1>
                  <strong>
                    NFC BANK
                    <br />
                    Assurance Frais Funéraires Entreprises & Associations"{" "}
                  </strong>
                </h1>
                <strong>
                  Formulaire d’adhésion tenant lieu d’Attestation d’Assurance
                  <br />
                  <span class="italic_title">
                    Individual subscription form, serves as Insurance
                    Attestation
                  </span>
                </strong>
              </div>
              <div class="col-3" id="logo_beneficial">
                <img src={pbf} alt="beneficial" />
                <span>
                  <span class="text-primary">N°: </span>{" "}
                  {/* <strong class="text-danger">00000051</strong>{" "} */}
                  <strong class="text-danger">{numero_police}</strong>{" "}
                </span>
              </div>
            </div>
          </header>
          <main>
            <div class="boder">
              <div class="row">
                <div class="col-12">
                  <strong>
                    CADRE RESERVE A NFC BANK/{" "}
                    <span class="italic_main">RESERVED FOR NFC BANK</span>
                  </strong>
                </div>
                <div class="col-4">
                  Chargé de clientèle:<strong>{nom_agent}</strong>{" "}
                </div>
                <div class="col-4">
                  Agence: <strong>{libelle_agence}</strong>{" "}
                </div>
                <div class="col-4">
                  Code:<strong>{code_agence}</strong>{" "}
                </div>
                <div class="col-4 italic">Account Manager</div>
                <div class="col-4 italic">Branch</div>
                <div class="col-4 italic">Code</div>
              </div>
            </div>

            <div class="boder">
              <div class="row">
                <div class="col-12 underline">
                  <strong>
                    CONTRACTANT/ <span class="italic_main">SURSCRIBER</span>
                  </strong>
                </div>
                <div class="col-4">
                  Police N°:<span class="italic"></span>{" "}
                  <strong>{numeroPolice}</strong>
                </div>
                <div class="col-4">
                  Adhésion N°/<span class="italic">Membership No.:</span>{" "}
                  <strong>{numAdhesion}</strong>
                </div>
                <div class="col-4">
                  Nom & Prénom/<span class="italic">Given Names:</span>{" "}
                  <strong>{nom_client}</strong>
                </div>

                <div class="col-4">
                  Raison sociale/<span class="italic">Social reason:</span> <strong>{socialReason}</strong>
                  <strong>{DateNai} </strong>
                </div>

                <div class="col-4">
                  Cellulaire: <strong>{subscriberPhone} </strong>
                </div>

                <div class="col-4">
                  Capital à Assurer: <strong>{insuredCapital} </strong>
                </div>
                <div class="col-4">
                  Ville: <strong>{subscriberCity} </strong>
                </div>

                {/* 
                <div class="col-2">N° de Compte:</div>
                <div class="col-2">
                  <strong>{numero_compte} </strong>
                </div> */}
                {/* <div class="col-2"></div>
                <div class="col-2"></div>
                <div class="col-4"></div>
                <div class="col-2 italic">Account Number</div>
                <div class="col-2">
                  <span class="italic"></span>
                </div>
                <div class="col-2">
                  <span class="italic"></span>
                </div>
                <div class="col-2">
                  <span class="italic"></span>
                </div>
                <div class="col-4 italic"></div> */}

                {/* <div class="col-4 italic">Postal Address</div>
                <div class="col-4 italic">Town</div> */}
                {/* <div class="col-4 italic">Cellular</div> */}

                <div class="col-4">
                  Activité: <strong>{activity} </strong>
                </div>

                <div class="col-4">
                  Apporteur: <strong>{nomApporteur} </strong>
                </div>
                {/* <div class="col-4">Téléphone domicile: 1515145</div>
                <div class="col-8 italic"></div>
                <div class="col-4 italic">Home phone</div> */}

                {/* <div class="col-4">Employeur:rgerger</div>
                <div class="col-8">Tél:545644</div>
                <div class="col-4 italic">Employer</div>
                <div class="col-4 italic">Phone</div> */}
                {/* <div class="col-12">
                  <strong>
                    N° CONTRIBUABLE/{" "}
                    <span class="italic_main">Tax payer number: </span>
                  </strong>{" "}
                  thtthtrhrthtrhtr
                </div> */}
              </div>
            </div>

            <div class="boder">
              <div class="row">
                <div class="col-12 underline">
                  <strong>
                    ASSURÉ/ <span class="italic_main">INSURED</span>
                  </strong>
                </div>
                {/* <div class="col-2">
                  Mme/<span class="italic">Mrs</span>{" "}
               
                </div> */}
                <div class="col-4">
                  Nom/<span class="italic">Surname:</span>{" "}
                  <strong>{nom_prenoms_assure} </strong>
                </div>
                <div class="col-4">
                  Prénoms/<span class="italic">Given Names:</span>
                  <strong>{prenoms_client} </strong>
                </div>
                {/* 
                <div class="col-2">
                  Mlle/<span class="italic">Miss</span>
                </div> */}
                <div class="col-4">
                  Date et lieu de naissance/<span class="italic">Date:</span>{" "}
                  <strong>{dateBirth} </strong>
                </div>

                {/* <div class="col-12">
                  M./<span class="italic">Mr</span>
                </div> */}

                <div class="col-4">
                  N° de Compte:
                  <strong>{numero_compte} </strong>
                </div>
                {/* <div class="col-2"></div>
                <div class="col-2"></div>
                <div class="col-4"></div>
                <div class="col-2 italic">Account Number</div>
                <div class="col-2">
                  <span class="italic"></span>
                </div>
                <div class="col-2">
                  <span class="italic"></span>
                </div>
                <div class="col-2">
                  <span class="italic"></span>
                </div>
                <div class="col-4 italic"></div> */}

                {/* <span class="col-4">
                  CNI N°/<span class="italic">ID: </span>{" "}
                  <strong>{cni} </strong>
                </span> */}

                {/* <div class="col-4">Situation matrimoniale: </div>
                <div class="col-2">
                  Célibataire <input type="checkbox" checked="1" />
                </div>
                <div class="col-2">
                  Marié(e) <input type="checkbox" />
                </div>
                <div class="col-2">
                  Divorcé(e) <input type="checkbox" />
                </div>
                <div class="col-2">
                  Veuf(ve) <input type="checkbox" />
                </div>
                <div class="col-4 italic">Marital Status</div>
                <div class="col-2 italic">Single</div>
                <div class="col-2 italic">Married</div>
                <div class="col-2 italic">Divorced</div>
                <div class="col-2 italic">Window(er)</div> */}

                {/* <div class="col-4">
                  Adresse postale: <strong>{adresse_postale} </strong>
                </div> */}
                <div class="col-4">
                  Ville: <strong>{ville} </strong>
                </div>
                <div class="col-4">
                  Cellulaire: <strong>{telephone} </strong>
                </div>
                {/* <div class="col-4 italic">Postal Address</div>
                <div class="col-4 italic">Town</div>
                <div class="col-4 italic">Cellular</div> */}

                <div class="col-4">
                  Fonction: <strong>{insuredFunction} </strong>
                </div>
                {/* <div class="col-4">
                  Email: <strong>{email} </strong>
                </div> */}
                <div class="col-4">
                  {/* Ancienneté: <strong>{anciennete} </strong> */}
                </div>
                <div class="col-4">
                  {/* Matricule: <strong>{matricule} </strong> */}
                </div>
                {/* <div class="col-4">Téléphone domicile: 1515145</div>
                <div class="col-8 italic"></div>
                <div class="col-4 italic">Home phone</div> */}

                {/* <div class="col-4">Employeur:rgerger</div>
                <div class="col-8">Tél:545644</div>
                <div class="col-4 italic">Employer</div>
                <div class="col-4 italic">Phone</div> */}
                {/* <div class="col-12">
                  <strong>
                    N° CONTRIBUABLE/{" "}
                    <span class="italic_main">Tax payer number: </span>
                  </strong>{" "}
                  thtthtrhrthtrhtr
                </div> */}
              </div>
            </div>

            <div class="boder">
              <div class="row">
                <div class="col-12 text-center">
                  <strong>
                    Bénéficiaire(s) en cas de décés ou d’invalidité /
                    <span class="italic_main">
                      {" "}
                      Beneficiaries in event of Death or Invalidity.
                    </span>
                  </strong>
                </div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">
                        Nom et Prénoms /{" "}
                        <span class="italic_main">Name and Given names</span>
                      </th>
                      <th scope="col">
                        % de réparation /{" "}
                        <span class="italic_main">Portion in %</span>
                      </th>
                      <th scope="col">
                        Date de Naissance /{" "}
                        <span class="italic_main">Date of birth</span>
                      </th>
                      <th scope="col">
                        Adresse /{" "}
                        <span class="italic_main">Address</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <th>{benef1}</th>
                      <th>{pourcentage1}</th>
                      <th>{relation1}</th>
                      <th>{addressbenef1}</th>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <th>{benef2}</th>
                      <th>{pourcentage2}</th>
                      <th>{relation2}</th>
                      <th>{addressbenef2}</th>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <th>{benef3}</th>
                      <th>{pourcentage3}</th>
                      <th>{relation3}</th>
                      <th>{addressbenef3}</th>
                    </tr>
                  </tbody>
                </table>
              </div>

              <strong>
                En l’absence de désignation du bénéficiaire(s), la somme due par
                PRUDENTIAL BENEFICIAL LIFE INSURANCE fait partie de la
                succession de l’assuré conformément à l’article 70 du code CIMA.
              </strong>
            </div>

            {/* <div class="boder">
              <div class="row">
                <div class="col-12 text-center">
                  <strong>
                    EFFET - GARANTIE - PRIMES /
                    <span class="italic_main">
                      {" "}
                      EFFECTIVE DATE - COVERAGE - PREMIUMS
                    </span>
                  </strong>
                </div>
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <th scope="row">
                        <strong>
                          Date d’effet /{" "}
                          <span class="italic_main">Effective date :</span>{" "}
                        </strong>
                      </th>
                      <th>
                        {" "}
                        Le/ <span class="italic_main">On:</span> {dateeffet}
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">
                        J’autorise mon employeur à prélever sur mon salaire
                        mensuel, ma quote-part de:
                      </th>
                      <th>
                        {authorizedCollection === true ? " Oui" : " Non"}
                      </th>
                    </tr>
                    <tr>
                      <th scope="row">
                        <strong>La cotisation mensuelle est fixée à : </strong>
                      </th>
                      <th>
                        {" "+monthlyContribution}
                       
                      </th>
                    </tr>

                    <tr>
                      <th scope="row">
                        <strong>
                          Cote Part employeur: {""}
                          
                        </strong>
                      </th>
                      <th>
                        {" "+employerShare}
                       
                      </th>
                    </tr>

                    <tr>
                      <th scope="row">
                        <strong>
                          Cote Part employé:{" "}
                          
                        </strong>
                      </th>
                      <th>
                      
                      </th>
                    </tr>

                    <tr>
                      <th scope="row">
                        <strong>
                          salaire brut:{" "}
                         
                        </strong>
                      </th>
                      <th>
                        {" "+grossSalary}
                       
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}

            {/* <div class="boder">
              <div class="row">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" rowspan="6" class="text-center">
                        GARANTIES/<span class="italic_main">GUARANTIEE </span>
                      </th>
                      <th scope="col" colspan="6" class="text-center">
                        CAPITAUX / <span class="italic_main">CAPITAL</span>
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" class="text-center">
                        OPTION I
                      </th>
                      <th scope="col" class="text-center">
                        OPTION II
                      </th>
                      <th scope="col" class="text-center">
                        OPTION III
                      </th>
                      <th scope="col" class="text-center">
                        OPTION IV
                      </th>
                      <th scope="col" class="text-center">
                        OPTION V
                      </th>
                      <th scope="col" class="text-center">
                        OPTION VI
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        Capital a verser sur le compte du client 4{" "}
                        <strong>NFC BANK</strong> /
                        <span class="italic_main">
                          Benefit Transferred to the client insured account at{" "}
                          <strong>NFC BANK</strong>
                        </span>
                      </th>
                      <th>250 000</th>
                      <th>500 000</th>
                      <th>750 000</th>
                      <th>1 000 000</th>
                      <th>2 500 000</th>
                      <th>5 000 000</th>
                    </tr>
                    <tr>
                      <th scope="row">
                        Capital a verser au(x) bénéficiaire(s) désigné(s)/
                        <span class="italic_main">
                          {" "}
                          Capital paid to beneficiary(ies)
                        </span>
                        <br />
                        N° de téléphone/
                        <span class="italic_main"> telephone number</span>
                      </th>
                      <th>250 000</th>
                      <th>500 000</th>
                      <th>750 000</th>
                      <th>1 000 000</th>
                      <th>2 500 000</th>
                      <th>5 000 000</th>
                    </tr>
                    <tr>
                      <th scope="row">PRIME ANNUELLE TTC (TVA 19,25%)</th>
                      <th>5 963</th>
                      <th>11 925</th>
                      <th>17 888</th>
                      <th>23 850</th>
                      <th>59 625</th>
                      <th>119 250</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> */}

            {/* <div class="row">
              <div class="col-10">
                Cocher l’option choisie /{" "}
                <span class="italic">Check the option Chosen :</span>{" "}
              </div>{" "}
              <strong class="col-2">{option}</strong>
            </div> */}

            <div class="boder">
              <div class="row">
                <div class="col-12">
                  <strong>
                    NB: Conformément aux dispositions des articles 13-1 et 73 du
                    code CIMA, la prise d’effet des garanties du présent contrat
                    est subordonnée au paiement de la prime ou de la premiére
                    fraction de prime.
                  </strong>
                  <br />
                  <span class="italic">
                    In conformity with the dispositions of articles 13-1 and 73
                    of the CIMA code, the guarantiee of the present contract is
                    effective at the payment of the premium or first part of it.
                  </span>
                </div>
                <div class="col-12">
                  <ul>
                    <li class="italic">
                      Par le présent formulaire, j’autorise{" "}
                      <strong>NFC BANK</strong> à débiter de mon compte, le
                      montant de la prime ci-dessus indiqué au profil de{" "}
                      <strong>PRUDENTIAL BENEFICIAL LIFE INSURANCE</strong>
                    </li>
                    <li class="italic">
                      I hereby authorize <strong>NFC BANK</strong> to pay from
                      my account the above premium to{" "}
                      <strong>PRUDENTIAL BENEFICIAL LIFE INSURANCE</strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </main>
          <footer>
            <div class="row">
              <div class="col-6"></div>
              <div class="col-3">
                Fait à/<span class="italic_main">Done at: </span>
              </div>
              <div class="col-3">
                le/<span class="italic_main">on: </span> <strong>{date}</strong>
              </div>

              <div class="col-6 underline">
                Signature de l’Adhérent /{" "}
                <span class="italic_main">Signature of Subscriber</span>
              </div>
              <div class="col-6 underline">
                Signature de <strong>NFC BANK</strong>
              </div>
            </div>
          </footer>
        </div>
      </>
    );
  }
}
