import React, { useState, useEffect} from "react";
import "./assets/css/style.css";
import {Form, Input, Button, Row, Col,DatePicker, Card,notification,} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { listAccount } from "./redux/actions/accountActions";
import Axios from "axios"
import PrintSc from "./print/PrintSc"
import RegisterBeneficiaires from "./BeneficiairesDeces" 

// 0302820102345739   EASY LIFE / EASY LIFE FLEX / EASY LIFE PLUS

export default function SafeCard() {

  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

  const accountList = useSelector((state) => state.accountList);

  const { comptes, error } = accountList;
  const { Search } = Input;

  const nom_client = comptes.length !== 0 ? comptes[0].PRENOM : "";

  const prenoms_client = comptes.length !== 0 ? comptes[0].LAST_NAME : "";

  const numero_compte = comptes.length !== 0 ? comptes[0].COMPTE : "";

  const email = comptes.length !== 0 ? comptes[0].EMAIL : "";

  const cni = comptes.length !== 0 ? comptes[0].CARTE_ID : "";

  const telephone = comptes.length !== 0 ? comptes[0].MOBILE : "";

  const adresse_postale = comptes.length !== 0 ? comptes[0].ADRESSE_POSTAL : "";
  const profession = comptes.length !== 0 ? comptes[0].PROFESSION : "";

  const ville = comptes.length !== 0 ? comptes[0].VILLE : "";
  const DateNai = comptes.length !== 0 ? comptes[0].DATE_NAISSANCE : "";

  const code_agence = userInfo ? userInfo[0].code_agence : "";
  const code_banque = userInfo ? userInfo[0].code_banque : "";
  const libelle_agence = userInfo ? userInfo[0].libelle_agence : "";
  const nom_agent = userInfo ? userInfo.nom : "";

  const [dateeffet, setdateeffet] = useState("");

  const [numero_police, setnumero_police] = useState("");
  
  //const [benef1, setbenef1] = useState("")
  //const [pourcentage1, setpourcentage1] = useState("")   
  // const [relation1, setrelation1] = useState("")
  //const [benef2, setbenef2] = useState("");
  //const [pourcentage2, setpourcentage2] = useState("");
  //const [relation2, setrelation2] = useState("");
  //const [benef3, setbenef3] = useState("");
  //const [pourcentage3, setpourcentage3] = useState(""); 
  //const [relation3, setrelation3] = useState("");

  const [option, setoption] = useState("1")
  const [numeroUnique, setnumeroUnique] = useState("")
  const [nomApporteur, setnomApporteur] = useState("")

  const [printSee, setprintSee] = useState(false);
  const [saved, setsaved] = useState(false);

  const option1 = {
    capital_nfc: 250000,
    capital_benef: 250000,
    prime_ht: 5000,
    tva: 962,
    prime_ttc: 5962,
  };

  const option2 = {
    capital_nfc: 500000,
    capital_benef: 500000,
    prime_ht: 10000,
    tva: 1925	,
    prime_ttc: 11925,
  };
  
  const option3 = {
    capital_nfc: 1000000,
    capital_benef: 1000000,
    prime_ht: 30000,
    tva: 5775,
    prime_ttc: 35775,
  };

const options = option === "option1" ? option1 : option === "option2" ? option2 : option3;

useEffect(()=>{
  console.log("nda look variation of taux : ", option)
  //console.log("nda look options: ", options)
}, [option])

  const [listBenef, updatelistBenef] = useState([])

  const formData = {
    options,
    nom_client,
    prenoms_client,
    numero_compte,
    email,
    cni,
    telephone,
    adresse_postale,
    profession,
    ville,
    numeroUnique,
    DateNai,
    code_agence,
    code_banque,
    libelle_agence,
    nom_agent,
    dateeffet,
    option,
    type_operation: "S",
    type_client: "safe_card",
    nomApporteur,
    listBenef,
  };  

  const printData = {
    nom_client,
    prenoms_client,
    numero_compte,
    email,
    cni,
    telephone,
    adresse_postale,
    profession,
    ville,
    numeroUnique,
    DateNai,
    code_agence,
    libelle_agence,
    nom_agent,
    dateeffet,
    option,
    printSee,
    nomApporteur,
    numero_police
  };

  const openNotificationWithIcon = () => {
    notification.success({
      message: "Ajout contrat",
      description: "Contrat ajouter avec success.",
      style: {
        marginTop: 50,
      },
    });
  };

  const openNotificationWithIconError = () => {
    notification.error({
      message: "Echec Ajout contrat",
      description: "Contrat non Enregistré ; Merci de recommencer.",
      style: {
        marginTop: 50,
      },
    });
  };

  const sendForm = () => {
  
    if(formData.option==="option3" && ((parseInt(formData.listBenef.pourcentage1)+parseInt(formData.listBenef.pourcentage2)+parseInt(formData.listBenef.pourcentage3)) != 100) )
    {

      notification.warning({
        message: 'Taux Incorret',
        description:'Merci de Verifier la Liste des Beneficiare',   

      });
    }
    else
    {
      
      Axios({
        method: "post",
        url: "/prubli_ma/ss/save_contrat_sc.php",
        data: formData,
        // headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        //handle success
        openNotificationWithIcon();
        setsaved(true);
        setprintSee(true); 
          
        setnumero_police(response['data'][0].numero_police);

      })
      .catch(function (response) {

        setprintSee(false); 

        setsaved(true);

        openNotificationWithIconError(); 
        
        console.log('safe card save  error:'+response);      

        setTimeout(() => {
          window.location.reload(true);
        }, 5000);


      });
    }
    

  };

  const onSearch = (value) => {    

    const accountData = new FormData();
    accountData.append("numero_compte", value);
    accountData.append("type_client", "safe_card");
    accountData.append("type_recherche", "S");
    dispatch(listAccount(accountData));

    setdateeffet('0');
  };

  return (
    <>
      <h3 className="text-center mb-3">Assurance Cartes et Chèques/ Cards and Cheques Insurance</h3>
      <Col className="mb-3 d-flex" span={24}>
        <div className="col-4">
          <h5>Saisir le numéro du compte</h5>{" "}
        </div>
        <div className="col-5">
          {" "}
          <Search
            placeholder="input search text"
            onSearch={onSearch}
            allowClear
            enterButton
          />
        </div>
      </Col>
      <div class="">
        <Card style={{ minHeight: "60vh" }}>
          {error ? (
            <h1>{error} </h1>
          ) : (
            <>
              {comptes.length !== 0 && (
                <>
                  {" "}
                  <h5 className="text-center mb-3" style={{color: "red"}}>{comptes.message_contrat_sc}</h5>
                  <div class="mb-3">
                    <div class="row">
                      <div class="col-4">
                        Chargé de clientèle:<strong>{nom_agent}</strong>
                      </div>
                      <div class="col-4">
                        Agence: <strong>{libelle_agence}</strong>
                      </div>
                      <div class="col-4">
                        Code:<strong>{code_agence}</strong>{" "}
                      </div>
                      <div class="col-4 italic">Account Manager</div>
                      <div class="col-4 italic">Branch</div>
                    </div>
                  </div>
                  <Row>
                    <Col className="mr-2" span={6}>
                      <Form.Item label="Nom " name="Nom du client">
                        <strong>{comptes[0].LAST_NAME}</strong>
                      </Form.Item>
                      <Form.Item label="Prenom " name="Prenom du client">
                        <strong>{comptes[0].PRENOM}</strong>
                      </Form.Item>
                      <Form.Item label="Email du client">
                        <strong>{comptes[0].EMAIL}</strong>
                      </Form.Item>
                      <Form.Item label="N° Identification Unique" name="unique">
                        <strong>{comptes[0].CIF}</strong>
                      </Form.Item>
                      <Form.Item
                        label="Date de Naissance"
                        name="Date de Naissance"
                      >
                        <strong>{comptes[0].DATE_NAISSANCE}</strong>
                      </Form.Item>
                    </Col>

                    {/*  */}
                    <Col className="mr-4 ml-5" span={7}>
                      {" "}
                      <Form.Item label="Compte">
                        <strong>{comptes[0].TYPE_COMPTE}</strong>
                      </Form.Item>
                      <Form.Item label="Téléphone">
                        <strong>{comptes[0].MOBILE}</strong>
                      </Form.Item>{" "}
                      <Form.Item label="N° du compte" name="compte">
                        <strong>{comptes[0].COMPTE}</strong>
                      </Form.Item>
                      <Form.Item label="N° CNI" name="cni">
                        <strong> {comptes[0].CARTE_ID}</strong>
                      </Form.Item>
                      <Form.Item label="Profession">
                        <strong>{comptes[0].PROFESSION}</strong>
                      </Form.Item>
                    </Col>
                    <Col className="mr-4 ml-5" span={6}>
                      {" "}
                      <Form.Item label="Ville">
                        <strong>{comptes[0].VILLE}</strong>
                      </Form.Item>
                      <Form.Item label="Adresse postal">
                        <strong>{comptes[0].ADRESSE_POSTAL}</strong>
                      </Form.Item>
                      <Form.Item
                        label="N° Identification Unique"
                        name="Identification Unique"
                      >
                        <Input
                          id="idUnique"
                          value={numeroUnique}
                          type="text"
                          placeholder="N° Identification Unique"
                          onChange={(e) => setnumeroUnique(e.target.value)}
                        />
                        </Form.Item>
                        <Form.Item
                        label="Nom Apporteur"
                        name="Nom Apporteur"
                      >
                        <Input
                          id="nomApporteur"
                          value={nomApporteur}
                          type="text"
                          placeholder="Nom Apporteur"
                          onChange={(e) => setnomApporteur(e.target.value)}
                        />

                      </Form.Item>
                    </Col>
                  </Row>
                  <div class="">
                    <div class="row">
                      <div class="col-12 text-center mb-5">
                        <strong>
                        PLAFONDS D’INDEMNISATION PAR TYPE DE CARTE /
                          <span class="italic_main">
                            {" "}
                            COMPENSATION CEILINGS PER CARD TYPE
                          </span>
                        </strong>
                      </div>
                      <Row>
                        {" "}
                        {/*<Col className="mr-3" span={7}>
                          {" "}
                          <Form.Item
                            label="Nom et Prénoms / Name and Given names"
                            name="Nom et Prénoms"
                            rules={[
                              {
                                required: true,
                                message: " Nom et Prénoms ",
                              },
                            ]}
                          >
                            <Input
                              id="idUnique"
                              type="text"
                              value={benef1}
                              onChange={(e) => setbenef1(e.target.value)}
                              placeholder="Nom et Prénoms / Name and Given names"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col className="mr-3" span={7}>
                          {" "}
                          <Form.Item
                            label=" % de réparation /Portion in %"
                            name="réparation"
                            rules={[
                              {
                                required: true,
                                message: " réparation ",
                              },
                            ]}
                          >
                            <Input
                              id="idUnique"
                              type="number"
                              value={pourcentage1}
                              onChange={(e) => setpourcentage1(e.target.value)}
                              placeholder="% de réparation /Portion in %"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col span={7}>
                          {" "}
                          <Form.Item
                            label=" Filiation /Relationship"
                            name="Filiation /Relationship"
                            rules={[
                              {
                                required: true,
                                message: " Filiation /Relationship ",
                              },
                            ]}
                          >
                            <Input
                              id="idUnique"
                              type="text"
                              value={relation1}
                              onChange={(e) => setrelation1(e.target.value)}
                              placeholder="Filiation /Relationship"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                       
                        <Col className="mr-3" span={7}>
                          {" "}
                          <Form.Item name="Nom et Prénoms">
                            <Input
                              id="idUnique"
                              type="text"
                              value={benef2}
                              onChange={(e) => setbenef2(e.target.value)}
                              placeholder="Nom et Prénoms / Name and Given names"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col className="mr-3" span={7}>
                          {" "}
                          <Form.Item name="réparation">
                            <Input
                              id="idUnique"
                              type="number"
                              value={pourcentage2}
                              onChange={(e) => setpourcentage2(e.target.value)}
                              placeholder="% de réparation /Portion in %"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col span={7}>
                          {" "}
                          <Form.Item name="Filiation /Relationship">
                            <Input
                              id="idUnique"
                              type="text"
                              value={relation2}
                              onChange={(e) => setrelation2(e.target.value)}
                              placeholder="Filiation /Relationship"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                      
                        <Col className="mr-3" span={7}>
                          {" "}
                          <Form.Item name="Nom et Prénoms">
                            <Input
                              id="idUnique"
                              type="text"
                              value={benef3}
                              onChange={(e) => setbenef3(e.target.value)}
                              placeholder="Nom et Prénoms / Name and Given names"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col className="mr-3" span={7}>
                          {" "}
                          <Form.Item name="réparation">
                            <Input
                              id="idUnique"
                              type="number"
                              value={pourcentage3}
                              onChange={(e) => setpourcentage3(e.target.value)}
                              placeholder="% de réparation /Portion in %"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col span={7}>
                          {" "}
                          <Form.Item name="Filiation /Relationship">
                            <Input
                              id="idUnique"
                              type="text"
                              value={relation3}
                              onChange={(e) => setrelation3(e.target.value)}
                              placeholder="Filiation /Relationship"
                            />
                          </Form.Item>{" "}
                          </Col>{" "} */}
                      </Row> 
                    </div>
                  </div>
                  <div class="">
                    <div class="row">
                      <Form.Item
                        label="Date d'effet de la garantie"
                        name="Date de garantie"
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          className=""
                          id="date"
                          onChange={(date, dateString) =>
                            setdateeffet(dateString)
                          }
                          format={dateFormatList}
                        />
                      </Form.Item>{" "}
                    </div>
                  </div>
                  <div class="">
                    <div class="row">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" rowspan="2" class="text-center">
                              PRIMES/
                              <span class="italic_main">PREMIUMP </span>
                            </th>
                            <th scope="col" colspan="3" class="text-center">
                              PRODUIT /{" "} {" "}
                              <span class="italic_main">PRODUCT</span>
                            </th>
                          </tr>
                          <tr>
                            <th scope="col" class="text-center">
                              EASY LIFE
                            </th>                           
                            <th scope="col" class="text-center">
                              EASY LIFE FLEX
                            </th>
                            <th scope="col" class="text-center">
                              EASY LIFE PLUS
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">
                              Prime annuelle/Annual premium (en franc CFA/In franc CFA){" "}
                              {/* <strong>NFC BANK</strong> /
                              <span class="italic_main">
                                Benefit Transferred to the client insured
                                account at <strong>NFC BANK</strong>
                              </span>  */}
                            </th>
                            <th>5 000</th>
                            <th>10 000</th>
                            <th>30 000</th>
                          </tr>
                          <tr>
                            <th scope="row">
                              TVA/VAT
                              {/*<span class="italic_main">
                                {" "}
                                Capital paid to beneficiary(ies)
                              </span>
                              <br />
                              N° de téléphone/
                              <span class="italic_main"> telephone number</span> */}
                            </th>
                            <th>962</th>
                            <th>1 925</th>
                            <th>5 775</th>
                          </tr>
                          <tr>
                            <th scope="row">Prime annuelle TTC/Annual premium tax inclusive (en franc CFA/in franc CFA)</th>
                            <th>5 962</th>
                            <th>11 925</th>
                            <th>35 775</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-7">
                      Cocher l’option choisie /{" "}
                      <span class="italic">Check the option Chosen :</span>{" "}
                    </div>
                    <div
                      className="col-4 d-flex"
                      onChange={(e) => setoption(e.target.value)}
                    >
                      <div class="col-6">
                        <strong> I</strong>{" "}
                        <input
                          type="radio"
                          id="assurance"
                          name="assurance"
                          value="option1"
                        />
                      </div>
                      <div class="col-6">
                        <strong> II</strong>{" "}
                        <input
                          type="radio"
                          id="assurance"
                          name="assurance"
                          value="option2"    
                        />
                      </div>
                      <div class="col-6">
                        <strong>III</strong>{" "}
                        <input
                          type="radio"
                          id="assurance"
                          name="assurance"
                          value="option3"     
                        />
                      </div>
                    </div>
                  </div>
                  {" "}   
                  <div class="row" style={{marginTop:"30px", marginBotton:"10px"}}>
                  {(option == 'option3' ) && (<RegisterBeneficiaires listBenef={listBenef} updatelistBenefvalue={updatelistBenef}/>)}
                  </div>        
                 

                  <div className="row mt-1">
                    <div className="col-10"></div>
                    <div className="d-flex col-2 justify-content-around">
                      {" "}
                      {(dateeffet.length > 5) && ((nom_client.length+prenoms_client.length) > 5) &&(<Button
                        onClick={sendForm}
                        type="primary"
                        className="login-form-button "
                        disabled={saved}
                      > 
                        Enregistrer
                      </Button>)}
                      <PrintSc printData={printData} />
                      {/* printData */}
                    </div>
                   

                  </div>
                 
                </>
              )}
            </>
          )}
        </Card>
      </div>
    </>
  );
}
