import React, { Component } from "react";
import { connect } from "react-redux"
import { listAgence } from "./redux/actions/agenceActions";
import { Table, Input, Button, Space } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import CreerAgence from "./CreerAgence";


 class AgenceList extends Component {
   state = {
     searchText: "",
     searchedColumn: "",
   };

   getColumnSearchProps = (dataIndex) => ({
     filterDropdown: ({
       setSelectedKeys,
       selectedKeys,
       confirm,
       clearFilters,
     }) => (
       <div style={{ padding: 8 }}>
         <Input
           ref={(node) => {
             this.searchInput = node;
           }}
           placeholder={`Search ${dataIndex}`}
           value={selectedKeys[0]}
           onChange={(e) =>
             setSelectedKeys(e.target.value ? [e.target.value] : [])
           }
           onPressEnter={() =>
             this.handleSearch(selectedKeys, confirm, dataIndex)
           }
           style={{ width: 188, marginBottom: 8, display: "block" }}
         />
         <Space>
           <Button
             type="primary"
             onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
             icon={<SearchOutlined />}
             size="small"
             style={{ width: 90 }}
           >
             Search
           </Button>
           <Button
             onClick={() => this.handleReset(clearFilters)}
             size="small"
             style={{ width: 90 }}
           >
             Reset
           </Button>
           <Button
             type="link"
             size="small"
             onClick={() => {
               confirm({ closeDropdown: false });
               this.setState({
                 searchText: selectedKeys[0],
                 searchedColumn: dataIndex,
               });
             }}
           >
             Filter
           </Button>
         </Space>
       </div>
     ),
     filterIcon: (filtered) => (
       <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
     ),
     onFilter: (value, record) =>
       record[dataIndex]
         ? record[dataIndex]
             .toString()
             .toLowerCase()
             .includes(value.toLowerCase())
         : "",
     onFilterDropdownVisibleChange: (visible) => {
       if (visible) {
         setTimeout(() => this.searchInput.select(), 100);
       }
     },
     render: (text) =>
       this.state.searchedColumn === dataIndex ? (
         <Highlighter
           highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
           searchWords={[this.state.searchText]}
           autoEscape
           textToHighlight={text ? text.toString() : ""}
         />
       ) : (
         text
       ),
   });

   handleSearch = (selectedKeys, confirm, dataIndex) => {
     confirm();
     this.setState({
       searchText: selectedKeys[0],
       searchedColumn: dataIndex,
     });
   };

   handleReset = (clearFilters) => {
     clearFilters();
     this.setState({ searchText: "" });
   };
   componentDidMount() {
     this.props.listAgence();
   }

     render() {
           const { agence, loading } = this.props.agenceList;
         const { root_liste_agence } = agence;
         console.log(root_liste_agence);
         const data = root_liste_agence? root_liste_agence:[];
     const columns = [
       {
         title: "Nom agence",
         dataIndex: "libelle_agence",
         key: "libelle_agence",
         width: "30%",
         ...this.getColumnSearchProps("libelle_agence"),
       },
       {
         title: "Code agence",
         dataIndex: "code_agence",
         key: "code_agence",
         width: "20%",
         ...this.getColumnSearchProps("code_agence"),
       },
       {
         title: "Code banque",
         dataIndex: "code_banque",
         key: "code_banque",
         ...this.getColumnSearchProps("code_banque"),
       },
       {
         title: " Seuil capital",
         dataIndex: "seuil_capital",
         key: "seuil_capital",
         ...this.getColumnSearchProps("seuil_capital"),
       },
     ];
         return (
           <>
            
                 <h1 className="my-5"> Liste des agences</h1>
               
             
             <CreerAgence />
             <Table
               rowKey={(record) => record.id}
               columns={columns}
               dataSource={data}
             />
           </>
         );
   }
 }

const mapStateToProps = (state) => {
  return {
    agenceList: state.agenceList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listAgence: () => dispatch(listAgence()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgenceList);

