import React, { useState } from "react";
import { Modal, Button } from "antd";

export default function FormBen(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    props.setformOk(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const { constPB, funcPB } = props;
  //
  //
  const {
    //
    code_agence,
    nom_client,
    prenom_client,
    DateNai,
    ville,
    telephone,
    profession,
    poid,
    taille,
    motifrefus,
    accidentetat,
    sejourmotif,
    tachedesc,
    workstopdesc,
    workstophealthdesc,
    poidsdesc,
    autresdesc,
    smokeqty,
    drugsdesc,
    enceintemois,
    accouchedesc,
    traitmedidesc,
    defaultdesc,
    hospnextdesc,
    mifadesc,
    intervdesc,
    intervention_chiru,
    traitement_evolution,
  } = constPB;
  //
  console.log(constPB);
  //
  const {
    setpoid,
    settaille,

    setcheckup,
    setdepistage,
    setradiographie,
    setelectrocard,
    setsangurine,
    setgrossi,
    setdefaultdesc,
    setrefuscontrat,
    setmotifrefus,
    setaccident,
    setaccidentetat,
    setsportif,
    setsporttype,
    setsejourH,
    setsejourdate,
    setsejourmotif,
    settachedanger,
    settachedesc,
    setworkstop,
    setworkstopdesc,
    setmillitaire,
    setworkstophealth,
    setworkstophealthdesc,
    setpoidsdesc,
    setmaladieresp,
    setmaladiecardio,
    setmaladiediges,
    setmaladieurin,
    setmaladienerv,
    setmaladiepsyc,
    setmaladiesang,
    setmaladieando,
    setmaladios,
    setmaladiepeau,
    setmaladieinfec,
    setmaladiepara,
    setmaladieorga,
    setdefaultconst,
    settumeur,
    setsida,
    setpositive,
    setautres,
    setautresdesc,
    setsmoke,
    setsmokeqty,
    setalcool,
    setdrugs,
    setdrugsdesc,
    setenceinte,
    setenceintemois,
    setregles,
    setdiagnostic,
    setaccouche,
    setaccouchedesc,
    sethospnext,
    sethospnextdesc,
    setintervention,
    setintervdesc,
    settestdiag,
    settraitmedical,
    settraitmedidesc,
    setmifa,
    setmifadesc,
    setintervention_chiru,
    settraitement_evolution,
  } = funcPB;
  //
  //

  return (
    <>
      <Button type="danger" onClick={showModal}>
        QM PBENEFICIAL
      </Button>
      <Modal
        width={1200}
        title="Formulaire médical BENEFICIAL"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ zIndex: 999 }}
      >
        <div class="mt-4 mb-4">
          <div class="container border  m-auto">
            <form class="user mt-4 mb-4">
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  Nom de l'agence: {code_agence}{" "}
                </div>
                <div class="col-sm-4">Date de naissance : {DateNai}</div>
                <div class="col-sm-4 mb-3 mb-sm-0">Adresse :{ville} </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  Nom et Prénom du proposant adhérent : {nom_client} {""}{" "}
                  {prenom_client}
                </div>
                {/* <div class="col-sm-4 mb-3 mb-sm-0">
                  <input
                    type="text"
                    class="form-control form-control-user"
                    placeholder="Lieu de naissance"
                  />
                </div> */}
                <div class="col-sm-4 mb-3 mb-sm-0">
                  Profession:{profession}{" "}
                </div>
              </div>
              <div class="form-group row">
                {/* <div class="col-sm-4 mb-3 mb-sm-0">
                  <input
                    type="number"
                    class="form-control form-control-user"
                    placeholder="N° de police"
                  />
                </div> */}
                <div class="col-sm-4">N° de téléphone:{telephone} </div>
                {/* <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control form-control-user"
                    placeholder=" Nationalité"
                  />
                </div> */}
              </div>
            </form>
          </div>
        </div>
        {/* <!-- deuxième partie --> */}
        <div class="mt-5 mb-4">
          <div class="container border  m-auto">
            <form class="user mt-4 mb-4">
              <div class="form-group row">
                <div
                  class="col-sm-4 mb-3 mb-sm-0"
                  onChange={(e) => setrefuscontrat(e.target.value)}
                >
                  <label for="quest1">
                    1.1) Avez-vous déjà été refusé, ajourné ou surprimé pour un
                    précédent contrat d’assurance de personnes ?{" "}
                    <p style={{ fontSize: "12px" }}>
                      Have you ever been victim of refusal, postponement or
                      cancellation of a previous personal insurance contract?
                    </p>
                  </label>
                  <p>
                    <input value="oui" type="radio" id="quest1" name="quest1" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="quest1"
                      name="quest1"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>
                    1.2)Si OUI, date - motif - nom de la Compagnie <br />
                    <p style={{ fontSize: "12px" }}>
                      If YES, state date, reason, name of the company
                    </p>
                  </label>

                  <p>
                    <input
                      value={motifrefus}
                      onChange={(e) => setmotifrefus(e.target.value)}
                      type="text"
                      class="form-control form-control-user"
                      placeholder="Date/ Motif / Nom de la campagnie"
                    />
                  </p>
                </div>
                <div
                  onChange={(e) => setaccident(e.target.value)}
                  class="col-sm-4 mb-3 mb-sm-0"
                >
                  <label for="quest2">
                    2.1) Avez-vous été victime d'accident (automobile ou autre)
                    ?
                    <p style={{ fontSize: "12px" }}>
                      Have you ever been victim of an accident (motor vehicule
                      or any other) ?
                    </p>
                  </label>
                  <p>
                    <input value="oui" type="radio" id="quest2" name="quest2" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="quest2"
                      name="quest2"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>
                    2.2) Si OUI, localisation des blessures, y a-t-il eu perte
                    de connaissances, durée de la perte de connaissance, date,
                    séquelles
                    <p style={{ fontSize: "12px" }}>
                      If yes, location of injuries, was there any loss of
                      consciousness? duration of loss of consciousness, dates,
                      after effects
                    </p>{" "}
                  </label>
                  <p>
                    <input
                      value={accidentetat}
                      onChange={(e) => setaccidentetat(e.target.value)}
                      type="text"
                      class="form-control form-control-user"
                      placeholder="localisation des blessures Y a-t-il eu perte de connaissanceDurée de la perte de connaissance"
                    />
                  </p>
                </div>
                <div
                  onChange={(e) => setsportif(e.target.value)}
                  class="col-sm-4"
                >
                  <label for="quest3">
                    2.3) Pratiquez-vous des activités sportives ?
                    <p style={{ fontSize: "12px" }}>Do you do sports?</p>
                  </label>
                  <p>
                    <input value="oui" type="radio" id="quest3" name="quest3" />{" "}
                    OUI/YES &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="quest3"
                      name="quest3"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div
                  onChange={(e) => setsporttype(e.target.value)}
                  class="col-sm-4 mb-3 mb-sm-0"
                >
                  <label for="quest4">
                    2.4) Si OUI, préciser en amateur ou en competition{" "}
                    <p style={{ fontSize: "12px" }}>
                      If yes, specify wheter as amateur or competitor
                    </p>
                  </label>
                  <p>
                    <input
                      type="radio"
                      class="from-check-label"
                      id="quest4"
                      name="quest4"
                      value="amateur"
                    />{" "}
                    En amateur &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="compétition"
                      type="radio"
                      id="quest4"
                      name="quest4"
                    />{" "}
                    En compétition
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div
                  onChange={(e) => setsejourH(e.target.value)}
                  class="col-sm-4"
                >
                  <label for="quest2d6">
                    3.1) Au cours de ces dernières années, avez-vous fait des
                    séjours dans un hôpital, clinique, sanatorium ou une ,aison
                    de repos?
                    <p style={{ fontSize: "12px" }}>
                      Have you, over the last few years been admitted in a
                      health or related establishment?
                    </p>
                  </label>
                  <p>
                    <input
                      type="radio"
                      id="quest2d6"
                      name="quest2d6"
                      value="oui"
                    />{" "}
                    OUI/YES &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      class="from-check-label"
                      id="quest2d6"
                      name="quest2d6"
                      value="non"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest002">3.2) Si OUI/YES</label>

                  <label for="quest002"></label>
                  <p>
                    <input
                      value={sejourmotif}
                      onChange={(e) => setsejourmotif(e.target.value)}
                      type="text"
                      class="form-control"
                      id="quest0003"
                      name="quest0003"
                      placeholder="date/ motifs"
                    />{" "}
                  </p>
                </div>
                {/* <div class="col-sm-4">
                  <p>
                    {" "}
                    3.3)<strong> Résultats:</strong>
                  </p>
                  <p>
                    <input
                      type="text"
                      class="form-control"
                      id="quest0103"
                      name="quest0103"
                    />{" "}
                  </p>
                </div> */}
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <p>
                    {" "}
                    3.3) Avez-vous subi :{" "}
                    <p style={{ fontSize: "12px" }}>Have you undergone:</p>
                  </p>
                  <label>
                    <>
                      examens médicaux?{" "}
                      <p style={{ fontSize: "12px" }}>Medical exams</p>{" "}
                    </>{" "}
                  </label>
                  <p>
                    Sang / urines{" "}
                    <p style={{ fontSize: "12px" }}>Blood,urine</p>{" "}
                    <p onChange={(e) => setsangurine(e.target.value)}>
                      <input
                        type="radio"
                        class="from-check-label"
                        id="quesddt23"
                        name="quesddt23"
                        value="oui"
                      />{" "}
                      OUI/YES &nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        value="non"
                        type="radio"
                        id="quesddt23"
                        name="quesddt23"
                      />
                      NON/NO
                    </p>{" "}
                    Electrocardiogramme{" "}
                    <p style={{ fontSize: "12px" }}>Electrocardiogram</p>
                    <p onChange={(e) => setelectrocard(e.target.value)}>
                      <input
                        type="radio"
                        class="from-check-label"
                        id="questdz23"
                        name="questdz23"
                        value="oui"
                      />{" "}
                      OUI/YES &nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        value="non"
                        type="radio"
                        id="questdz23"
                        name="questdz23"
                      />{" "}
                      NON/NO
                    </p>
                    Radiographies{" "}
                    <p style={{ fontSize: "12px" }}>X-ray photography</p>
                    <p onChange={(e) => setradiographie(e.target.value)}>
                      <input
                        type="radio"
                        class="from-check-label"
                        id="questddza23"
                        name="questddza23"
                        value="oui"
                      />{" "}
                      OUI/YES &nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="questddza23"
                        name="questddza23"
                        value="non"
                      />{" "}
                      NON/NO
                    </p>
                    Test de dépistage (toxoplasmose, hepatite B, SIDA etc...){" "}
                    <p style={{ fontSize: "12px" }}>
                      Toxoplasmosis, hepatitis B, AIDS, etc.
                    </p>{" "}
                    <p onChange={(e) => setradiographie(e.target.value)}>
                      <input
                        type="radio"
                        class="from-check-label"
                        id="quesdzdt23"
                        name="quesdzdt23"
                        value="oui"
                      />{" "}
                      OUI/YES &nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="quesdzdt23"
                        name="quesdzdt23"
                        value="non"
                      />{" "}
                      NON/NO
                    </p>
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <p>
                    {" "}
                    3.4)
                    <>
                      {" "}
                      Un traitement specialisé tel que :
                      <p style={{ fontSize: "12px" }}>
                        A specialized treatment such as
                      </p>
                    </>{" "}
                  </p>
                  <label for="quest22">
                    Rayons x, chimiothérapie immunothérapie ou cobaltothérapie ?
                    <p style={{ fontSize: "12px" }}>
                      radiation sickness, chemotheraphy, immunotheraphy or
                      cobalt theraphy?
                    </p>
                  </label>
                  <p>
                    <input type="radio" id="quest22" name="quest22" /> OUI/YES
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      class="from-check-label"
                      id="quest22"
                      name="quest22"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest24">
                    3.5) + Des transfusions de sang ou de dérivés sanguins{" "}
                    <p style={{ fontSize: "12px" }}>
                      +Transfusion of blood or blood products
                    </p>{" "}
                    :
                  </label>
                  <p>
                    {" "}
                    <input type="radio" id="quest24" name="quest24" /> OUI/YES
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      class="from-check-label"
                      id="quest24"
                      name="quest24"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div
                  onChange={(e) => setcheckup(e.target.value)}
                  class="col-sm-4"
                >
                  <label for="quesdzt27">
                    3.6) Avez-vous subi un check-up, consultation, maladie,
                    blessure, ou une intervention chirurgicale?
                    <p style={{ fontSize: "12px" }}>
                      Have you undergone a check-up, consultation, illness,
                      injury, or operations?
                    </p>{" "}
                    :
                  </label>
                  <p>
                    <input
                      type="radio"
                      id="quesdzt27"
                      name="quesdzt27"
                      value="oui"
                    />{" "}
                    OUI/YES &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="radio"
                      class="from-check-label"
                      id="quesdzt27"
                      name="quesdzt27"
                      value="non"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div
                  onChange={(e) => setelectrocard(e.target.value)}
                  class="col-sm-4"
                >
                  <label for="questrer28">
                    3.7) Avez-vous subi un électrocardiogramme, un traitement de
                    rayons x ?{" "}
                    <p style={{ fontSize: "12px" }}>
                      Have you undergone an Electrocardiogram, an x-ray
                      photocopy treatement ?
                    </p>{" "}
                    :
                  </label>
                  <p>
                    <input
                      value="oui"
                      type="radio"
                      id="questrer28"
                      name="questrer28"
                    />{" "}
                    OUI/YES &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="questrer28"
                      name="questrer28"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <p>
                    <label for="questi01">
                      3.8) Précisez les interventions chirurgicales subies et
                      préciser les résultats{" "}
                      <p style={{ fontSize: "12px" }}>
                        Specify the surgery undergone and specify the results
                      </p>{" "}
                    </label>
                  </p>
                  <p>
                    <input
                      value={intervention_chiru}
                      onChange={(e) => setintervention_chiru(e.target.value)}
                      type="text"
                      class="form-control"
                      id="questi01"
                      name="questi01"
                    />
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* <!-- troisième partie --> */}

        <div class="mt-5 mb-4">
          <div class="container border  m-auto">
            <form class="user mt-4 mb-4">
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="ddzd">
                    4.1) L’exécution de vos tâches quotidiennes vous expose
                    t-elle à des dangers particuliers d’accident ou de maladie ?
                    <p style={{ fontSize: "12px" }}>
                      Does the execution of your daily tasks expose you to
                      specific dangers of accident or illness?
                    </p>
                  </label>
                  <p onChange={(e) => settachedanger(e.target.value)}>
                    <input value="oui" type="radio" id="ddzd" name="ddzd" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="ddzd"
                      name="ddzd"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>
                    4.2) Si OUI, lesquels?{" "}
                    <p style={{ fontSize: "12px" }}>if yes, which</p>{" "}
                  </label>
                  <p>
                    <input
                      value={tachedesc}
                      onChange={(e) => settachedesc(e.target.value)}
                      type="text"
                      class="form-control form-control-user"
                    />
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="questd006">
                    4.3) Etes-vous actuellement en arrêt de travail ?
                    <p style={{ fontSize: "12px" }}>
                      Have you presently stopped working?
                    </p>
                  </label>
                  <p onChange={(e) => setworkstop(e.target.value)}>
                    <input
                      type="radio"
                      id="questd006"
                      name="questd006"
                      value="oui"
                    />{" "}
                    OUI/YES &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="questd006"
                      name="questd006"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest002">
                    4.3.1) Si OUI, depuis quand - motif - date de reprise
                    envisagée
                    <p style={{ fontSize: "12px" }}>
                      Since when, reason, date envisaged for resumption
                    </p>
                  </label>

                  <label for="quest002"></label>
                  <p>
                    <input
                      value={workstopdesc}
                      onChange={(e) => setworkstopdesc(e.target.value)}
                      type="text"
                      class="form-control"
                      id="quest0003"
                      name="quest0003"
                      placeholder="date/ motifs"
                    />{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest0d02">
                    4.4) Avez-vous été exempté du service militaire, reformé
                    pour un motif de santé?
                    <p style={{ fontSize: "12px" }}>
                      Have you been exempted from military service for health
                      reasons?
                    </p>
                  </label>
                  <p onChange={(e) => setmillitaire(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="quest0d02"
                      name="quest0d02"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="quest0d02"
                      name="quest0d02"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quesfet005">
                    4.5) Durant les 5 dernières années, avez-vous dû interrompre
                    votre travail pendant plus de trois semaines pour problème
                    de santé
                    <p style={{ fontSize: "12px" }}>
                      Have you over the last five years ever stopped work for
                      more than tree weeks for health reasons?
                    </p>
                  </label>
                  <p onChange={(e) => setworkstophealth(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="quesfet005"
                      name="quesfet005"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="quesfet005"
                      name="quesfet005"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <label>
                    4.5.1) Si OUI, quand - durée de chaque arrêt -motif{" "}
                    <p style={{ fontSize: "12px" }}>
                      when , duration of each stoppage, reason
                    </p>
                  </label>
                  <p>
                    <input
                      onChange={(e) => setworkstophealthdesc(e.target.value)}
                      value={workstophealthdesc}
                      type="text"
                      class="form-control"
                      id="quest0003"
                      name="quest0003"
                      placeholder="date/ motifs/durée"
                    />{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <p>
                    5.1) Quels sont vos taille et poids ?{" "}
                    <p style={{ fontSize: "12px" }}>
                      What is your height and weight?
                    </p>
                  </p>
                  <p>
                    <input
                      value={poid}
                      onChange={(e) => setpoid(e.target.value)}
                      type="number"
                      class="form-control form-control-user"
                      placeholder="Poids"
                    />
                  </p>
                  <p>
                    <input
                      value={taille}
                      onChange={(e) => settaille(e.target.value)}
                      type="number"
                      class="form-control form-control-user"
                      placeholder="Taille"
                    />
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <p>
                    5.2) Avez-vous grossi ou maigri de plus de 5 kgs ces
                    dernières années ?{" "}
                    <p style={{ fontSize: "12px" }}>
                      Have you ever gained or lost weight by more than 5kgs in
                      six months?
                    </p>
                  </p>
                  <p onChange={(e) => setgrossi(e.target.value)}>
                    <input type="radio" name="queddest003" value="oui" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      name="queddest003"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>
                    5.3) Si OUI, de combien de kgs quand et pourquoi ?{" "}
                    <p style={{ fontSize: "12px" }}>
                      if yes, by how many kgs when and why
                    </p>{" "}
                    <p style={{ fontSize: "12px" }}>If so, how many kgs</p>
                  </label>
                  <p>
                    <input
                      value={poidsdesc}
                      onChange={(e) => setpoidsdesc(e.target.value)}
                      type="text"
                      placeholder="combien de Kg/quand/pourquoi"
                      class="form-control form-control-user"
                    />
                  </p>
                </div>
              </div>
            </form>{" "}
          </div>{" "}
        </div>
        {/* <!-- quartrième partie --> */}

        <div class="mt-5 mb-4">
          <div class="container border  m-auto">
            <form class="user mt-4 mb-4">
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <p>
                    6.1) Souffrez-vous ou avez-vous été atteint de{" "}
                    <p style={{ fontSize: "12px" }}>
                      Are you suffering from or have you ever been affected by
                    </p>{" "}
                  </p>
                  <label for="quest1">
                    Maladies de l’appareil respiratoire (toux de longue durée,
                    crachement de sang, essoufflement, affection des poumons,
                    allergies, bronchite, sinusite, asthme,pneumonie, pleurésie
                    et autres affections pulmonaires ? ..)
                    <p style={{ fontSize: "12px" }}>
                      respiratory infection (protracted cough, spitting of
                      blood, breathlessness, lung ailement, allergies,
                      bronchitis, sinusitis ,pneaumonia and other pulmonary
                      aliments,...)
                    </p>
                  </label>
                  <p onChange={(e) => setmaladieresp(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="quesdzt1"
                      name="quesdzt1"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="quesdzt1"
                      name="quesdzt1"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                {/* <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>
                    6.2) Si OUI/YES Précisez les indices exacts{" "}
                    <p style={{ fontSize: "12px" }}>
                      If yes, specify the exact indices
                    </p>
                    :
                  </label>

                  <p>
                    <input
                      // value={}
                      type="text"
                      class="form-control form-control-user"
                      placeholder="date découverte/Les traitements/évolution"
                    />
                  </p>
                </div> */}
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest5">
                    6.3) Maladies de l'appareil cardio-vasculaire (infarctus,
                    palpitation, hypertension artérielle, crises cardiaques et
                    autres affections cardiovasculaires, ...) ?
                    <p style={{ fontSize: "12px" }}>
                      Cardio-vascular diseases (infarction, high blood pressure,
                      arthritis)?
                    </p>
                  </label>
                  <p onChange={(e) => setmaladiecardio(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="quesddszt1"
                      name="quesddszt1"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="quesddszt1"
                      name="quesddszt1"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest8">
                    6.4) Maladies de l ’appareil digestif (jaunisse, hépatite,
                    maladie du foie, diarrhée chronique, colite, ulcères
                    gastriques ou duodénal hernie, appendicite, ou autres
                    affections de l 'estomac ou intestins ? ...)
                    <p style={{ fontSize: "12px" }}>
                      Digestive tract diseases (jaundice, hepatitis, chronic
                      diarrhea,...)
                    </p>
                  </label>
                  <p onChange={(e) => setmaladiediges(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="quessdddszt1"
                      name="quessdddszt1"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="quessdddszt1"
                      name="quessdddszt1"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest11">
                    6.5) Maladie de l’appareil urinaire et génital (albuminurie
                    et sang dans les urines, syphilis, gonococcie, maladies
                    sexuellement transmissibles, etc...)
                    <p style={{ fontSize: "12px" }}>
                      Urinary and genital organ diseases(albuminuria and blood
                      in urine, sexually transmitted diseases, etc...)
                    </p>
                  </label>
                  <p onChange={(e) => setmaladieurin(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="quessdddddszt1"
                      name="quessdddddszt1"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="quessdddddszt1"
                      name="quessdddddszt1"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest14">
                    6.6) Maladies du système nerveux
                    (Névntesciatique,paralysies,méningite, congestion cérébrale,
                    vertige épilepsie, maux de tête prolongés, maladie des
                    muscles?...)
                    <p style={{ fontSize: "12px" }}>
                      Nervous system diseases (paralysis, meningitis, epilepsy,
                      etc...)
                    </p>
                  </label>
                  <p onChange={(e) => setmaladienerv(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="qudszt1"
                      name="qudszt1"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="qudszt1"
                      name="qudszt1"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest17">
                    6.7) Maladies neuro-psychiques (dépression nerveuse,
                    tentative de suicide, fatigue prolongée, perte de mémoire,
                    épilepsie, affection mentale, perte de connaissance, ...)
                    <p style={{ fontSize: "12px" }}>
                      Neuro-psychic diseases (nervous depression, suicide
                      attempt, prolonged fatigue, etc...)
                    </p>
                  </label>
                  <p onChange={(e) => setmaladiepsyc(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="qudszdt1"
                      name="qudszdt1"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="qudszdt1"
                      name="qudszdt1"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest2">
                    6.8) Maladies du sang de ganglions et de la terre (anémie,
                    présence de ganglions anormaux, Hémoglobinopathies et crises
                    hémolytiques, "sickle cell" etc . ..)?
                    <p style={{ fontSize: "12px" }}>
                      Blood glands and heart diseases (anemia, presence of
                      abnormal glands, hemoglobinopathy and hemolytic, etc...)
                    </p>
                  </label>
                  <p onChange={(e) => setmaladiesang(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="qudszddt1"
                      name="qudszddt1"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="qudszddt1"
                      name="qudszddt1"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest4">
                    6.9) Maladies endocriniennes ou métaboliques ( diabète,
                    goutte, anomalies de la thyroïde, . )
                    <p style={{ fontSize: "12px" }}>
                      Endocrine or metabolic diseases (diabetes, gout, thyroid
                      abnormalities, etc...)
                    </p>
                  </label>
                  <p onChange={(e) => setmaladieando(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="qudsdzddt1"
                      name="qudsdzddt1"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="qudsdzddt1"
                      name="qudsdzddt1"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <label for="quest6">
                    6.10) Maladies des os et des articulations(érthrose,
                    arthrite, rhumatismes divers)?
                    <p style={{ fontSize: "12px" }}>
                      Bone and joint diseases (osteoarthritis, various
                      rheumatism, arthritis, etc...)
                    </p>
                  </label>
                  <p onChange={(e) => setmaladios(e.target.value)}>
                    <input value="oui" type="radio" id="qddt1" name="qddt1" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="qddt1"
                      name="qddt1"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quedst9">
                    6.11) Maladies de la peau (ablation de grain de beauté,
                    verrues fréquentes,autres lésions, etc ...)
                    <p style={{ fontSize: "12px" }}>
                      skin diseases (removal of beauty mole, frequent verruca,
                      other other skin lesions, etc...)
                    </p>
                  </label>
                  <p onChange={(e) => setmaladiepeau(e.target.value)}>
                    <input value="oui" type="radio" id="qsddt1" name="qsddt1" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="qsddt1"
                      name="qsddt1"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <label for="quest12">
                    6.12) Maladies infectieuses sévères ou compliquées
                    (tuberculose, hépatites A, B ou C. infeptions à répétition,
                    ...)
                    <p style={{ fontSize: "12px" }}>
                      Severe or complicated infectious diseases (tuberculosis,
                      hepatitis A, B or C. repeated infections, ...)
                    </p>
                  </label>
                  <p onChange={(e) => setmaladieinfec(e.target.value)}>
                    <input value="oui" type="radio" id="qddts1" name="qddts1" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="qddts1"
                      name="qddts1"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest15">
                    6.13) Maladies parasitaires ou mycoses (paludisme,
                    bilharziose, candidose, dysenterid amibienne, filariose,
                    onchocerose, leismaniose, Kalazar, ...)
                    <p style={{ fontSize: "12px" }}>
                      Parasitic or mycoses diseases(malaria, candida, dysentery,
                      filariasis, onchocerosis, leismaniosis, Kala-azar, etc...)
                    </p>
                  </label>
                  <p onChange={(e) => setmaladiepara(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="qddtds1"
                      name="qddtds1"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="qddtds1"
                      name="qddtds1"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest18">
                    6.14) Maladies des organes de sens, troubles oculaires et
                    auditifs (myopie, surdité, affection de nez ou gorge ? ...)
                    <p style={{ fontSize: "12px" }}>
                      sensual, ocular and auditory complications
                      (myopia/shortsightedness, deafness, etc...)
                    </p>
                  </label>
                  <p onChange={(e) => setmaladieorga(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="qddtzds1"
                      name="qddtzds1"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="qddtzds1"
                      name="qddtzds1"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest3">
                    6.15) Vous avez un défaut de constitution ou une infirmité ?
                    Si OUI/YES quand? ?{" "}
                    <p style={{ fontSize: "12px" }}>
                      other diseases not mentioned above
                    </p>
                  </label>
                  <p onChange={(e) => setdefaultconst(e.target.value)}>
                    <input value="oui" type="radio" id="qdd" name="qdd" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="qdd"
                      name="qdd"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>
                    6.16) Si OUI quel?
                    <p style={{ fontSize: "12px" }}>if yes, which</p>{" "}
                  </label>
                  <p>
                    <input
                      value={defaultdesc}
                      onChange={(e) => setdefaultdesc(e.target.value)}
                      type="text"
                      class="form-control form-control-user"
                    />
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest7">
                    6.17) Vous a-t-on dit que vous avez une tumeur, un cancer ou
                    la prostate?
                    <p style={{ fontSize: "12px" }}>
                      Have you been fold you have a tumor, cancer or prostate?
                    </p>
                  </label>
                  <p onChange={(e) => settumeur(e.target.value)}>
                    <input value="oui" type="radio" id="qddd" name="qddd" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="qddd"
                      name="qddd"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <label for="quest10">
                    6.18) Avez-vous suivi une consultation ou un traitement en
                    rapport avec le SIDA, ou encore une situation liée au SIDA
                    <p style={{ fontSize: "12px" }}>
                      Have you had an AIDS-related consultaion or treament or an
                      AIDS-related situation?
                    </p>
                  </label>
                  <p onChange={(e) => setsida(e.target.value)}>
                    <input value="oui" type="radio" id="qdddd" name="qdddd" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="qdddd"
                      name="qdddd"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest13">
                    6.19) Avez-vous eu ou vous a-t-on dit que vous étiez
                    sero-positif(ve)?
                    <p style={{ fontSize: "12px" }}>
                      Have you ever had or been told that you are HIV-positive?
                    </p>
                  </label>
                  <p onChange={(e) => setpositive(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="qdddddd"
                      name="qdddddd"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="qdddddd"
                      name="qdddddd"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest16">
                    6.20) Autres maladies NON/NO citées ci-dessus :
                    <p style={{ fontSize: "12px" }}>
                      Other diseases not mentioned above:
                    </p>
                  </label>
                  <p onChange={(e) => setautres(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="qddddddzd"
                      name="qddddddzd"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="qddddddzd"
                      name="qddddddzd"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <label>
                    6.21) Si OUI, lesquelles?{" "}
                    <p style={{ fontSize: "12px" }}>if yes, which ones?</p>
                  </label>
                  <p>
                    <input
                      value={autresdesc}
                      onChange={(e) => setautresdesc(e.target.value)}
                      type="text"
                      class="form-control form-control-user"
                    />
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>
                    6.22)Précisez les indices exacts, la date de découverte, les
                    traitements, l'évolution de vos maladies{" "}
                    <p style={{ fontSize: "12px" }}>
                      specify the exact signs, the date of diagnosis, he
                      treatments and evolution
                    </p>
                  </label>
                  <p>
                    <input
                      value={traitement_evolution}
                      onChange={(e) => settraitement_evolution(e.target.value)}
                      type="text"
                      class="form-control form-control-user"
                      placeholder="date découverte/Les traitements/évolution"
                    />
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest01">
                    7.1) Fumez-vous ?
                    <p style={{ fontSize: "12px" }}>do you smoke?</p>
                  </label>
                  <p onChange={(e) => setsmoke(e.target.value)}>
                    <input value="oui" type="radio" id="zd" name="zd" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="zd"
                      name="zd"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>
                    7.2) Si OUI, combien d'unités par jour?{" "}
                    <p style={{ fontSize: "12px" }}>
                      If yes, how many sticks a day?
                    </p>
                  </label>
                  <p>
                    <input
                      value={smokeqty}
                      onChange={(e) => setsmokeqty(e.target.value)}
                      type="number"
                      class="form-control form-control-user"
                    />
                  </p>
                </div>
              </div>
            </form>{" "}
          </div>{" "}
        </div>
        {/* <!-- cinquième partie --> */}
        <div class="mt-5 mb-4">
          <div class="container border  m-auto">
            <form class="user mt-4 mb-4">
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest05">
                    8.1) Consommez-vous l' alcool?{" "}
                    <p style={{ fontSize: "12px" }}>Do you consume alcohol?</p>
                  </label>
                  <p onChange={(e) => setalcool(e.target.value)}>
                    <input value="oui" type="radio" id="zdzd" name="zdzd" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="zdzd"
                      name="zdzd"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest08">
                    8.2) Consommez-vous la drogue?{" "}
                    <p style={{ fontSize: "12px" }}>Do you consume drugs?</p>
                  </label>
                  <p onChange={(e) => setdrugs(e.target.value)}>
                    <input value="oui" type="radio" id="zdzdd" name="zdzdd" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="zdzdd"
                      name="zdzdd"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest011">
                    8.3) Si OUI, quelle quantité par jour?{" "}
                    <p style={{ fontSize: "12px" }}>
                      If yes, what quantity per day?
                    </p>
                  </label>
                  <p>
                    <input
                      value={drugsdesc}
                      onChange={(e) => setdrugsdesc(e.target.value)}
                      type="number"
                      class="form-control form-control-user"
                      id="quest011"
                      name="quest011"
                    />{" "}
                  </p>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest017">
                    9.2) Etes-vous enceinte ?{" "}
                    <p style={{ fontSize: "12px" }}>Are you pregnant ?</p>
                  </label>
                  <p onChange={(e) => setenceinte(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="zdzaddd"
                      name="zdzaddd"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="zdzaddd"
                      name="zdzaddd"
                    />{" "}
                    NON/NO
                  </p>
                </div>

                <div class="col-sm-4">
                  <label for="quest06">
                    9.5) De combien de mois ?{" "}
                    <p style={{ fontSize: "12px" }}>How many months ago?</p>
                  </label>
                  <p>
                    <input
                      value={enceintemois}
                      onChange={(e) => setenceintemois(e.target.value)}
                      type="number"
                      class="form-control form-control-user"
                      id="quest06"
                      name="quest06"
                    />
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest02">
                    9.3) Avez-vous déjà eu une affection de vos règles ou des
                    organes génitaux ?
                    <p style={{ fontSize: "12px" }}>
                      Have you ever had a problem with your menstrual period
                      genital organs?
                    </p>
                  </label>
                  <p onChange={(e) => setregles(e.target.value)}>
                    <input value="oui" type="radio" id="zd" name="zd" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="zd"
                      name="zd"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest04">
                    9.4) vos accouchements antérieurs ont-ils été normaux ?
                    <p style={{ fontSize: "12px" }}>
                      have your past deliveries been carried out under normal
                      conditions?
                    </p>
                  </label>
                  <p onChange={(e) => setaccouche(e.target.value)}>
                    <input value="oui" type="radio" id="zde" name="zde" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="zde"
                      name="zde"
                    />{" "}
                    NON/NO
                  </p>
                </div>

                <div class="col-sm-4">
                  <label for="quest09">
                    9.6) Si NON, précisez les interventions chirurgicales
                    subies?
                    <p style={{ fontSize: "12px" }}>
                      If no, specify the surgical procedures performed
                    </p>
                  </label>
                  <p>
                    <input
                      value={accouchedesc}
                      onChange={(e) => setaccouchedesc(e.target.value)}
                      type="text"
                      class="form-control form-control-user"
                      id="quest09"
                      name="quest09"
                    />{" "}
                  </p>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-4">
                  <label for="quest012">
                    10.1) Devez-vous etre hospitalisé prochainement ou subir des
                    examens médicaux
                    <p style={{ fontSize: "12px" }}>
                      Are you expecting to be hospitalized in the near future or
                      to undergo medical tests?
                    </p>
                  </label>
                  <p onChange={(e) => sethospnext(e.target.value)}>
                    <input value="oui" type="radio" id="zzdde" name="zzdde" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="zzdde"
                      name="zzdde"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest015">10.2) Si OUI/YES</label>

                  <p>
                    <input
                      value={hospnextdesc}
                      onChange={(e) => sethospnextdesc(e.target.value)}
                      type="text"
                      placeholder="date/nature"
                      class="form-control form-control-user"
                      id="quest018"
                      name="quest018"
                    />{" "}
                  </p>
                </div>

                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest004">
                    10.4) Devez-vous sibir une intervention chirurgicale?
                  </label>
                  <p onChange={(e) => setintervention(e.target.value)}>
                    <input
                      value="oui"
                      type="radio"
                      id="zzdddze"
                      name="zzdddze"
                    />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="zzdddze"
                      name="zzdddze"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest03">
                    10.3) Si OUI, les motifs{" "}
                    <p style={{ fontSize: "12px" }}>if yes, reasons</p>{" "}
                  </label>
                  <p>
                    <input
                      value={intervdesc}
                      onChange={(e) => setintervdesc(e.target.value)}
                      type="text"
                      class="form-control form-control-user"
                      id="quest03"
                      name="quest03"
                    />
                  </p>
                </div>

                <div class="col-sm-4">
                  <label for="quest07">
                    10.5) Vous a-t-on dit que vous deviez subir des tests de
                    diagnostic, etre hospitalisé dans les prochains mois?
                    <p style={{ fontSize: "12px" }}>
                      have you been told you are to undergo diagnosis tests or
                      be hospitalized in the months ahead?
                    </p>
                  </label>
                  <p onChange={(e) => setdiagnostic(e.target.value)}>
                    <input value="oui" type="radio" id="zze" name="zze" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="zze"
                      name="zze"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest010">
                    11.1) Avez-vous suivi ou Suivez-vous un traitement médical?
                    <p style={{ fontSize: "12px" }}>
                      have you undergone or are undergoing medical treatment?
                    </p>
                  </label>
                  <p onChange={(e) => settraitmedical(e.target.value)}>
                    <input value="oui" type="radio" id="zzez" name="zzez" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="zzez"
                      name="zzez"
                    />{" "}
                    NON/NO
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <label for="quest013">
                    11.2) Si OUI s'agit-il d'un traitement par perfusion/
                    piqures ?
                    <p style={{ fontSize: "12px" }}>
                      If yes, were you or are you on drips / were you or are you
                      on injections?
                    </p>
                  </label>
                  <p>
                    <input
                      value={traitmedidesc}
                      onChange={(e) => settraitmedidesc(e.target.value)}
                      type="text"
                      class="form-control form-control-user"
                      id="quest013"
                      name="quest013"
                    />{" "}
                  </p>
                </div>

                <div class="col-sm-4">
                  <label for="quest018">
                    12.1) Un membre de votre famille a-t-il présenté des cas de
                    maladie héréditaires telles que: Tumeurs malignes, maladie
                    rénales, maladies cardiovasculaires, ictus, hypertension,
                    goutte, anomalies biologiques; maladies ou désordres
                    héréditaires; dyslipidémie, diabète, tuberculose,
                    alcoolisme, suicide, épilepsie, ou autre maladies mentales,
                    maladies nerveuses.
                    <p style={{ fontSize: "12px" }}>
                      Has anyone in your family had any hereditary disease such
                      as: Malignant tumors, disease kidney disease,
                      cardiovascular disease, stroke, hypertension, gout,
                      laboratory abnormalities; diseases or disorders
                      hereditary; dyslipidemia, diabetes, tuberculosis,
                      alcoholism, suicide, epilepsy, or other mental illness,
                      nervous diseases.
                    </p>
                  </label>
                  <p onChange={(e) => setmifa(e.target.value)}>
                    <input value="oui" type="radio" id="zzezzz" name="zzezzz" />{" "}
                    OUI/YES&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value="non"
                      type="radio"
                      class="from-check-label"
                      id="zzezzz"
                      name="zzezzz"
                    />{" "}
                    NON/NO
                  </p>
                </div>
                <div class="col-sm-4">
                  <label>
                    12.2) Si OUI/YES, précisez de qui s'agit{" "}
                    <p style={{ fontSize: "12px" }}>
                      If yes, specify who it is
                    </p>
                    :{" "}
                  </label>
                  <p>Père / Mère / Frère / Soeur</p>
                  <input
                    value={mifadesc}
                    onChange={(e) => setmifadesc(e.target.value)}
                    type="text"
                    class="form-control form-control-user"
                    id="quest03"
                    name="quest03"
                  />
                </div>
              </div>{" "}
              <div class="form-group row"></div>
              <p class="text-justify m-4">
                Je certifie avoir répondu sincèrement, sans aucune réticence et
                n'avoir rien dissimulé sur mon état de santé passé ou actuel et
                pends acte que toute réticnce ou fausse déclaration de ma part
                entrainera la nullité du contrat. j'autorise expressément la
                compagnie à prendre toutes informations qu'elle jugerait utiles
                et nécessaires auprès des médecins qui m'ont soigné.
                <p style={{ fontSize: "12px" }}>
                  I certify that I answered sincerely, without any reluctance
                  and to have concealed nothing about my past or current state
                  of health and take note that any reluctance or
                  misrepresentation on my part will result in the nullity of the
                  contract. I expressly authorize the company to take any
                  information it deems useful and needed by the doctors who
                  treated me.
                </p>
              </p>
              <p class="ml-4 mr-4">
                J'autorise ces médecins à communiquer à la compagnie tous les
                renseignements demandés.
                <p style={{ fontSize: "12px" }}>
                  I authorize these physicians to communicate to the company
                  every information requested.
                </p>
              </p>{" "}
              <div class="user mt-4 mb-4">
                <div class="form-group row ">
                  <div class="col-sm-2  "></div>
                  <div class="col-sm-2  "></div>
                  <div class="col-sm-2  "></div>
                  <div class="col-sm-2  "></div>

                  {/* <div class="col-sm-2  ">
                    <label for="fait">Fait à</label>
                    <input
                      type="texr"
                      class="form-control form-control-user"
                      id="fait"
                      name="fait"
                    />
                  </div> */}

                  {/* <div class="col-sm-2  ">
                    <label for="date">Le</label>
                    <input
                      type="date"
                      class="form-control form-control-user"
                      id="date"
                      name="date"
                    />
                  </div> */}
                </div>
              </div>
            </form>{" "}
          </div>{" "}
        </div>
      </Modal>
    </>
  );
}
