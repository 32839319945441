import React from "react";
import {
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";

export default function ProtectedRoute({ component: Component, ...rest }) {
            const userSignin = useSelector((state) => state.userSignin);
            const { isAuth, loading } = userSignin;

            console.log(isAuth);

  return (
    <>
     { loading === false && (<Route
        {...rest}
        render={(props) =>
          isAuth ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
              }}
            />
          )
        }
      />)}
    </>
  );
}
