import React,{useState} from "react"
import { Modal, Button, Form, Input, InputNumber } from "antd";
import { useDispatch } from "react-redux";
import { addAgence, listAgence } from "./redux/actions/agenceActions";

export default function CreerAgence ()  {
  const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [code_agence, setcode_agence] = useState("")
    const [code_banque, setcode_banque] = useState("")
    const [libelle_agence, setlibelle_agence] = useState("")

  const showModal = () => {
    setVisible(true);
    };
    const dispacth = useDispatch()
    
      const agenceData = new FormData();
      agenceData.append("code_agence", code_agence);
      agenceData.append("code_banque", "NF");
        agenceData.append("libelle_agence", libelle_agence);
  const clearState = () => {
  setcode_agence("");
  setlibelle_agence("");
}

  const handleOk = () => {
      setConfirmLoading(true);
      dispacth(addAgence(agenceData));
    setTimeout(() => {
      setVisible(false);
      clearState();
         dispacth(listAgence());
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Créer Agence
      </Button>
      <Modal
        title="Créer agence"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form.Item label="Code agence">
          <Input
            value={code_agence}
            onChange={(e) => setcode_agence(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Libelle agence">
          <Input
            value={libelle_agence}
            onChange={(e) => setlibelle_agence(e.target.value)}
          />
        </Form.Item>
        {/* <Form.Item label="Code banque">
          <Input
            value={code_banque}
            onChange={(e) => setcode_banque(e.target.value)}
          />
        </Form.Item> */}
      </Modal>
    </>
  );
};
