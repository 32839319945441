import React from 'react'

export default function QMedicalPBli({ QMedical }) {


    return (
      <>
        <div>{QMedical[0].nom_demandeur}</div>
        <div class="mt-4 mb-4">
          <div class="container border  m-auto">
            <form class="user mt-4 mb-4">
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  Nom de l'agence: <strong>{QMedical[0].nom_agence} </strong>
                </div>
                <div class="col-sm-4">
                  Date de naissance :{" "}
                  <strong>{QMedical[0].date_naissance} </strong>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  Adresse :<strong>{QMedical[0].lieu_naissance} </strong>{" "}
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  Nom et Prénom du proposant adhérent :{" "}
                  <strong>{QMedical[0].nom_demandeur} </strong>
                </div>
                {/* <div class="col-sm-4 mb-3 mb-sm-0">
                  <input
                    type="text"
                    class="form-control form-control-user"
                    placeholder="Lieu de naissance"
                  />
                </div> */}
                <div class="col-sm-4 mb-3 mb-sm-0">Profession:</div>
              </div>
              <div class="form-group row">
                {/* <div class="col-sm-4 mb-3 mb-sm-0">
                  <input
                    type="number"
                    class="form-control form-control-user"
                    placeholder="N° de police"
                  />
                </div> */}
                <div class="col-sm-4">N° de téléphone:</div>
                {/* <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control form-control-user"
                    placeholder=" Nationalité"
                  />
                </div> */}
              </div>
            </form>
          </div>
        </div>
        {/* <!-- deuxième partie --> */}
        <div class="mt-5 mb-4">
          <div class="container border  m-auto">
            <form class="user mt-4 mb-4">
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest1">
                    1.1) Avez-vous déjà été refusé, ajourné ou surprimé pour un
                    précédent contrat d’assurance de personnes ?{" "}
                    <p style={{ fontSize: "12px" }}>
                      Have you ever been refused, postponed or overworked for a
                      previous life and health insurance contract?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].refus_assurance} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>1.2)Si OUI/YES</label>

                  <p>
                    <strong>{QMedical[0].motif_refus} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest2">
                    2.1) Avez-vous été victime d'accident (automobile ou autre)
                    ?
                    <p style={{ fontSize: "12px" }}>
                      Have you been the victim of an accident (automobile or
                      other) ?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].accident_auto} </strong>{" "}
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>2.2) Si OUI/YES</label>
                  <p>
                    <strong>{QMedical[0].accident_autodesc} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest3">
                    2.3) Pratiquez-vous des activités sportives ?
                    <p style={{ fontSize: "12px" }}>
                      Do you participate in sports activities?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].activite_sportive} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest4">2.4) Si OUI/YES</label>
                  <p>
                    <strong>{QMedical[0].observation_sportive} </strong>{" "}
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <label for="quest2d6">
                    3.1) Au cours de ces dernières années, avez-vous fait des
                    séjours dans un hôpital, clinique, sanatorium ou une ,aison
                    de repos?
                    <p style={{ fontSize: "12px" }}>
                      In the past few years, have you done any stays in a
                      hospital, clinic, sanatorium or aison rest?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].sejour_hopital} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest002">3.2) Si OUI/YES</label>

                  <label for="quest002"></label>
                  <p>
                    <strong>{QMedical[0].obs_sejour_h} </strong>{" "}
                  </p>
                </div>
                {/* <div class="col-sm-4">
                  <p>
                    {" "}
                    3.3)<strong> Résultats:</strong>
                  </p>
                  <p>
                    <input
                      type="text"
                      class="form-control"
                      id="quest0103"
                      name="quest0103"
                    />{" "}
                  </p>
                </div> */}
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <p>
                    {" "}
                    3.4) Avez-vous subi :{" "}
                    <p style={{ fontSize: "12px" }}>Have you suffered:</p>
                  </p>
                  <label>
                    <strong>examens médicaux?</strong>{" "}
                  </label>
                  <p>
                    Sang / urines{" "}
                    <p>
                      <strong>{QMedical[0].sang_urine} </strong>{" "}
                    </p>{" "}
                    Electrocardiogramme
                    <p>
                      <strong>{QMedical[0].exam_electrocardiogramme} </strong>{" "}
                    </p>
                    Radiographies
                    <p>
                      <strong>{QMedical[0].exam_radiographie} </strong>{" "}
                    </p>
                    Test de dépistage (toxoplasmose, hepatite B, SIDA etc...){" "}
                    <p>
                      {/* <strong>{QMedical[0].exam_radiographie} </strong>{" "} */}
                    </p>
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <p>
                    {" "}
                    3.6)
                    <strong>
                      {" "}
                      Un traitement specialisé tel que :
                      <p style={{ fontSize: "12px" }}>
                        Specialized treatment such as
                      </p>
                    </strong>{" "}
                  </p>
                  <label for="quest22">
                    Rayons x, chimiothérapie immunothérapie ou cobaltothérapie ?
                    <p style={{ fontSize: "12px" }}>
                      X-rays, chemotherapy, immunotherapy or cobaltotherapy?
                    </p>
                  </label>
                  <p>
                    {/* <strong>{QMedical[0].exam_radiographie} </strong>{" "} */}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest24">
                    3.7) + Des transfusions de sang ou de dérivés sanguim{" "}
                    <p style={{ fontSize: "12px" }}>
                      Transfusions of blood or blood derivatives
                    </p>{" "}
                    :
                  </label>
                  <p>
                    {/* <strong>{QMedical[0].exam_radiographie} </strong>{" "} */}
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <label for="quesdzt27">
                    3.8) Avez-vous subi un check-up, consultation, maladie,
                    blessure, ou une intervention chirurgicale?
                    <p style={{ fontSize: "12px" }}>
                      Have you undergone a check-up, consultation, illness,
                      injury, or surgery?
                    </p>{" "}
                    :
                  </label>
                  <p>
                    <strong>{QMedical[0].check_up} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="questrer28">
                    3.9) Avez-vous subi un électrocardiogramme, un traitement de
                    rayons x ?{" "}
                    <p style={{ fontSize: "12px" }}>
                      Have you had an EKG, treatment for X-rays ?
                    </p>{" "}
                    :
                  </label>
                  <p>
                    <strong>{QMedical[0].exam_electrocardiogramme} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <p>
                    <label for="questi01">
                      3.5) Préciser les résultats{" "}
                      <p style={{ fontSize: "12px" }}>Specify the results</p>:{" "}
                    </label>
                  </p>
                  <p>
                    {/* <strong>{QMedical[0].exam_electrocardiogramme} </strong>{" "} */}
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* <!-- troisième partie --> */}

        <div class="mt-5 mb-4">
          <div class="container border  m-auto">
            <form class="user mt-4 mb-4">
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="ddzd">
                    4.1) L’exécution de vos tâches quotidiennes vous expose
                    t-elle à des dangers particuliers d’accident ou de maladie ?
                    <p style={{ fontSize: "12px" }}>
                      Performing your daily tasks exposes you to particular
                      dangers of accident or illness?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].danger_particulier} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>4.2) Si OUI/YES, lesquels?</label>
                  <p>
                    <strong>{QMedical[0].obs_danger} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="questd006">
                    4.3) Etes-vous actuellement en arrêt de travail ?
                    <p style={{ fontSize: "12px" }}>
                      Are you currently on sick leave?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].workstop} </strong>{" "}
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest002">4.3.1) Si OUI/YES</label>

                  <label for="quest002"></label>
                  <p>
                    <strong>{QMedical[0].workstopdesc} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest0d02">
                    4.4) Avez-vous été exempté du service militaire, reformé
                    pour un motif de santé?
                    <p style={{ fontSize: "12px" }}>
                      Have you been exempted from military service, reformed for
                      health reasons?
                    </p>
                  </label>
                  <p>
                    {/* <strong>{QMedical[0].workstopdesc} </strong>{" "} */}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quesfet005">
                    4.5) Durant les 5 dernières années, avez-vous dû interrompre
                    votre travail pendant plus de trois semaines pour problème
                    de santé
                    <p style={{ fontSize: "12px" }}>
                      During the past 5 years, have you had to interrupt your
                      work for more than three weeks for problem health
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].workstophealth} </strong>{" "}
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <label>4.5.1) Si OUI/YES</label>
                  <p>
                    <p>
                      <strong>{QMedical[0].workstophealthdesc} </strong>{" "}
                    </p>
                  </p>
                </div>
                <div class="col-sm-4">
                  <p>
                    5.1) Quels sont vos taille et poids ?{" "}
                    <p style={{ fontSize: "12px" }}>
                      What are your height and weight?
                    </p>
                  </p>
                  <strong>{QMedical[0].poid} </strong>{" "}
                  <p>
                    <strong>{QMedical[0].taille} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <p>
                    5.2) Avez-vous grossi ou maigri de plus de 5 kgs ces
                    dernières années ?{" "}
                    <p style={{ fontSize: "12px" }}>
                      Have you gained or lost weight more than 5 kgs these last
                      years ?
                    </p>
                  </p>
                  <strong>{QMedical[0].grossi_maigri} </strong>{" "}
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>
                    5.3) Si OUI/YES, de combien de kgs?{" "}
                    <p style={{ fontSize: "12px" }}>If so, how many kgs</p>
                  </label>
                  <p>
                    <strong>{QMedical[0].obs_grossi} </strong>{" "}
                  </p>
                </div>
              </div>
            </form>{" "}
          </div>{" "}
        </div>
        {/* <!-- quartrième partie --> */}

        <div class="mt-5 mb-4">
          <div class="container border  m-auto">
            <form class="user mt-4 mb-4">
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <p>
                    6.1) Souffrez-vous ou avez-vous été atteint de{" "}
                    <p style={{ fontSize: "12px" }}>
                      Do you suffer or have you been affected by
                    </p>{" "}
                    :
                  </p>
                  <label for="quest1">
                    Maladies de l’appareil respiratoire (toux de longue durée,
                    crachement de sang, essoufflement, affection des poumons,
                    allergies, bronchite, sinusite, asthme,pneumonie, pleurésie
                    et autres affections pulmonaires ? ..)
                    <p style={{ fontSize: "12px" }}>
                      Diseases of the respiratory system (long-lasting cough,
                      coughing up blood, shortness of breath, lung damage
                      allergies, bronchitis, sinusitis, asthma, pneumonia,
                      pleurisy and other lung conditions? ..)
                    </p>
                  </label>
                  <strong>{QMedical[0].maladie_respiration} </strong>{" "}
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>
                    6.2) Si OUI/YES Précisez les indices exacts{" "}
                    <p style={{ fontSize: "12px" }}>
                      If yes, specify the exact indices
                    </p>
                    :
                  </label>

                  <p>
                    <input
                      // value={}
                      type="text"
                      class="form-control form-control-user"
                      placeholder="date découverte/Les traitements/évolution"
                    />
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest5">
                    6.3) Maladies de l'appareil cardio-vasculaire (infarctus,
                    palpitation, hypertension artérielle, crises cardiaques et
                    autres affections cardiovasculaires, ...) ?
                    <p style={{ fontSize: "12px" }}>
                      Diseases of the cardiovascular system (heart attack,
                      palpitation, high blood pressure, heart attacks and other
                      cardiovascular conditions, ...)?
                    </p>
                  </label>
                  <strong>{QMedical[0].maladie_cardio} </strong>{" "}
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest8">
                    6.4) Maladies de l ’appareil digestif (jaunisse, hépatite,
                    maladie du foie, diarrhée chronique, colite, ulcères
                    gastriques ou duodénal hernie, appendicite, ou autres
                    affections de l 'estomac ou intestins ? ...)
                    <p style={{ fontSize: "12px" }}>
                      Diseases of the digestive system (jaundice, hepatitis,
                      liver disease, chronic diarrhea, colitis, ulcers gastric
                      or duodenal hernia, appendicitis, or others stomach or
                      intestinal disorders? ...)
                    </p>
                  </label>
                  <strong>{QMedical[0].maladie_diges} </strong>{" "}
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest11">
                    6.5) Maladie de l’appareil urinaire et génital (albuminurie
                    et sang dans les urines, syphilis, gonococcie, maladies
                    sexuellement transmissibles, etc...)
                    <p style={{ fontSize: "12px" }}>
                      Disease of the urinary and genital tract (albuminuria and
                      blood in the urine, syphilis, gonorrhea, diseases sexually
                      transmitted, etc ...)
                    </p>
                  </label>
                  <strong>{QMedical[0].maladie_urinaire} </strong>{" "}
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest14">
                    6.6) Maladies du système nerveux
                    (Névntesciatique,paralysies,méningite, congestion cérébrale,
                    vertige épilepsie, maux de tête prolongés, maladie des
                    muscles?...)
                    <p style={{ fontSize: "12px" }}>
                      Diseases of the nervous system (Sciatic neuritis,
                      paralysis, meningitis, cerebral congestion, dizziness
                      epilepsy, prolonged headache, disease muscles? ...)
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].maladie_nerveux} </strong>{" "}
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest17">
                    6.7) Maladies neuro-psychiques (dépression nerveuse,
                    tentative de suicide, fatigue prolongée, perte de mémoire,
                    épilepsie, affection mentale, perte de connaissance, ...)
                    <p style={{ fontSize: "12px" }}>
                      Neuro-psychic diseases (nervous breakdown, suicide
                      attempt, prolonged fatigue, memory loss, epilepsy, mental
                      illness, loss of consciousness, ...)
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].maladie_psy} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest2">
                    6.8) Maladies du sang de ganglions et de la terre (anémie,
                    présence de ganglions anormaux, Hémoglobinopathies et crises
                    hémolytiques, "sickle cell" etc . ..)?
                    <p style={{ fontSize: "12px" }}>
                      Diseases of the blood, lymph nodes and soil (anemia,
                      presence of abnormal lymph nodes, Haemoglobinopathies and
                      seizures hemolytics, "sickle cell" etc. ..)?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].maladie_sang} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest4">
                    6.9) Maladies endocriniennes ou métaboliques ( diabète,
                    goutte, anomalies de la thyroïde, . )
                    <p style={{ fontSize: "12px" }}>
                      Endocrine or metabolic diseases (diabetes, gout, thyroid
                      abnormalities,. )
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].maladie_endo} </strong>{" "}
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <label for="quest6">
                    6.10) Maladies des os et des articulations(érthrose,
                    arthrite, rhumatismes divers)?
                    <p style={{ fontSize: "12px" }}>
                      Diseases of the bones and joints (osteoarthritis,
                      arthritis, various rheumatism)?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].maladie_os} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quedst9">
                    6.11) Maladies de la peau (ablation de grain de beauté,
                    verrues fréquentes,autres lésions, etc ...)
                    <p style={{ fontSize: "12px" }}>
                      Skin diseases (mole removal, frequent warts, other
                      lesions, etc.)
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].maladie_peau} </strong>{" "}
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <label for="quest12">
                    6.12) Maladies infectieuses sévères ou compliquées
                    (tuberculose, hépatites A, B ou C. infeptions à répétition,
                    ...)
                    <p style={{ fontSize: "12px" }}>
                      Severe or complicated infectious diseases (tuberculosis,
                      hepatitis A, B or C. repeated infections, ...)
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].maladie_infec} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest15">
                    6.13) Maladies parasitaires ou mycoses (paludisme,
                    bilharziose, candidose, dysenterid amibienne, filariose,
                    onchocerose, leismaniose, Kalazar, ...)
                    <p style={{ fontSize: "12px" }}>
                      Parasitic diseases or mycoses (malaria, bilharzia,
                      candidiasis, amoebic dysentery, filariasis, onchocerosis,
                      leismaniasis, Kalazar, ...)
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].maladie_para} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest18">
                    6.14) Maladies des organes de sens, troubles oculaires et
                    auditifs (myopie, surdité, affection de nez ou gorge ? ...)
                    <p style={{ fontSize: "12px" }}>
                      Diseases of the sense organs, eye disorders and hearing
                      (myopia, deafness, nose or throat disease? ...)
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].maladie_organe} </strong>{" "}
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest3">
                    6.15) Vous avez un défaut de constitution ou une infirmité ?
                    Si OUI/YES quand? ?{" "}
                    <p style={{ fontSize: "12px" }}>
                      Do you have a constitution defect or an infirmity? If so
                      when? ?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].infirmite} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>6.16) Si OUI/YES quand? </label>
                  <p>{/* <strong>{QMedical[0].infirmite} </strong>{" "} */}</p>
                </div>
                <div class="col-sm-4">
                  <label for="quest7">
                    6.17) Vous a-t-on dit que vous avez une tumeur, un cancer ou
                    la prostate?
                    <p style={{ fontSize: "12px" }}>
                      Have you been told you have a tumor, cancer or prostate?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].tumeur} </strong>{" "}
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <label for="quest10">
                    6.18) Avez-vous suivi une consultation ou un traitement en
                    rapport avec le SIDA, ou encore une situation liée au SIDA
                    <p style={{ fontSize: "12px" }}>
                      Have you undergone any consultation or treatment in
                      related to AIDS, or a situation related to AIDS
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].traitement_sida} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest13">
                    6.19) Avez-vous eu ou vous a-t-on dit que vous étiez
                    sero-positif(ve)?
                    <p style={{ fontSize: "12px" }}>
                      Have you had or were you told you were sero-positive?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].sero_positif} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest16">
                    6.20) Autres maladies NON/NO citées ci-dessus :
                    <p style={{ fontSize: "12px" }}>
                      Other diseases not mentioned above:
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].autres_maladie} </strong>{" "}
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <label>6.21) Si OUI/YES, lesquelles?</label>
                  <p>
                    <strong>{QMedical[0].obs_autre_mal} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest01">7.1) Fumez-vous ?</label>
                  <p>
                    <strong>{QMedical[0].fumeur} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label>
                    7.2) Si OUI/YES, combien d'unités par jour?{" "}
                    <p style={{ fontSize: "12px" }}>
                      If so, how many units per day?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].obs_fumeur} </strong>{" "}
                  </p>
                </div>
              </div>
            </form>{" "}
          </div>{" "}
        </div>
        {/* <!-- cinquième partie --> */}
        <div class="mt-5 mb-4">
          <div class="container border  m-auto">
            <form class="user mt-4 mb-4">
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest05">
                    8.1) Consommez-vous l' alcool?{" "}
                    <p style={{ fontSize: "12px" }}>Do you drink alcohol?</p>
                  </label>
                  <p>
                    <strong>{QMedical[0].alcool} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest08">
                    8.2) Consommez-vous la drogue?{" "}
                    <p style={{ fontSize: "12px" }}>Do you use drugs?</p>
                  </label>
                  <p>
                    <strong>{QMedical[0].drogue} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest011">
                    8.3) Si OUI/YES, quelle quantité par jour?{" "}
                    <p style={{ fontSize: "12px" }}>If so, how much per day?</p>
                  </label>
                  <p>
                    <strong>{QMedical[0].obs_drogue} </strong>{" "}
                  </p>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest017">
                    9.2) Etes-vous enceinte ?{" "}
                    <p style={{ fontSize: "12px" }}>Are you pregnant ?</p>
                  </label>
                  <p>
                    <strong>{QMedical[0].enceinte} </strong>{" "}
                  </p>
                </div>

                <div class="col-sm-4">
                  <label for="quest06">
                    9.5) De combien de mois ?{" "}
                    <p style={{ fontSize: "12px" }}>How many months?</p>
                  </label>
                  <p>
                    <strong>{QMedical[0].nbre_mois} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest02">
                    9.3) Avez-vous déjà eu une affection de vos règles ou des
                    organes génitaux ?
                    <p style={{ fontSize: "12px" }}>
                      Have you ever had a problem with your period or genitals?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].affection_regle} </strong>{" "}
                  </p>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest04">
                    9.4) vos accouchements antérieurs ont-ils été normaux ?
                    <p style={{ fontSize: "12px" }}>
                      have your previous deliveries been normal?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].accouchement} </strong>{" "}
                  </p>
                </div>

                <div class="col-sm-4">
                  <label for="quest09">
                    9.6) Si NON/NO, précisez les interventions chirurgicales
                    subies?
                    <p style={{ fontSize: "12px" }}>
                      If not, specify the surgical procedures suffered?
                    </p>
                  </label>
                  <p>
                    {/* <strong>{QMedical[0].accouchement} </strong>{" "} */}
                  </p>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-4">
                  <label for="quest012">
                    10.1) Devez-vous etre hospitalisé prochainement ou subir des
                    examens médicaux
                    <p style={{ fontSize: "12px" }}>
                      Are you due to be hospitalized soon or undergo medical
                      exams
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].hospi_prochaine} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest015">10.2) Si OUI/YES</label>

                  <p>
                    <strong>{QMedical[0].obs_hospi} </strong>{" "}
                  </p>
                </div>

                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest004">
                    10.4) Devez-vous sibir une intervention ?
                  </label>
                  <p>
                    {/* <strong>{QMedical[0].chirugicales} </strong>{" "} */}
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <label for="quest03">10.3) Si OUI/YES, les motifs </label>
                  <p>
                    <strong>{QMedical[0].motif_intervention} </strong>{" "}
                  </p>
                </div>

                <div class="col-sm-4">
                  <label for="quest07">
                    10.5) Vous a-t-on dit que vous deviez subir des tests de
                    diagnostic, etre hospitalisé dans les prochains mois?
                    <p style={{ fontSize: "12px" }}>
                      Have you been told you need to be tested for diagnosis, be
                      hospitalized in the next few months?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].test_hospi} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label for="quest010">
                    11.1) Avez-vous suivi ou Suivez-vous un traitement médical?
                    <p style={{ fontSize: "12px" }}>
                      Have you followed or are you following any medical
                      treatment?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].trait_medo} </strong>{" "}
                  </p>
                </div>
              </div>{" "}
              <div class="form-group row">
                <div class="col-sm-4">
                  <label for="quest013">
                    11.2) Si OUI/YES s'agit-il d'un traitement par perfusion/
                    piqures ?
                    <p style={{ fontSize: "12px" }}>
                      If yes, is it an infusion treatment / bites?
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].perfusion_piqure} </strong>{" "}
                  </p>
                </div>

                <div class="col-sm-4">
                  <label for="quest018">
                    12.1) Un membre de votre famille a-t-il présenté des cas de
                    maladie héréditaires telles que: Tumeurs malignes, maladie
                    rénales, maladies cardiovasculaires, ictus, hypertension,
                    goutte, anomalies biologiques; maladies ou désordres
                    héréditaires; dyslipidémie, diabète, tuberculose,
                    alcoolisme, suicide, épilepsie, ou autre maladies mentales,
                    maladies nerveuses.
                    <p style={{ fontSize: "12px" }}>
                      Has anyone in your family had any hereditary disease such
                      as: Malignant tumors, disease kidney disease,
                      cardiovascular disease, stroke, hypertension, gout,
                      laboratory abnormalities; diseases or disorders
                      hereditary; dyslipidemia, diabetes, tuberculosis,
                      alcoholism, suicide, epilepsy, or other mental illness,
                      nervous diseases.
                    </p>
                  </label>
                  <p>
                    <strong>{QMedical[0].mal_hereditaire} </strong>{" "}
                  </p>
                </div>
                <div class="col-sm-4">
                  <label>
                    12.2) Si OUI/YES, précisez de qui s'agit{" "}
                    <p style={{ fontSize: "12px" }}>
                      If yes, specify who it is
                    </p>
                    :{" "}
                  </label>
                  <p>Père / Mère / Frère / Soeur</p>
                  <strong>{QMedical[0].obs_hereditaire} </strong>
                </div>
              </div>{" "}
              <div class="form-group row"></div>
              <p class="text-justify m-4">
                Je certifie avoir répondu sincèrement, sans aucune réticence et
                n'avoir rien dissimulé sur mon état de santé passé ou actuel et
                pends acte que toute réticnce ou fausse déclaration de ma part
                entrainera la nullité du contrat. j'autorise expressément la
                compagnie à prendre toutes informations qu'elle jugerait utiles
                et nécessaires auprès des médecins qui m'ont soigné.
                <p style={{ fontSize: "12px" }}>
                  I certify that I answered sincerely, without any reluctance
                  and to have concealed nothing about my past or current state
                  of health and take note that any reluctance or
                  misrepresentation on my part will result in the nullity of the
                  contract. I expressly authorize the company to take any
                  information it deems useful and needed by the doctors who
                  treated me.
                </p>
              </p>
              <p class="ml-4 mr-4">
                J'autorise ces médecins à communiquer à la compagnie tous les
                renseignements demandés.
                <p style={{ fontSize: "12px" }}>
                  I authorize these physicians to communicate to the company
                  every information requested.
                </p>
              </p>{" "}
              <div class="user mt-4 mb-4">
                <div class="form-group row ">
                  <div class="col-sm-2  "></div>
                  <div class="col-sm-2  "></div>
                  <div class="col-sm-2  "></div>
                  <div class="col-sm-2  "></div>
                </div>
              </div>
            </form>{" "}
          </div>{" "}
        </div>
      </>
    );
}
