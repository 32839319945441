import Axios from "axios";
import {
  ADD_FM_REQUEST,
  ADD_FM_SUCCESS,
  ADD_FM_FAIL,
  GET_FM_REQUEST,
  GET_FM_SUCCESS,
  GET_FM_FAIL,
} from "../constants/fmConst";

export const addFm = (fmSendData) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_FM_REQUEST,
    });

    const { data } = await Axios.post(
      "/prubli_ma/ss/save_fm_banque.php",
      fmSendData
    );
    dispatch({ type: ADD_FM_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ADD_FM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listFm = (formData) => async (dispatch) => {
  dispatch({
    type: GET_FM_REQUEST,
  });
  try {
    const { data } = await Axios.post(
      "/prubli_ma/ss/recherche_qm.php",
      formData
    );
    dispatch({ type: GET_FM_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_FM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
