import React, { useState, useEffect, useCallback } from "react";
import {  Form, Input,  Button,  Row,  Col,  Radio,  Card,  notification,} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { calculPret, clearSuccess } from "./redux/actions/calculPretActions";
//import { addFm } from "./redux/actions/fmActions";
import { addContrat } from "./redux/actions/ContratActions";
import { listAccount } from "./redux/actions/accountActions";
import FormBen from "./FormBen";
import FormMed from "./FormMed";
import Print from "./print/Print";
import Axios from "axios";
import Simulateur from "./Simulateur";
import Garant from "./Garant";

// 0302820102345739

export default function EmpruntDirect() {
  const accountList = useSelector((state) => state.accountList);

  const { comptes, error } = accountList;
  // console.log(comptes);

  // state formulaire medicale
  const [poid, setpoid] = useState("");
  const [taille, settaille] = useState("");
  const [grossi, setgrossi] = useState("");
  const [assurance_vie, setassurance_vie] = useState("");
  const [compagnie_assurance, setcompagnie_assurance] = useState("");
  const [maladie_chronique, setmaladie_chronique] = useState("");
  const [traitement_regulier, settraitement_regulier] = useState("");
  const [interruption_travail, setinterruption_travail] = useState("");
  const [interruption_observation, setinterruption_observation] = useState("");
  // const [numero_compte, setnumero_compte] = useState("");

  //state formulaire medicale beneficial
  //
  // const [nationalite, setnationalite] = useState("");
  const [refuscontrat, setrefuscontrat] = useState("");
  const [motifrefus, setmotifrefus] = useState("");
  const [accident, setaccident] = useState("");
  const [accidentetat, setaccidentetat] = useState("");
  const [sportif, setsportif] = useState("");
  const [sporttype, setsporttype] = useState("");
  const [sejourH, setsejourH] = useState("");
  const [sejourdate, setsejourdate] = useState("");
  const [sejourmotif, setsejourmotif] = useState("");
  const [tachedanger, settachedanger] = useState("");
  const [tachedesc, settachedesc] = useState("");
  const [workstop, setworkstop] = useState("");
  const [workstopdesc, setworkstopdesc] = useState("");
  const [millitaire, setmillitaire] = useState("");
  const [workstophealth, setworkstophealth] = useState("");
  const [workstophealthdesc, setworkstophealthdesc] = useState("");
  const [poidsdesc, setpoidsdesc] = useState("");
  const [maladieresp, setmaladieresp] = useState("");
  const [maladiecardio, setmaladiecardio] = useState("");
  const [maladiediges, setmaladiediges] = useState("");
  const [maladieurin, setmaladieurin] = useState("");
  const [maladienerv, setmaladienerv] = useState("");
  const [maladiepsyc, setmaladiepsyc] = useState("");
  const [maladiesang, setmaladiesang] = useState("");
  const [maladieando, setmaladieando] = useState("");
  const [maladios, setmaladios] = useState("");
  const [maladiepeau, setmaladiepeau] = useState("");
  const [maladieinfec, setmaladieinfec] = useState("");
  const [maladiepara, setmaladiepara] = useState("");
  const [maladieorga, setmaladieorga] = useState("");
  const [defaultconst, setdefaultconst] = useState("");
  const [defaultdesc, setdefaultdesc] = useState("");
  const [tumeur, settumeur] = useState("");
  const [sida, setsida] = useState("");
  const [positive, setpositive] = useState("");
  const [autres, setautres] = useState("");
  const [autresdesc, setautresdesc] = useState("");
  const [smoke, setsmoke] = useState("");
  const [smokeqty, setsmokeqty] = useState("");
  const [alcool, setalcool] = useState("");
  const [drugs, setdrugs] = useState("");
  const [drugsdesc, setdrugsdesc] = useState("");
  const [enceinte, setenceinte] = useState("");
  const [enceintemois, setenceintemois] = useState("");
  const [regles, setregles] = useState("");
  const [diagnostic, setdiagnostic] = useState("");
  const [accouche, setaccouche] = useState("");
  const [accouchedesc, setaccouchedesc] = useState("");
  const [hospnext, sethospnext] = useState("");
  const [hospnextdesc, sethospnextdesc] = useState("");
  const [intervention, setintervention] = useState("");
  const [intervdesc, setintervdesc] = useState("");
  const [testdiag, settestdiag] = useState("");
  const [traitmedical, settraitmedical] = useState("");
  const [traitmedidesc, settraitmedidesc] = useState("");
  const [mifa, setmifa] = useState("");
  const [mifadesc, setmifadesc] = useState("");
  const [intervention_chiru, setintervention_chiru] = useState("")
  const [traitement_evolution, settraitement_evolution] = useState("")
  const [sangurine, setsangurine] = useState("");
  const [electrocard, setelectrocard] = useState("");
  const [radiographie, setradiographie] = useState("");
  const [depistage, setdepistage] = useState();
  const [checkup, setcheckup] = useState()
  //

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  console.log('userInfo:'+JSON.stringify(userInfo));

  const [typeoperation, settypeoperation] = useState("p");
  const [typecompte, settypecompte] = useState("c");

  const [montantcapital, setmontantcapital] = useState(0);

  const nom_client = comptes.length !== 0 ? comptes[0].PRENOM : "";

  const prenoms_client = comptes.length !== 0 ? comptes[0].LAST_NAME : "";

  const numero_compte = comptes.length !== 0 ? comptes[0].COMPTE : "";

  const email = comptes.length !== 0 ? comptes[0].EMAIL : "";

  const cni = comptes.length !== 0 ? comptes[0].CARTE_ID : "";

  const telephone = comptes.length !== 0 ? comptes[0].MOBILE : "";

  const adresse_postale = comptes.length !== 0 ? comptes[0].ADRESSE_POSTAL : "";
  const profession = comptes.length !== 0 ? comptes[0].PROFESSION : "";

  const ville = comptes.length !== 0 ? comptes[0].VILLE : "";
  const DateNai = comptes.length !== 0 ? comptes[0].DATE_NAISSANCE : "";

  const [pourcentageclient, setpourcentageclient] = useState(0);
  const [pourcentagepersonnel, setpourcentagepersonnel] = useState(0);
  const [prime_brute, setprime_brute] = useState(0);

  const [accessoires, setaccessoires] = useState(0);

  

  const [date_naissance, setdate_naissance] = useState("");

  const [prime, setprime] = useState(0);
  const [age, setAge] = useState(0);
  const [dureePret, setdureePret] = useState(0);
  const [printSee, setprintSee] = useState(false);
  const [formOk, setformOk] = useState(false);
  const [all, setall] = useState(false);
  const [saved, setsaved] = useState(false);
  // const [numero_police, setnumero_police] = useState("");
  const [numeroUnique, setnumeroUnique] = useState(0);
  const maxCap = userInfo ? parseInt(userInfo[0].seuil_capital) : 1000;
  // const maxCap = 5000000;
  const code_banque = userInfo ? userInfo[0].code_banque : "";
  const code_agence = userInfo ? userInfo[0].code_agence : "";
  const code_questionnaire = "CQ001";
  //const accessoires = 1500;
  const prime_ht = prime_brute + accessoires;
  const montant_tva = Number((prime_ht * 0.1925).toFixed(0));
  const prime_ttc = Number((prime_ht + montant_tva).toFixed(0));
  const quote_part_partenaire = Number((prime_brute * 0.15).toFixed(0));
  const quote_part_prubli = Number((prime_ttc - quote_part_partenaire).toFixed(0));
  //
  // state garantie

  const [refdossier, setrefdossier] = useState("");
  const [dureedifere, setdureedifere] = useState("0");
  const [dateeffet, setdateeffet] = useState("");
  const [datemiseplace, setdatemiseplace] = useState("");
  const [montantecheance, setmontantecheance] = useState("");
  const [datepremiere, setdatepremiere] = useState("");
  const [datedernier, setdatedernier] = useState("");
  const [periodicite, setperiodicite] = useState("");

  //
  //
  //
  const constPB = {
    //
    code_agence,
    nom_client,
    prenoms_client,
    DateNai,
    ville,
    telephone,
    profession,
    //
    depistage,
    radiographie,
    electrocard,
    sangurine,
    checkup,
    refuscontrat,
    motifrefus,
    accident,
    accidentetat,
    sportif,
    sporttype,
    sejourH,
    sejourmotif,
    tachedanger,
    tachedesc,
    workstop,
    workstopdesc,
    millitaire,
    workstophealth,
    workstophealthdesc,
    poidsdesc,
    maladieresp,
    maladiecardio,
    maladiediges,
    maladieurin,
    maladienerv,
    maladiepsyc,
    maladiesang,
    maladieando,
    maladios,
    maladiepeau,
    maladieinfec,
    maladiepara,
    maladieorga,
    // defaultconst,
    // dafaultdesc,
    tumeur,
    sida,
    positive,
    autres,
    autresdesc,
    smoke,
    smokeqty,
    alcool,
    drugs,
    drugsdesc,
    enceinte,
    enceintemois,
    regles,
    diagnostic,
    accouche,
    accouchedesc,
    hospnext,
    hospnextdesc,
    intervention,
    intervdesc,
    testdiag,
    traitmedical,
    traitmedidesc,
    mifa,
    mifadesc,
    grossi,
    defaultdesc,
    intervention_chiru,
    traitement_evolution,
  };
  //
  //
  const funcPB = {
    setcheckup,
    setdepistage,
    setradiographie,
    setelectrocard,
    setsangurine,
    setpoid,
    settaille,

    setrefuscontrat,
    setmotifrefus,
    setaccident,
    setaccidentetat,
    setsportif,
    setsporttype,
    setsejourH,
    setsejourdate,
    setsejourmotif,
    settachedanger,
    settachedesc,
    setworkstop,
    setworkstopdesc,
    setmillitaire,
    setworkstophealth,
    setworkstophealthdesc,
    setpoidsdesc,
    setmaladieresp,
    setmaladiecardio,
    setmaladiediges,
    setmaladieurin,
    setmaladienerv,
    setmaladiepsyc,
    setmaladiesang,
    setmaladieando,
    setmaladios,
    setmaladiepeau,
    setmaladieinfec,
    setmaladiepara,
    setmaladieorga,
    setdefaultconst,
    setdefaultdesc,
    settumeur,
    setsida,
    setpositive,
    setautres,
    setautresdesc,
    setsmoke,
    setsmokeqty,
    setalcool,
    setdrugs,
    setdrugsdesc,
    setenceinte,
    setenceintemois,
    setregles,
    setdiagnostic,
    setaccouche,
    setaccouchedesc,
    sethospnext,
    sethospnextdesc,
    setintervention,
    setintervdesc,
    settestdiag,
    settraitmedical,
    settraitmedidesc,
    setmifa,
    setmifadesc,
    setgrossi,
    setintervention_chiru,
    settraitement_evolution,
  };
  //
  //
  //

  const garState = {
    refdossier,
    dureedifere,
    dateeffet,
    datemiseplace,
    montantecheance,
    datepremiere,
    datedernier,
    periodicite,
    dureePret,
  };
  // console.log(garState);
  const garFunc = {
    setrefdossier,
    setdureedifere,
    setdateeffet,
    setdatemiseplace,
    setmontantecheance,
    setdatepremiere,
    setdatedernier,
    setperiodicite,
  };
  //
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
  const envoiPB = montantcapital > maxCap ? true : false;
  const code_etat_contrat = montantcapital > maxCap ? 2 : 0;
  const code_type_qm = montantcapital > maxCap ? "qp" : "qb";

  const simuler =
    montantcapital > 0 &&
    age !== null &&
    dureePret &&
    !envoiPB > 0 &&
    all === true
      ? true
      : false;

  const rejete = age >= 70 || age < 18 ? true : false;
  const isDisable = age >= 70 || age < 18 || !simuler || !formOk ? true : false;
  const simulerDisable = age !== null && dureePret ? false : true;

  const dispatch = useDispatch();
  const tauxPret = useSelector((state) => state.tauxPret);
  const { tauxPrime, success } = tauxPret;
  const contratAdd = useSelector((state) => state.contratAdd);
  const { successAdd, contrat } = contratAdd;

  const t1 = typeoperation === "p" ? "EMPRUNTEUR" : "DECOUVERT";
  const t2 = typecompte === "Employe_banque" ? "PERSPONNEL" : " CLIENT";
  //

  const tauxP = tauxPrime ? tauxPret.tauxPrime.root_taux_pret.taux : 0;
  const tauxCompte = typecompte === "Employe_banque" ?pourcentagepersonnel: pourcentageclient ;
  const afficheTaux = typeoperation === "p" ? tauxP : tauxCompte * 100;
  let duree = parseFloat(dureePret);

  const calculPrime = useCallback(() => {    

    //setall(false);

    var today = new Date();
    var dtn = comptes.length !== 0 ? comptes[0].DATE_NAISSANCE : ""; // on lit la date de naissance
    var an = dtn.substr(6, 4); // l'année (les quatre premiers caractères de la chaîne à partir de 6)
    var mois = dtn.substr(3, 2); // On selectionne le mois de la date de naissance
    var day = dtn.substr(0, 2); // On selectionne la jour de la date de naissance

    var dateNaissance = new Date(an + "-" + mois + "-" + day);

    var age1 = today.getFullYear() - dateNaissance.getFullYear();
    var m = today.getMonth() - dateNaissance.getMonth();

    var ageF =
      m < 0 || (m === 0 && today.getDate() < dateNaissance.getDate())
        ? age1 - 1
        : age1;
    setdate_naissance(dateNaissance);

    let age = ageF === 0 ? null : ageF;
    setAge(age);

    var operationOp = document.getElementById("typeOP");
    if (operationOp.checked) {
      var typeoperation = "p";
    } else {
      var typeoperation = "d";
    }

    var montantcapital = document.getElementById("capital").value;
    setmontantcapital(montantcapital);

    // var comptess = document.getElementById("compte");
    var typeCompte = comptes.length !== 0 ? comptes[0].TYPE_COMPTE : "";

    var dureePret = document.getElementById("dureePret").value;

    settypecompte(typeCompte, dureePret);

    if (age >= 18 && age <= 40) {
      setpourcentageclient(0.52 / 100);
      setpourcentagepersonnel(0.51 / 100);
    } else if (age > 40 && age <= 50) {
      setpourcentageclient(0.88 / 100);
      setpourcentagepersonnel(0.86 / 100);
    } else if (age > 50 && age <= 60) {
      setpourcentageclient(1.41 / 100);
      setpourcentagepersonnel(1.4 / 100);
    } else if (age > 60 && age <= 65) {
      setpourcentageclient(2.25 / 100);
      setpourcentagepersonnel(2.23 / 100);
    } else if (age > 65 && age <= 70) {
      setpourcentageclient(3.41 / 100);
      setpourcentagepersonnel(3.41 / 100);
    } else if (age > 70) {
      setpourcentageclient(0);
      setpourcentagepersonnel(0);
    }

    if (
      (typeoperation === "d" || typeoperation === "p") && typeCompte === "Employe_banque"
    ) {
      var tauxprime = pourcentagepersonnel;
    } else {
      var tauxprime = pourcentageclient;
    }

    /* il n'existe plus de prime de bases

    if (tauxprime * montantcapital < 5000) {
      var prime = 5000;
    } else {
      var prime = tauxprime * montantcapital;
    }*/

    var prime = tauxprime * montantcapital;

    setprime(prime);
    
    if (typeoperation === "d") {
      var primeBrut = prime;
      setprime_brute(primeBrut);
    } 
    else {
      var primeBrut = (montantcapital * tauxP) / 100;
      setprime_brute(primeBrut);
    }

    if(dureePret<=12)
    { 
      setaccessoires(0);
    }
    else
    {
      setaccessoires(1500);
    }
    

    console.log('tauxCompte:'+tauxCompte);

    console.log('tauxPrime:'+tauxP);

    console.log('Durée:'+dureePret);
    
    
    /*
    if (typeoperation === "d") {
      var primeBrut = prime;
      setprime_brute(primeBrut);
    } else if ((montantcapital * tauxP) / 100 < 5000) {
      var primeBrut = 5000;
      setprime_brute(primeBrut);
    } else {
      var primeBrut = (montantcapital * tauxP) / 100;
      setprime_brute(primeBrut);
    }*/

  }, [pourcentageclient, pourcentagepersonnel, tauxP]); 

 
  // console.log(tauxP)

  const onChangeDate = (date, dateString) => {
    calculPrime();
  };

  const changeCapital = (e) => {
    calculPrime();
  };

  const selectCompte = () => {
    calculPrime();

  };
 
  
  

  const setDuree = (e) => {      

    console.log('nda change duree pret :'+e.target.value);
   
    setTimeout(() => {
      setdureePret(e.target.value);  
    }, 1000);
   
    setall(false);
    
  };

  const calculTaux = () => {
    const formData = new FormData();
    // formData.append("age", age);
    formData.append("nbre_mois", duree);
    dispatch(calculPret(formData));
    console.log(formData);
  };
  const openNotificationWithIcon = () => {
    notification.success({
      message: "Ajout contrat",
      description: "Contrat ajouter avec success.",
      style: {
        marginTop: 50,
      },
    });
  };

  const changeOperation = (e) => {
    settypeoperation(e.target.value);
    setTimeout(() => {
      calculPrime();
    }, 1000);
  };
  const numPolice = contrat ? contrat.numero_police : null;

  const compteCredit = 501920201301586;

  const fmSend = {
    poid,
    taille,
    grossi,
    assurance_vie,
    compagnie_assurance,
    maladie_chronique,
    traitement_regulier,
    interruption_travail,
    interruption_observation,
    numero_compte,
    // numero_police: numPolice,
  };

  //
   const fmPBSendData = {
     numero_compte: numero_compte,
     //  numero_police: numPolice,
     nom_agence: code_agence,
     nom_demandeur: nom_client,
     date_naissance: DateNai,
     lieu_naissance: "",
     refus_assurance: refuscontrat,
     motif_refus: motifrefus,
     accident_auto: accident,
     accident_autodesc: accidentetat,
     activite_sportive: sportif,
     observation_sportive: sporttype,
     sejour_hopital: sejourH,
     obs_sejour_h: sejourmotif,
     sang_urine: sangurine,
     exam_electrocardiogramme: electrocard,
     exam_radiographie: radiographie,
     exam_depistage: depistage,
     check_up: checkup,
     danger_particulier: tachedanger,
     obs_danger: tachedesc,
     service_militaire: millitaire,
     workstop,
     workstopdesc,
     workstophealth,
     workstophealthdesc,
     poid,
     taille,
     grossi_maigri: grossi,
     obs_grossi: poidsdesc,
     maladie_respiration: maladieresp,
     maladie_cardio: maladiecardio,
     maladie_diges: maladiediges,
     maladie_urinaire: maladieurin,
     maladie_nerveux: maladienerv,
     maladie_psy: maladiepsyc,
     maladie_sang: maladiesang,
     maladie_endo: maladieando,
     maladie_os: maladios,
     maladie_peau: maladiepeau,
     maladie_infec: maladieinfec,
     maladie_para: maladiepara,
     maladie_organe: maladieorga,
     infirmite: defaultconst,
     // obs_infirmite: dafaultdesc,
     tumeur,
     traitement_sida: sida,
     sero_positif: positive,
     autres_maladie: autres,
     obs_autre_mal: autresdesc,
     fumeur: smoke,
     obs_fumeur: smokeqty,
     alcool,
     drogue: drugs,
     obs_drogue: drugsdesc,
     enceinte,
     affection_regle: regles,
     nbre_mois: enceintemois,
     accouchement: accouche,
     chirugicales: intervention,
     hospi_prochaine: hospnext,
     obs_hospi: hospnextdesc,
     motif_intervention: intervdesc,
     test_hospi: testdiag,
     trait_medo: traitmedical,
     perfusion_piqure: traitmedidesc,
     mal_hereditaire: mifa,
     obs_hereditaire: mifadesc,
     intervention_chiru,
     traitement_evolution,
   };

    const fmSendData = montantcapital > maxCap ? fmPBSendData : fmSend;

  // 
  const contratData = {
    reference_dossier: refdossier,
    duree_differe: dureedifere,
    date_effet_garantie: dateeffet,
    date_mise_en_place: datemiseplace,
    montant_echeance: montantecheance,
    date_premiere_echeance: datepremiere,
    date_derniere_echeance: datedernier,
    type_operation: typeoperation,
    capital: montantcapital,
    //type_compte: typecompte,
    type_compte : typecompte==="Employe_banque" ? "P": "C",
    niu: numeroUnique,
    compte_a_crediter: compteCredit,
    type_client: "particulier",
    periodicite,
    nom_client,
    prenoms_client,
    numero_compte,
    email,
    cni,
    telephone,
    date_naissance,
    prime,
    prime_brute,
    prime_ht,
    accessoires,
    montant_tva,
    prime_ttc,
    profession,
    quote_part_partenaire,
    quote_part_prubli,
    ville,
    adresse_postale,
    code_agence,
    code_banque,
    code_questionnaire,
    duree,
    code_etat_contrat,
    code_type_qm,
    fmSendData,
  };

  /*print data*/
  //personnel agence
  const libelle_agence = userInfo ? userInfo[0].libelle_agence : "";
  const usernom = userInfo ? userInfo.nom : "";
  const userprenom = userInfo ? userInfo.prenoms : "";
  const usermail = userInfo ? userInfo.email : "";

  //client agence
  const nomClient = contrat ? contrat[0].nom_client : "";
  const numeroPolice = contrat ? contrat.numero_police : "";
  const prenomClient = contrat ? contrat.prenoms_client : "";
  const numeroCompte = contrat ? contrat.numero_compte : "";
  const emailClient = contrat ? contrat.email : "";
  const CNI = contrat ? contrat.cni : "";
  const NIU = contrat ? contrat.niu : "";
  const phone = contrat ? contrat.telephone : "";
  const birthD = contrat ? contrat.date_naissance : "";
  const typOp = contrat ? contrat.type_operation : "";
  const Cap = contrat ? contrat.capital : "";
  const typCm = contrat ? contrat.type_compte : "";
  const DUREE = contrat ? contrat.duree : "";
  const PRIME = contrat ? contrat.prime : "";
  const primeBrute = contrat ? contrat.prime_brute : "";
  const primeHT = contrat ? contrat.prime_ht : "";
  const ACCESSOIRES = contrat ? contrat.accessoires : "";
  const montantTVA = contrat ? contrat.montant_tva : "";
  const primeTTC = contrat ? contrat.prime_ttc : "";
  const saveDate = contrat ? contrat.date_enregistrement : "";
  const codeQuestion = contrat ? contrat.code_questionnaire : "";
  const partPart = contrat ? contrat.quote_part_partenaire : "";
  const partPBL = contrat ? contrat.quote_part_prubli : "";
  const Postal = contrat ? contrat.adresse_postale : "";
  const Proff = contrat ? contrat.profession : "";
  const City = contrat ? contrat.ville : "";
  const codeUser = contrat ? contrat.code_utilisateur : "";
  const niu = numeroUnique;
  //
  const printData = {
    nom_client,
    prenoms_client,
    numero_compte,
    email,
    cni,
    niu,
    telephone,
    date_naissance,
    prime,
    prime_brute,
    prime_ht,
    accessoires,
    montant_tva,
    prime_ttc,
    profession,
    quote_part_partenaire,
    quote_part_prubli,
    ville,
    typeoperation,
    montantcapital,
    typecompte,
    //agence
    code_agence,
    libelle_agence,
    usernom,
    userprenom,
    //client
    nomClient,
    numeroPolice,
    printSee,
    adresse_postale,
    DateNai,
    //
    refdossier,
    dureedifere,
    dateeffet,
    datemiseplace,
    montantecheance,
    datepremiere,
    datedernier,
    periodicite,
    dureePret,
  };

  const fmData = {
    poid,
    taille,
    grossi,
    assurance_vie,
    compagnie_assurance,
    maladie_chronique,
    traitement_regulier,
    interruption_travail,
    interruption_observation,
  };
  // console.log(fmData);
  const fmFunc = {
    setpoid,
    settaille,
    setgrossi,
    setassurance_vie,
    setcompagnie_assurance,
    setmaladie_chronique,
    settraitement_regulier,
    setinterruption_travail,
    setinterruption_observation,
  };


  const submitHandler = () => {

    // dispatch(addContrat(fmdata));
    dispatch(addContrat(contratData));

  };
  useEffect(() => {
    if (success) {
      setall(true);
      calculPrime();
      dispatch(clearSuccess());
    }
    if (successAdd) {
      openNotificationWithIcon();
           setprintSee(!printSee);
      // console.log(contrat.numero_police);
      // setnumero_police(contrat.numero_police);
      dispatch(clearSuccess());
      // soumission formulaire medical
      // setTimeout(() => {
      //   console.log(contrat.numero_police);
      //   dispatch(addFm(fmSendData));
      // }, 10000);

      setsaved(true);
   
    }
  }, [dispatch, success, successAdd, calculPrime]);

  const clearState = () => {
    setsaved(false);
  };

  // mail send
  const site = "http://prubli-ma.etech-keys.com";
  const formData = new FormData();
  formData.append(
    "msg",
    `Merci de vous connecter à la plateforme ${site}   pour le suivi du contrat Emprunteur Particulier de M ${nom_client} ${prenoms_client} ,telephone ${telephone}, ville ${ville},  pour un Montant de ${montantcapital} en attente de validation de votre Part !`
  );
  formData.append("from", usermail);
  formData.append("nom_emetteur", usernom);
  formData.append("code_agence", code_agence);
  

  const sendMail = () => {
    Axios({
      method: "post",
      url: "/prubli_ma/ss/send_mail.php",
      data: formData,
      //headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const sendBeneficial = () => {
    Axios({
      method: "post",
      url: "/prubli_ma/ss/save_contrat.php",
      data: contratData,
      //headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        sendMail();
        setsaved(true);
        openNotificationWithIcon();
        setprintSee(!printSee);
        // console.log(contrat.numero_police);
        // console.log(response);
        // setnumero_police(contrat.numero_police);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const simulData = {
    age,
    prime_brute,
    accessoires,
    prime_ht,
    montant_tva,
    prime_ttc,
    quote_part_partenaire,
    quote_part_prubli,
  };
  const { Search } = Input;
  const onSearch = (value) => {
    const accountData = new FormData();
    accountData.append("numero_compte", value);
    accountData.append("type_client", "p");
    accountData.append("type_recherche", "E");
    dispatch(listAccount(accountData));
  };
 const onFinishFailed = (errorInfo: any) => {
   console.log("Failed:", errorInfo);
 };

  const saveHandler = envoiPB ? submitHandler : sendBeneficial;
  return (
    <>
      <h2 className="text-center mb-3">Emprunteur particulier </h2>
      <Col className="mb-3 d-flex" span={24}>
        <div className="col-4">
          <h5>Saisir le numéro du compte</h5>{" "}
        </div>
        <div className="col-5">
          {" "}
          <Search
            placeholder="input search text"
            onSearch={onSearch}
            allowClear
            enterButton
          />
        </div>
      </Col>
      <Card style={{ minHeight: "60vh" }}>
        <div>
          {error ? (
            <h1>{error} </h1>
          ) : (
            <>
              {comptes.length !== 0 && (
                <>
                  <Form onFinish={saveHandler} onFinishFailed={onFinishFailed}>
                    <Row>
                      <Col className="mr-2" span={7}>
                        <Form.Item label="Nom " name="Nom du client">
                          <strong>{comptes[0].LAST_NAME}</strong>
                        </Form.Item>
                        <Form.Item label="Prenom " name="Prenom du client">
                          <strong>{comptes[0].PRENOM}</strong>
                        </Form.Item>
                        <Form.Item label="Email du client">
                          <strong>{comptes[0].EMAIL}</strong>
                        </Form.Item>
                        <Form.Item
                          label="N° Identification Unique"
                          name="unique"
                        >
                          <strong>{comptes[0].CIF}</strong>
                        </Form.Item>
                        <Form.Item
                          label="Date de Naissance"
                          name="Date de Naissance"
                        >
                          <strong>{comptes[0].DATE_NAISSANCE}</strong>
                        </Form.Item>
                        <Form.Item label="Compte">
                          <strong>{comptes[0].TYPE_COMPTE}</strong>
                        </Form.Item>
                        <Form.Item label="Téléphone">
                          <strong>{comptes[0].MOBILE}</strong>
                        </Form.Item>{" "}
                        <Form.Item label="N° du compte" name="compte">
                          <strong>{comptes[0].COMPTE}</strong>
                        </Form.Item>
                        <Form.Item label="N° CNI" name="cni">
                          <strong> {comptes[0].CARTE_ID}</strong>
                        </Form.Item>
                      </Col>

                      {/*  */}
                      <Col className="mr-4 ml-5" span={7}>
                        <Form.Item label="Ville">
                          <strong>{comptes[0].VILLE}</strong>
                        </Form.Item>
                        <Form.Item label="Profession">
                          <strong>{comptes[0].PROFESSION}</strong>
                        </Form.Item>
                        <Form.Item label="Adresse postal">
                          <strong>{comptes[0].ADRESSE_POSTAL}</strong>
                        </Form.Item>
                        <Form.Item
                          label="N° Identification Unique"
                          name="Identification Unique"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: " Entrer le N° Identification Unique ",
                          //   },
                          // ]}
                        >
                          <Input
                            id="idUnique"
                            // max="9999999999"
                            // min="1000000000"
                            value={numeroUnique}
                            type="text"
                            placeholder="N° Identification Unique"
                            onChange={(e) => setnumeroUnique(e.target.value)}
                          />
                        </Form.Item>

                        <Form.Item
                          name="radio-group"
                          label="Type opération"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: " Entrer Type opération",
                          //   },
                          // ]}
                        >
                          <Radio.Group
                            onChange={changeOperation}
                            defaultValue={typeoperation}
                          >
                            <Radio
                              id="typeOP"
                              name="operation"
                              value="p"
                              className="ml-1"
                            >
                              Prêt
                            </Radio>
                            <Radio id="typeOD" name="operation" value="d">
                              Découvert
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item
                          label="Montant du capital"
                          name="Montant du capital"
                          rules={[
                            {
                              required: true,
                              message: " Entrer le montant capital ",
                            },
                          ]}
                        >
                          <Input
                            id="capital"
                            suffix="FCFA"
                            type="number"
                            placeholder="Montant du capital"
                            onChange={changeCapital}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Durée du prêt "
                          name="Durée du prêt"
                          rules={[
                            {
                              required: true,
                              message: " Entrer la durée ",
                            },
                          ]}
                        >
                          <Input
                            id="dureePret"
                            suffix="/Mois"
                            type="number"
                            value={dureePret}
                            onChange={setDuree}
                            placeholder="Durée du prêt en mois"
                          />
                        </Form.Item>
                        <Form.Item label="Compte à crediter">
                          <strong>{compteCredit}</strong>
                        </Form.Item>
                        <div className="d-flex justify-content-between mb-4">
                          <div>
                            {" "}
                            <h5>
                              <Button
                                onClick={calculTaux}
                                disabled={simulerDisable}
                              >
                                Simuler
                              </Button>
                              Taux: {afficheTaux}
                            </h5>
                          </div>
                          <div>
                            <Garant garState={garState} garFunc={garFunc} />{" "}
                          </div>
                          <div>
                            {montantcapital > maxCap && (
                              <FormBen
                                constPB={constPB}
                                funcPB={funcPB}
                                setformOk={setformOk}
                              />
                            )}
                            {montantcapital <= maxCap && (
                              <FormMed
                                fmData={fmData}
                                fmFunc={fmFunc}
                                setformOk={setformOk}
                              />
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col span={8}>
                        <h3 style={{ color: "#e74c3c" }}>
                          {t1} {t2}
                        </h3>
                        {rejete ? (
                          <h4 style={{ color: "red" }}>Dossier rejeter</h4>
                        ) : (
                          <div>
                            {simuler && (
                              <>
                                <Simulateur simulData={simulData} />
                              </>
                            )}
                          </div>
                        )}

                        <Form.Item className="d-flex mt-5">
                          {envoiPB && (
                            <Button
                              type="danger"
                              onClick={sendBeneficial}
                              //htmlType="submit"
                              className="login-form-button mt-5"
                              disabled={
                                age >= 70 ||
                                age < 18 ||
                                formOk === false ||
                                saved
                              }
                            >
                              Envoyer à PBENEFICIAL
                            </Button>
                          )}
                          <div className="d-flex">
                            {!envoiPB && (
                              <Button
                                type="primary"
                                onClick={submitHandler}
                                //htmlType="submit"
                                className="login-form-button"
                                disabled={isDisable || saved}
                              >
                                Enregistrer
                              </Button>
                            )}
                            <div className="ml-5">
                              {" "}
                              {simuler ? <Print printData={printData} /> : null}
                            </div>
                            <div className="ml-5">
                              {" "}
                              {printSee ? (
                                <Button type="primary">
                                  <a href="/mainapp/emprundirect">Rafraichir</a>
                                </Button>
                              ) : null}
                            </div>
                          </div>
                        </Form.Item>
                      </Col>
                      {/* <Print printData={printData} /> */}
                    </Row>
                  </Form>
                </>
              )}
            </>
          )}
        </div>
      </Card>
    </>
  );
}
