import React, { useState, useEffect, useCallback } from "react";
import "./assets/css/style.css";
import {Form, Input, Button, Row, Col, Radio, DatePicker, Card,notification,} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { listAccount } from "./redux/actions/accountActions";
import Axios from "axios"
import PrintPa from "./print/PrintPa"


  // compte Corporate pour test 0201510107887225

  // compte Particulier pour test 0202820100346719

export default function CompteP() {
  const dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];

  const accountList = useSelector((state) => state.accountList);

  const { comptes, error } = accountList;
  const { Search } = Input;
  
  const [type_client, settype_client] = useState("")

  //console.log(comptes[0]);

  const nom_client =(type_client==='C' && comptes.length !== 0) ? comptes[0].NOM_PROMOTEUR : (type_client==='P' && comptes.length !== 0) ? comptes[0].PRENOM : "";

  const prenoms_client = (type_client==='C' && comptes.length !== 0) ? comptes[0].PRENOM_PROMOTEUR : (type_client==='P' && comptes.length !== 0) ? comptes[0].LAST_NAME : "";
  
  //comptes.length !== 0 ? comptes[0].LAST_NAME : "";

  const numero_compte = comptes.length !== 0 ? comptes[0].COMPTE : "";

  const email = comptes.length !== 0 ? comptes[0].EMAIL : "";

  //const cni = comptes.length !== 0 ? comptes[0].CARTE_ID : "";

  const cni = (type_client==='C' && comptes.length !== 0) ? comptes[0].CNI_PROMOTEUR : (type_client==='P' && comptes.length !== 0) ? comptes[0].CARTE_ID : "";

  //const telephone = comptes.length !== 0 ? comptes[0].MOBILE : "";

  const telephone = (type_client==='C' && comptes.length !== 0) ? comptes[0].PHONE : (type_client==='P' && comptes.length !== 0) ? comptes[0].MOBILE : "";
  
  
  // const adresse_postale = comptes.length !== 0 ? comptes[0].ADRESSE_POSTAL : ""; 

  const adresse_postale = (type_client==='C' && comptes.length !== 0) ? comptes[0].ADRESSE_POSTALE : (type_client==='P' && comptes.length !== 0) ? comptes[0].ADRESSE_POSTAL : "";

  const profession = comptes.length !== 0 ? comptes[0].PROFESSION : "";

  const ville = (type_client==='C' && comptes.length !== 0) ? comptes[0].LIEU_NAISS_PROMOTEUR : (type_client==='P' && comptes.length !== 0) ? comptes[0].VILLE : "";
 
  //const ville = comptes.length !== 0 ? comptes[0].VILLE : "";

 // const DateNai = comptes.length !== 0 ? comptes[0].DATE_NAISSANCE : "";

  const DateNai = (type_client==='C' && comptes.length !== 0) ? comptes[0].DATE_NAISS_PROMOTEUR : (type_client==='P' && comptes.length !== 0) ? comptes[0].DATE_NAISSANCE : "";

  const code_agence = userInfo ? userInfo[0].code_agence : "";
  const code_banque = userInfo ? userInfo[0].code_banque : "";
  const libelle_agence = userInfo ? userInfo[0].libelle_agence : "";
  const nom_agent = userInfo ? userInfo.nom : "";

  const [dateeffet, setdateeffet] = useState("");
  const [benef1, setbenef1] = useState("")
  const [pourcentage1, setpourcentage1] = useState("")   
  const [relation1, setrelation1] = useState("")
  const [addressbenef1, setaddressbenef1] = useState("")
  const [addressbenef2, setaddressbenef2] = useState("")
  const [addressbenef3, setaddressbenef3] = useState("")  
  const [benef2, setbenef2] = useState("");
  const [pourcentage2, setpourcentage2] = useState("");
  const [relation2, setrelation2] = useState("");
  const [benef3, setbenef3] = useState("");
  const [pourcentage3, setpourcentage3] = useState(""); 
  const [relation3, setrelation3] = useState("");
  const [option, setoption] = useState("OPTION_I")
  
  const [numeroUnique, setnumeroUnique] = useState("")
  const [nomApporteur, setnomApporteur] = useState("")

    const [printSee, setprintSee] = useState(false);
    const [saved, setsaved] = useState(false);

    const [numero_police, setnumero_police] = useState("");

   

  //const options = option === OPTION_I ? OPTION_I : OPTION_II;

  const [options, setoptions] = useState("");

  useEffect( ()=>{

   
    const fetchData  = async () => {
      
      const OPTION_I = {
        capital_nfc: 250000,
        capital_benef: 250000,
        prime_ttc: 5963,
      };
    
      const OPTION_II = {
          capital_nfc: 500000,
          capital_benef: 500000,
          prime_ttc: 11925,
      };

      const OPTION_III = {
        capital_nfc: 750000,
        capital_benef: 750000,
        prime_ttc: 17888,
      };
    
      const OPTION_IV = {
          capital_nfc: 1000000,
          capital_benef: 1000000,
          prime_ttc: 23850,
      };

      const OPTION_V = {
        capital_nfc: 2500000,
        capital_benef: 2500000,
        prime_ttc: 59625,
      };
    
      const OPTION_VI = {
          capital_nfc: 5000000,
          capital_benef: 5000000,
          prime_ttc: 119250,
      };

      var optionss = option === 'OPTION_I' ? OPTION_I : option === 'OPTION_II' ? OPTION_II : option === 'OPTION_III' ? OPTION_III : option === 'OPTION_IV' ? OPTION_IV : option === 'OPTION_V' ? OPTION_V : option === 'OPTION_VI' ? OPTION_VI:OPTION_VI;
      
           
       
      await setoptions(optionss);

      console.log('options:'+JSON.stringify(optionss));

      console.log('option:'+option);
    }

    fetchData()
    // make sure to catch any error
    .catch(console.error)

    
   
   
  }, [option])

  // useEffect( ()=>{ 
    
  //   // 0201510204671109

  //   console.log('nom_client:'+nom_client);

  //   console.log('prenom_client:'+prenoms_client);

  //   console.log('dateeffet:'+dateeffet); 

  //   //this.nom_client='';
   
  // }, [type_client] 
  // )


  const listBenef = {
    pourcentage1,
    relation1,
    benef1,
    addressbenef1,
    pourcentage2,
    relation2,
    benef2,
    addressbenef2,
    pourcentage3,
    relation3,
    benef3,
    addressbenef3,
  };
  
  const formData = {
    options,
    nom_client,
    prenoms_client,
    numero_compte,
    email,
    cni,
    telephone,
    adresse_postale,
    profession,
    ville,
    numeroUnique,
    DateNai,
    code_agence,
    code_banque,
    libelle_agence,
    nom_agent,
    dateeffet,
    option,
    type_operation: "c",
    listBenef,
    type_client: "parraine",
    nomApporteur,
    numero_police,
  };

  const printData = {
    nom_client,
    prenoms_client,
    numero_compte,
    email,
    cni,
    telephone,
    adresse_postale,
    profession,
    ville,
    numeroUnique,
    DateNai,
    code_agence,
    libelle_agence,
    nom_agent,
    dateeffet,
    pourcentage1,
    relation1,
    addressbenef1,
    addressbenef2,
    addressbenef3,
    benef1,
    pourcentage2,
    relation2,
    benef2,
    pourcentage3,
    relation3,
    benef3,
    option,
    printSee,
    nomApporteur,
    numero_police,
  };

  const openNotificationWithIcon = () => {
    notification.success({
      message: "Ajout contrat",
      description: "Contrat ajouter avec success.",
      style: {
        marginTop: 50,
      },
    });
  };

  const openNotificationWithIconError = () => {
    notification.error({
      message: "Echec Ajout contrat",
      description: "Contrat non Enregistré ; Merci de recommencer.",
      style: {
        marginTop: 50,
      },
    });
  };

 

  const sendForm = () => {
    // console.log(formData);
    Axios({
      method: "post",
      url: "/prubli_ma/ss/save_contrat_cp.php",
      data: formData,
     // headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        openNotificationWithIcon();
        setsaved(true);
        setprintSee(true);      
        
        console.log(JSON.stringify(response, null, 2));
        
        setnumero_police(response['data'][0].numero_police);

      })
      .catch(function (response) {
        
        setsaved(true);

        openNotificationWithIconError();  

        console.log('yes error:'+response);      

        // setTimeout(() => {
        //   window.location.reload(true);
        // }, 5000);

      });
  };

  const onSearch = (value) => {
    const accountData = new FormData();
    accountData.append("numero_compte", value);
    accountData.append("type_client", type_client);
    accountData.append("type_recherche", "C");
    dispatch(listAccount(accountData));

    setdateeffet('0');
    
  };

  return (
    <>
      <h2 className="text-center mb-3">Compte Parrainé </h2>
      <Col className="mb-3 d-flex" span={24}>
        <div className="col-6 d-flex">
        
          <h5>Type De Compte</h5>                    
                  
          <div
            className="col-6 d-flex"
            onChange={(e) => settype_client(e.target.value)}
          >
            <div class="col-6">
              <strong>Corporate</strong>{" "}
              <input
                type="radio"
                id="type_client"
                name="type_client"
                value="C"
              />
            </div>
            <div class="col-6">
              <strong>Particulier</strong>{" "}
              <input
                type="radio"
                id="type_client"
                name="type_client"
                value="P"
              />
            </div>
           
        </div>
        </div>
        <div className="col-3 d-flex">        
        <h5>Numéro De Compte</h5>{" "}
      
        </div>
        <div className="col-3 d-flex">        
          <Search
            placeholder="input search text"
            onSearch={onSearch}
            allowClear
            enterButton
          />
        </div>
      </Col>
     
      <div class="">
      
        <Card style={{ minHeight: "60vh" }}>
          {error ? (
            <h1>{error} </h1>
          ) : (
            <>
              {comptes.length !== 0 && (
                <>
                  {" "}
                  <h5 className="text-center mb-3" style={{color: "red"}}>{comptes.message_contrat_c}</h5>
                  <div class="mb-3">
                    <div class="row">
                      <div class="col-4">
                        Chargé de clientèle: <strong>{nom_agent}</strong>
                      </div>
                      <div class="col-4">
                        Agence: <strong>{libelle_agence}</strong>
                      </div>
                      <div class="col-4">
                        Code:<strong>{code_agence}</strong>{" "}
                      </div>
                      <div class="col-4 italic">Account Manager</div>
                      <div class="col-4 italic">Branch</div>
                    </div>
                  </div>
                  <Row>
                    <Col className="mr-2" span={6}>
                      <Form.Item label="Nom " name="Nom du client">
                        <strong>{nom_client}</strong>
                      </Form.Item>
                      <Form.Item label="Prenom " name="Prenom du client">
                        <strong>{prenoms_client}</strong>
                      </Form.Item>
                      <Form.Item label="Email du client">
                        <strong>{comptes[0].EMAIL}</strong>
                      </Form.Item>
                      <Form.Item label="N° Identification Unique" name="unique">
                        <strong>{comptes[0].CIF}</strong>
                      </Form.Item>
                      <Form.Item
                        label="Date de Naissance"
                        name="Date de Naissance"
                      >
                        <strong>{DateNai}</strong>
                      </Form.Item>
                    </Col>

                    {/*  */}
                    <Col className="mr-4 ml-5" span={7}>
                      {" "}
                      <Form.Item label="Compte">
                        <strong>{comptes[0].TYPE_COMPTE}</strong>
                      </Form.Item>
                      <Form.Item label="Téléphone">
                        <strong>{telephone}</strong>
                      </Form.Item>{" "}
                      <Form.Item label="N° du compte" name="compte">
                        <strong>{comptes[0].COMPTE}</strong>
                      </Form.Item>
                      <Form.Item label="N° CNI" name="cni">
                        <strong> {cni}</strong>
                      </Form.Item>
                      <Form.Item label="Profession">
                        <strong>{comptes[0].PROFESSION}</strong>
                      </Form.Item>
                    </Col>
                    <Col className="mr-4 ml-5" span={6}>
                      {" "}
                      <Form.Item label="Ville">
                        <strong>{ville}</strong>
                      </Form.Item>
                      <Form.Item label="Adresse postal">
                        <strong>{comptes[0].ADRESSE_POSTAL}</strong>
                      </Form.Item>
                      <Form.Item
                        label="N° Identification Unique"
                        name="Identification Unique"
                      >
                        <Input
                          id="idUnique"
                          value={numeroUnique}
                          type="text"
                          placeholder="N° Identification Unique"
                          onChange={(e) => setnumeroUnique(e.target.value)}
                        />
                        </Form.Item>
                        <Form.Item
                        label="Nom Apporteur"
                        name="Nom Apporteur"
                      >
                        <Input
                          id="nomApporteur"
                          value={nomApporteur}
                          type="text"
                          placeholder="Nom Apporteur"
                          onChange={(e) => setnomApporteur(e.target.value)}
                        />

                      </Form.Item>
                    </Col>
                  </Row>
                  <div class="">
                    <div class="row">
                      <div class="col-12 text-center mb-5">
                        <strong>
                          Bénéficiaire(s) en cas de décés ou d’invalidité /
                          <span class="italic_main">
                            {" "}
                            Beneficiaries in event of Death or Invalidity.
                          </span>
                        </strong>
                      </div>
                      <Row>
                        {" "}
                        <Col className="mr-3" span={6}>
                          {" "}
                          <Form.Item
                            label="Nom et Prénoms / Name and Given names"
                            name="Nom et Prénoms"
                            rules={[
                              {
                                required: true,
                                message: " Nom et Prénoms ",
                              },
                            ]}
                          >
                            <Input
                              id="idUnique"
                              type="text"
                              value={benef1}
                              onChange={(e) => setbenef1(e.target.value)}
                              placeholder="Nom et Prénoms / Name and Given names"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col className="mr-3" span={6}>
                          {" "}
                          <Form.Item
                            label=" % de réparation /Portion in %"
                            name="réparation"
                            rules={[
                              {
                                required: true,
                                message: " réparation ",
                              },
                            ]}
                          >
                            <Input
                              id="idUnique"
                              type="number"
                              value={pourcentage1}
                              onChange={(e) => setpourcentage1(e.target.value)}
                              placeholder="% de réparation /Portion in %"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col  className="mr-3" span={5}>
                          {" "}
                          <Form.Item
                            label=" Filiation /Relationship"
                            name="Filiation /Relationship"
                            rules={[
                              {
                                required: true,
                                message: " Filiation /Relationship ",
                              },
                            ]}
                          >
                            <Input
                              id="idUnique"
                              type="text"
                              value={relation1}
                              onChange={(e) => setrelation1(e.target.value)}
                              placeholder="Filiation /Relationship"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col  className="mr-3" span={5}>
                          {" "}
                          <Form.Item
                            label=" Adresse / Address"
                            name="Adresse / Address"
                            rules={[
                              {
                                required: true,
                                message: " Adresse /Address ",
                              },
                            ]}
                          >
                            <Input
                              id="idUnique"
                              type="text"
                              value={addressbenef1}
                              onChange={(e) => setaddressbenef1(e.target.value)}
                              placeholder="Adresse /Address"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}

                        {/*  */}
                        <Col className="mr-3" span={6}>
                          {" "}
                          <Form.Item name="Nom et Prénoms">
                            <Input
                              id="idUnique"
                              type="text"
                              value={benef2}
                              onChange={(e) => setbenef2(e.target.value)}
                              placeholder="Nom et Prénoms / Name and Given names"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col className="mr-3" span={6}>
                          {" "}
                          <Form.Item name="réparation">
                            <Input
                              id="idUnique"
                              type="number"
                              value={pourcentage2}
                              onChange={(e) => setpourcentage2(e.target.value)}
                              placeholder="% de réparation /Portion in %"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col className="mr-3" span={5}>
                          {" "}
                          <Form.Item name="Filiation /Relationship">
                            <Input
                              id="idUnique"
                              type="text"
                              value={relation2}
                              onChange={(e) => setrelation2(e.target.value)}
                              placeholder="Filiation /Relationship"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}

                        <Col className="mr-3" span={5}>
                          {" "}
                          <Form.Item   name="Adresse / Address"                         
                          >
                            <Input
                              id="idUnique"
                              type="text"
                              value={addressbenef2}
                              onChange={(e) => setaddressbenef2(e.target.value)}
                              placeholder="Adresse /Address"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}

                        {/*  */}
                        <Col className="mr-3" span={6}>
                          {" "}
                          <Form.Item name="Nom et Prénoms">
                            <Input
                              id="idUnique"
                              type="text"
                              value={benef3}
                              onChange={(e) => setbenef3(e.target.value)}
                              placeholder="Nom et Prénoms / Name and Given names"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col className="mr-3" span={6}>
                          {" "}
                          <Form.Item name="réparation">
                            <Input
                              id="idUnique"
                              type="number"
                              value={pourcentage3}
                              onChange={(e) => setpourcentage3(e.target.value)}
                              placeholder="% de réparation /Portion in %"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        <Col span={5}>
                          {" "}
                          <Form.Item name="Filiation /Relationship">
                            <Input
                              id="idUnique"
                              type="text"
                              value={relation3}
                              onChange={(e) => setrelation3(e.target.value)}
                              placeholder="Filiation /Relationship"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                        
                        <Col className="mr-3" span={5}>
                          {" "}
                          <Form.Item   name="Adresse / Address"                         
                          >
                            <Input
                              id="idUnique"
                              type="text"
                              value={addressbenef3}
                              onChange={(e) => setaddressbenef3(e.target.value)}
                              placeholder="Adresse /Address"
                            />
                          </Form.Item>{" "}
                        </Col>{" "}
                      </Row>
                    </div>
                  </div>
                  <div class="">
                    <div class="row">
                      <Form.Item
                        label="Date d'effet de la garantie"
                        name="Date de garantie"
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          className=""
                          id="date"
                          onChange={(date, dateString) =>
                            setdateeffet(dateString)
                          }
                          format={dateFormatList}
                        />
                      </Form.Item>{" "}
                    </div>
                  </div>
                  <div class="">
                    <div class="row">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" rowspan="6" class="text-center">
                              GARANTIES/
                              <span class="italic_main">GUARANTIEE </span>
                            </th>
                            <th scope="col" colspan="6" class="text-center">
                              CAPITAUX /{" "}
                              <span class="italic_main">CAPITAL</span>
                            </th>
                          </tr>
                          <tr>
                            <th scope="col" class="text-center">
                              OPTION I
                            </th>
                            <th scope="col" class="text-center">
                              OPTION II
                            </th>
                            <th scope="col" class="text-center">
                              OPTION III
                            </th>
                            <th scope="col" class="text-center">
                              OPTION IV
                            </th>
                            <th scope="col" class="text-center">
                              OPTION  V
                            </th>
                            <th scope="col" class="text-center">
                              OPTION VI
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">
                              Capital a verser sur le compte du client {" "}
                              <strong>NFC BANK</strong> /
                              <span class="italic_main">
                                Benefit Transferred to the client insured
                                account at <strong>NFC BANK</strong>
                              </span>
                            </th>
                            <th>250 000</th>
                      <th>500 000</th>
                      <th>750 000</th>
                      <th>1 000 000</th>
                      <th>2 500 000</th>
                      <th>5 000 000</th> 
                          </tr>
                          <tr>
                            <th scope="row">
                              Capital a verser au(x) bénéficiaire(s) désigné(s)/
                              <span class="italic_main">
                                {" "}
                                Capital paid to beneficiary(ies)
                              </span>
                              <br />
                              N° de téléphone/
                              <span class="italic_main"> telephone number</span>
                            </th>
                            <th>250 000</th>
                      <th>500 000</th>
                      <th>750 000</th>
                      <th>1 000 000</th>
                      <th>2 500 000</th>
                      <th>5 000 000</th>
                          </tr>
                          <tr>
                            <th scope="row">PRIME ANNUELLE TTC (TVA 19,25%)</th>
                            <th>5 963</th>
                            <th>11 925</th>
                            <th>17 888</th>
                            <th>23 850</th>
                            <th>59 625</th>
                            <th>119 250</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      Cocher l’option choisie /{" "}
                      <span class="italic">Check the option Chosen :</span>{" "}
                    </div>
                    <div
                      className="col-1 d-flex"
                      onChange={(e) => setoption(e.target.value)}
                    >
                      <div class="col-6">
                        <strong> I</strong>{" "}
                        <input
                          type="radio"
                          id="assurance"
                          name="assurance"
                          value="OPTION_I"
                          defaultChecked
                        />
                      </div>
                      <div class="col-6">
                        <strong> II</strong>{" "}
                        <input
                          type="radio"
                          id="assurance"
                          name="assurance"
                          value="OPTION_II"
                        />
                      </div>
                      <div class="col-6">
                        <strong> III</strong>{" "}
                        <input
                          type="radio"
                          id="assurance"
                          name="assurance"
                          value="OPTION_III"
                          
                        />
                      </div>
                      <div class="col-6">
                        <strong> IV</strong>{" "}
                        <input
                          type="radio"
                          id="assurance"
                          name="assurance"
                          value="OPTION_IV"
                        />
                      </div>
                      <div class="col-6">
                        <strong>V</strong>{" "}
                        <input
                          type="radio"
                          id="assurance"
                          name="assurance"
                          value="OPTION_V"
                          
                        />
                      </div>
                      <div class="col-6">
                        <strong>VI</strong>{" "}
                        <input
                          type="radio"
                          id="assurance"
                          name="assurance"
                          value="OPTION_VI"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-10"></div>
                    <div className="d-flex col-2 justify-content-around">
                      {" "}
                      {(nom_client !== undefined || prenoms_client !== undefined ) && (dateeffet.length > 5) && 
                        ((nom_client.length+prenoms_client.length) > 5) && (<Button    // ce point ? est pour verifier si la variable existe avant de faire la comparaison 
                        onClick={sendForm}
                        type="primary"
                        className="login-form-button "
                        disabled={saved}
                      >
                        Enregistrer
                      </Button> )}
                      <PrintPa printData={printData} />
                      {/* printData */}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </Card>
      </div>
    </>
  );
}
