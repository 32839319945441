import React, { Component } from "react";
import { connect } from "react-redux";
import { listContrat } from "./redux/actions/ContratActions";
import {Table,Input, Button,Space,Popconfirm,DatePicker,Select,Modal,Spin,Form,} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import Axios from "axios";
import PrintContrat from "./print/PrintContrat";
import SimulateurContrat from "./SimulateurContrat";
import QMedical from "./QMedical";
import UpdateContrat from "./UpdateContrat";

class Reportings extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    startDate: "",
    endDate: "",

    filter: false,
    statut: "",
    filterStatut: false,
    filterdate: false,
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  dateRange = (date, dateStrings, info) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];
    this.setState({
      startDate: startDate,
      endDate: endDate,
      filter: true,
    });
  };
  datefilter = (date, dateStrings, info) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];
    this.setState({
      startDate: startDate,
      endDate: endDate,
      filterdate: true,
    });
  };
  clearDate = () => {
    this.setState({
      filter: false,
      filterStatut: false,
      filterdate: false,
    });
  };
  // fonctions pour selection multiple
  handleChange(record) {
    // console.log(record.code_etat_contrat);
  }

  listCont = () => {
    this.props.listContrats();
  };

  componentDidMount() {
    this.props.listContrats();
  }

  statutChange = (value) => {
    this.setState({
      statut: value,
      filterStatut: true,
    });
    //  setTimeout(() => {
    //      console.log(this.state.statut);
    //  }, 100);
  };

  downloadTxtFile = (exportData) => {
    const element = document.createElement("a");
    const file = new Blob( exportData, {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element);
    element.click();
  };

  render() {
    const { contrats, loading } = this.props.listContrat;
    const { userInfo } = this.props.userSignin;
    const { Option } = Select;
    const data = contrats
      ? contrats.root_liste_contrat
        ? contrats.root_liste_contrat
        : []
      : [];
      const beneInfo = contrats
      ? contrats.root_ayant_droit
        ? contrats.root_ayant_droit
        : []
      : [];


    console.log(localStorage.getItem('choixProduit'));

    const dataxx = data.filter((value) => {
      if (['2', '3', '4'].includes(userInfo.code_type_utilisateur)) {
        return value.code_agence === userInfo[0].code_agence;
      }
      else {
        return value.code_agence;
      }
  
    });

    const datax = dataxx.filter((value) => {

      if(localStorage.getItem('choixProduit')!=='all')
      {
        return value.type_client === localStorage.getItem('choixProduit'); 
      }
      else
      {
        return value.type_client;
      }
      
    });

    let libelleResult = datax.filter((value) => {
      return value.libelle_etat_contrat === this.state.statut;
    });

    let DateLibelle = libelleResult.filter((obj) => {
      return (
        obj.date_enregistrement.substr(0, 10) >= this.state.startDate &&
        obj.date_enregistrement.substr(0, 10) <= this.state.endDate
      );
    });

    const dataStatut = this.state.filterStatut ? libelleResult : datax;

    const libelleDate = this.state.filterdate ? DateLibelle : dataStatut;

    // console.log(libelleDate);

    // console.log(dataStatut);

    let DateResult = datax.filter((obj) => {
      return (
        obj.date_enregistrement.substr(0, 10) >= this.state.startDate &&
        obj.date_enregistrement.substr(0, 10) <= this.state.endDate
      );
    });

    const resultData = this.state.filter ? DateResult : datax;
    const headers = [
      { label: "Nom Client", key: "nom_client" },
      { label: "Type client", key: "type_client" },
      { label: "Type operation", key: "type_operation" },
      { label: "Capital", key: "capital" },
      { label: "Numéro du compte", key: "numero_compte" },
      { label: "Numéro CNI", key: "cni" },
      { label: "Numéro ID unique", key: "niu" },
      { label: "Numéro police", key: "numero_police" },
      { label: "Surprime", key: "surprime" },
      { label: "Accessoires", key: "accessoires" },
      { label: "TVA", key: "montant_tva" },
      { label: "Prime de base", key: "prime_brute" },
      { label: "Total premium", key: "prime_ttc" },
      { label: "Part NFC", key: "quote_part_partenaire" },
      { label: "Part PBLI", key: "quote_part_prubli" },
      { label: "Compte PBLI", key: "compte_a_crediter" },
    ];

    const exportData = this.state.filterStatut ? libelleDate : resultData;

    const csvReport = {
      data: exportData,
      headers: headers,
      filename: "Rapport.csv",
    };
    const columns = [
      {
        title: "Nom Client",
        dataIndex: "nom_client",
        key: "nom_client",
        width: "12%",
        ...this.getColumnSearchProps("nom_client"),
      },

      {
        title: "N° police",
        dataIndex: "numero_police",
        key: "numero_police",
        width: "12%",
        ...this.getColumnSearchProps("numero_police"),
      },
      {
        title: "Capital",
        dataIndex: "capital",
        key: "capital",
        ...this.getColumnSearchProps("capital"),
      },
      {
        title: "Numéro du compte",
        dataIndex: "numero_compte",
        key: "numero_compte",
        ...this.getColumnSearchProps("numero_compte"),
      },
      {
        title: "Type client",
        dataIndex: "type_client",
        key: "type_client",
        ...this.getColumnSearchProps("type_client"),
      },
      {
        title: "Date création",
        dataIndex: "date_enregistrement",
        key: "date_enregistrement",
        sorter: (a, b) =>
          Date.parse(a.date_enregistrement) - Date.parse(b.date_enregistrement),
      },
      {
        title: "Statut",
        key: "statut",
        width: "6%",
        render: (record) => (
          <>
            <span
              className={
                record.libelle_etat_contrat === "Validé"
                  ? "badge badge-success"
                  : record.libelle_etat_contrat === "En Attente"
                  ? "badge badge-warning"
                  : record.libelle_etat_contrat === "En Attente PruBli"
                  ? "badge badge-info "
                  : "badge badge-danger"
              }
            >
              {" "}
              {record.libelle_etat_contrat}
            </span>
          </>
        ),
      },

      {
        title: "Action",
        fixed: "right",
        key: "action",
        width: "6%",
        render: (text, record) =>{
          console.log('****************************************')
          console.log(record, text)
          return (
          <div className="d-flex">
            <PrintContrat contrats={datax} beneInfo={beneInfo} id={record.id} userInfo={userInfo} />
            {(userInfo
              ? (userInfo.code_type_utilisateur === "5" &&
                  record.libelle_etat_contrat !== "En Attente PruBli") ||
                (userInfo.code_type_utilisateur === "6" &&
                  record.libelle_etat_contrat === "En Attente PruBli")
              : null) &&
              !loading && (
                <ChangeEtat
                  userInfo={userInfo}
                  record={record}
                  listContrat={this.listCont}
                />
              )}

            <QMedical record={record} />
          </div>
        )},
      },
    ];
    const { RangePicker } = DatePicker;
    console.log('**************************************')
  console.log(exportData)

    return (
      <>
        {/* <button
          onClick={() => this.downloadTxtFile(JSON.parse(exportData))}
        >
          Download
        </button> */}
        <h2 className="mb-3">Liste Des Contrats : {localStorage.getItem('libelleChoixProduit')} </h2> 
        {}
        <div className="d-flex justify-content-between">
          {" "}
          <div className="col-12">
            {" "}
            <strong>Trier par date: </strong>
            <RangePicker
              onChange={this.dateRange}
              disabled={this.state.filterStatut}
              className="mx-3"
            />
            <strong>Trier par statut: </strong>
            <Select
              // className="mx-3"
              defaultValue=""
              style={{ width: 145 }}
              // allowClear
              onChange={this.statutChange}
              disabled={this.state.filter}
            >
              <Option value="En Attente">En Attente</Option>
              <Option value="En Attente PruBli">En Attente PruBli</Option>
              <Option value="Validé">Validé</Option>
              <Option value="rejeté">Rejeté</Option>
              <Option value="Annulé">Annulé</Option>
            </Select>
            {this.state.filterStatut && (
              <RangePicker
                onChange={this.datefilter}
                disabled={this.state.filter}
                // className="mx-3"
              />
            )}{" "}
            <Button type="primary" onClick={this.clearDate}>
              Vider le filtre
            </Button>
          </div>
        </div>
        <div className="mt-5 mb-1 d-flex justify-content-between">
          {" "}
          <div>
            <Button type="primary">
              <CSVLink {...csvReport}>Export to CSV</CSVLink>
            </Button>
          </div>
          <div>
            {" "}
            <UpdateContrat />
          </div>
        </div>
        <Table
          rowKey={(record) => record.id}
          loading={loading}
          columns={columns}
          dataSource={exportData}
          // className="mt-5"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listContrat: state.listContrat,
    userSignin: state.userSignin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listContrats: () => dispatch(listContrat()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reportings);

//
//
const ChangeEtat = ({ record, listContrat, userInfo }) => {
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [etat, setetat] = React.useState();
  const [supprime, setsupprime] = React.useState(0);
  const [data, setData] = React.useState(null);

  // primeHorsTax = record.prime_brute + accessoires1 - supprime;
  // primeToutTax = record.montant_tva + primeHorsTax;

  const surprime = supprime===""? 0: parseFloat(supprime);
  const primeHtf = parseFloat(record.prime_ht) + surprime;
  const tvaF = primeHtf * 0.1925;
  const primeTtcf = primeHtf + tvaF;

  const simul = { surprime, primeHtf, tvaF, primeTtcf };

  const showModal = () => {
    setVisible(true);
  };
  const { Option } = Select;
  const etatData = new FormData();
  etatData.append("numero_police", record.numero_police);
  etatData.append("code_etat_contrat", etat);
  etatData.append("surprime", surprime);
  etatData.append("prime_ht", primeHtf);
  etatData.append("montant_tva", tvaF);
  etatData.append("prime_ttc", primeTtcf);

  const sendEtat = () => {
    Axios({
      method: "post",
      url: "/prubli_ma/ss/validation.php",
      data: etatData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        // console.log(response);
        // console.table(response.data)
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const handleOk = () => {
    setConfirmLoading(true);
    sendEtat();
    setTimeout(() => {
      listContrat();
      setVisible(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    // console.log("Clicked cancel button");
    setVisible(false);
  };

  // console.log(etat);

  return (
    <>
      <Button
        style={{
          backgroundColor: "orange",
          color: "white",
        }}
        shape="circle"
        size="small"
        onClick={showModal}
      >
        {" "}
      V
      </Button>
      <Modal
        width={800}
        title="Changer l'état du contrat"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div className="d-flex">
          <div className="col-6">
            {" "}
            <p>Numéro Police: {record.numero_police} </p>
            <p>Code utilisateur: {record.code_utilisateur} </p>

            {userInfo.code_type_utilisateur === "6" && (
              <Form.Item name={["user", "supprime"]} label="Supprime">
              <Input
                value={supprime}
                onChange={(e) => setsupprime(e.target.value)}
                type="number"
                placeholder="supprime"
              />
            </Form.Item>
            )}
            
            <Form.Item name={["user", "statut"]} label="Statut">
              <Select
                defaultValue={record.libelle_etat_contrat}
                style={{ width: 200 }}
                onChange={(value) => setetat(value)}
              >
                <Option value={1}>Validé</Option>
                <Option value={0}>En attente</Option>
                <Option value={2}>En attente PruBli</Option>
                <Option value={-1}>Rejeté</Option>
                <Option value={-2}>Annulé</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-6">
            <SimulateurContrat simul={simul} record={record} />
          </div>
        </div>
      </Modal>
    </>
  );
};
