import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import { Modal, Button } from "antd";
import ContratDetails from "../ContratDetails";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";

export default class PrintContrat extends Component { 
  state = {
    isModalVisible: false,
  };
  showModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  render() {
    const { contrats, id, userInfo,beneInfo } = this.props;

    return (
      <div>
        <Button
          style={{
            backgroundColor: "#1B9CFC",
            color: "white",
          }}
          shape="circle"
          size="small"
          onClick={this.showModal}
        >
          <EyeOutlined />
        </Button>
        <Modal

          width={1200}
          title="Contrat"
          visible={this.state.isModalVisible}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Fermer
            </Button>,
            <ReactToPrint
              trigger={() => (
                <Button key="submit" type="primary">
                  Imprimer-
                </Button>
              )}
              content={() => this.componentRef}
            />,
          ]}
        >
          <ContratDetails
            ref={(el) => (this.componentRef = el)}
            contrats={contrats}
            beneInfo={beneInfo}
            id={id}
            userInfo={userInfo}
          />
        </Modal>
      </div>
    );
  }
}
