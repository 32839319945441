export const PRIMEP_LIST_REQUEST = "PRIMEP_LIST_REQUEST";
export const PRIMEP_LIST_SUCCESS = "PRIMEP_LIST_SUCCESS";
export const PRIMEP_LIST_FAIL = "PRIMEP_LIST_FAIL";

export const PRIMEP_ADD_REQUEST = "PRIMEP_ADD_REQUEST";
export const PRIMEP_ADD_SUCCESS = "PRIMEP_ADD_SUCCESS";
export const PRIMEP_ADD_FAIL = "PRIMEP_ADD_FAIL";


export const PRIMEP_UPDATE_REQUEST = "PRIMEP_UPDATE_REQUEST";
export const PRIMEP_UPDATE_SUCCESS = "PRIMEP_UPDATE_SUCCESS";
export const PRIMEP_UPDATE_FAIL = "PRIMEP_UPDATE_FAIL";

export const PRIMEP_DELETE_REQUEST = "PRIMEP_DELETE_REQUEST";
export const PRIMEP_DELETE_SUCCESS = "PRIMEP_DELETE_SUCCESS";
export const PRIMEP_DELETE_FAIL = "PRIMEP_DELETE_FAIL";


export const PRIMED_LIST_REQUEST = "PRIMED_LIST_REQUEST";
export const PRIMED_LIST_SUCCESS = "PRIMED_LIST_SUCCESS";
export const PRIMED_LIST_FAIL = "PRIMED_LIST_FAIL";

export const PRIMED_UPDATE_REQUEST = "PRIMED_UPDATE_REQUEST";
export const PRIMED_UPDATE_SUCCESS = "PRIMED_UPDATE_SUCCESS";
export const PRIMED_UPDATE_FAIL = "PRIMED_UPDATE_FAIL";

export const PRIMED_DELETE_REQUEST = "PRIMED_DELETE_REQUEST";
export const PRIMED_DELETE_SUCCESS = "PRIMED_DELETE_SUCCESS";
export const PRIMED_DELETE_FAIL = "PRIMED_DELETE_FAIL";

export const PRIMED_ADD_REQUEST = "PRIMED_ADD_REQUEST";
export const PRIMED_ADD_SUCCESS = "PRIMED_ADD_SUCCESS";
export const PRIMED_ADD_FAIL = "PRIMED_ADD_FAIL";

