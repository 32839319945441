import React from "react";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import "./styles/header.css";
import { Avatar, Popover, Input } from "antd";
import { UserOutlined, AudioOutlined } from "@ant-design/icons";
import { logout } from "../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import ChangePwd from "../ChangePwd";
import nfc from "../assets/images/nfc.png";

export default function Header({
  collapsed,
  handleCollapsedChange,
  handleToggleSidebar,
}) {
  const { Search } = Input;
  const suffix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1890ff",
      }}
    />
  );
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;
  const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  var token = getCookie("token");
  const tokenData = new FormData();
  tokenData.append("token", token);

  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout(tokenData));
  };

  const text = <span>{userInfo ? userInfo.email : null} </span>;
  const content = (
    <div>
      <ChangePwd />
      <p style={{ cursor: "pointer" }} onClick={logoutHandler}>
        {" "}
        <i className="mr-3 fa fa-sign-out"></i> Déconnexion
      </p>
    </div>
  );
  return (
    <>
      <header>
        <div className="d-flex">
          <div className="col-6 d-flex">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: handleCollapsedChange,
              }
            )}
            {React.createElement(MenuUnfoldOutlined, {
              className: "trigger1",
              onClick: () => handleToggleSidebar(true),
            })}
          </div>
          <div className="col-6" >
            <div className="d-flex">
        
              <div className="col-12 d-flex justify-content-between">
                <h4 style={{ color: "black", marginTop: 25, marginRight:10 }}>
                  {" "}
                  {userInfo ? userInfo.email : null}{" "}
                </h4>
                <div style={{ marginTop:7 }} >
                  <Popover
                    placement="bottom"
                    title={text}
                    content={content}
                    trigger="hover"
                  >
                    <Avatar
                      size={{ xs: 24, sm: 32, md: 40, lg: 50, xl: 54, xxl: 58 }}
                      icon={<UserOutlined />}
                    />
                  </Popover>
                </div>

                <div>
                  {" "}
                  <img
                    // className="col-4"
                    src={nfc}
                    style={{ height: 70, width: "100%", marginLeft:25 }}
                    alt="nfc"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        ,
      </header>
    </>
  );
}
