import React, { useState } from "react";
import "./styles/App.scss";
import Aside from "./Aside";
import Footer from "./Footer";
import Header from "./Header";
import {Card,} from "antd";

import {BrowserRouter as Router,Switch,Route,Redirect} from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import PrimePret from "../PrimePret";
import PrimeDecouvert from "../PrimeDecouvert";
import Reporting from "../Reporting";
import ReportingNews from "../ReportingNew";
import EmpruntCoop from "../EmpruntCoop";
import CreerCompte from "../CreerCompte";
import ResetPassword from "../ResetPassword";
import ExportData from "../ExportData";
import ImportData from "../ImportData";
import DeleteData from "../DeleteData";
import ValidContrat from "../ValidContrat";
import ProtectedRoute from "../routes/ProtectedRoute"
import { useSelector } from "react-redux";
import Comptes from "../Comptes";
import Dashboard from "../Dashboard"
import EmpruntDirect from "../EmpruntDirect";
import AgenceList from "../AgenceList"
import GesUser from "../GesUser";
import CompteP from "../CompteP";
import SafeCard from "../SafeCard";
import RetraiteC from "../RetraiteC";
import FraisF from "../FraisF";

function MainApps(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(false);
  const [toggled, setToggled] = useState(false);
  const rtl = false;
  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };
  const { url } = props.match;

  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  return (
    <Router>
      <>
        <div className={`app ${rtl ? "rtl" : ""} ${toggled ? "toggled" : ""}`}>
          <Aside
            image={image}
            collapsed={collapsed}
            rtl={rtl}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            handleImageChange={handleImageChange}
          />

          <main>
            <Header
              collapsed={collapsed}
              handleCollapsedChange={handleCollapsedChange}
              handleToggleSidebar={handleToggleSidebar}
            />

            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              className="contenu"
              style={{ width: "98%" }}
            >
              {/* <Card style={{ minHeight: "86vh" }}> */}
              <Switch>
                <Redirect exact from={`${url}`} to={`${url}/dashboard`} />
                <Route path={`${url}/dashboard`} component={Dashboard} />
                <Route
                  path={`${url}/empruncoorporate`}
                  component={EmpruntCoop}
                />
                <Route path={`${url}/emprundirect`} component={EmpruntDirect} />
                <Route path={`${url}/compteparraine`} component={CompteP} />
                <Route path={`${url}/safecard`} component={SafeCard} />
                <Route path={`${url}/retraitecomplementaire`} component={RetraiteC} />
                <Route path={`${url}/fraisfuneraires`} component={FraisF} />
                <Route path={`${url}/primepret`} component={PrimePret} />
                <Route
                  path={`${url}/primedecouvert`}
                  component={PrimeDecouvert}
                />
                <Route path={`${url}/creercompte`} component={CreerCompte} />
                <Route path={`${url}/comptes`} component={Comptes} />
                <Route path={`${url}/resetpassword`} component={ResetPassword} />
                <Route path={`${url}/exportdata`} component={ExportData} />
                <Route path={`${url}/deletedata`} component={DeleteData} />

                <Route path={`${url}/reporting`} component={Reporting} />
                <Route path={`${url}/reportingnew`} component={ReportingNews} />
               
                <Route path={`${url}/agence`} component={AgenceList} />
                <Route path={`${url}/users`} component={GesUser} />
               
               
                <Route path={`${url}/importdata`} component={ImportData} />
                <Route path={`${url}/validContrat`} component={ValidContrat} />
               
              </Switch>
              {/* </Card> */}
            </Scrollbars>

            <Footer />
          </main>
        </div>
      </>
    </Router>
  );
}

export default MainApps;
