import {
  PRIMEP_LIST_REQUEST,
  PRIMEP_LIST_SUCCESS,
  PRIMEP_LIST_FAIL,
  PRIMEP_UPDATE_REQUEST,
  PRIMEP_UPDATE_SUCCESS,
  PRIMEP_ADD_FAIL,
  PRIMEP_ADD_REQUEST,
  PRIMEP_ADD_SUCCESS,
  PRIMED_ADD_FAIL,
  PRIMED_ADD_REQUEST,
  PRIMED_ADD_SUCCESS,
  PRIMEP_DELETE_FAIL,
  PRIMEP_DELETE_REQUEST,
  PRIMEP_DELETE_SUCCESS,
  PRIMEP_UPDATE_FAIL,
  PRIMED_DELETE_FAIL,
  PRIMED_DELETE_REQUEST,
  PRIMED_DELETE_SUCCESS,
  PRIMED_LIST_REQUEST,
  PRIMED_LIST_SUCCESS,
  PRIMED_LIST_FAIL,
  PRIMED_UPDATE_REQUEST,
  PRIMED_UPDATE_SUCCESS,
  PRIMED_UPDATE_FAIL,
} from "../constants/tauxPrimeConst";
import Axios from "axios";

export const addPrimePret= (contratData) => async (dispatch) => {
  dispatch({
    type: PRIMEP_ADD_REQUEST,
  });

  try {
    const { data } = await Axios.post(
      "/prubli_ma/ss/ajout_tarif_pret.php",
      contratData
    );
    dispatch({ type: PRIMEP_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRIMEP_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updatePrimePret = ( contratData ,id) => async (dispatch) => {
  try {
    dispatch({
      type: PRIMEP_UPDATE_REQUEST,
    });
    const { data } = await Axios.post(
      `/prubli_ma/ss/maj_tarif_pret.php?id=${id}`,
      contratData
    );
    dispatch({ type: PRIMEP_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRIMEP_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//
export const updatePrimeDec = (contratData) => async (dispatch) => {
  try {
    dispatch({
      type: PRIMED_UPDATE_REQUEST,
    });
    const { data } = await Axios.post(
      "/prubli_ma/ss/maj_tarif_decouvert.php",
      contratData
    );
    dispatch({ type: PRIMED_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRIMED_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// 
export const deletePrimePret = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: PRIMEP_DELETE_REQUEST,
    });
    const { data } = await Axios.post(
      "/prubli_ma/ss/supprimer_tarif_pret.php",
      formData
    );
    dispatch({
      type: PRIMEP_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRIMEP_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
//
export const deletePrimeDec = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: PRIMED_DELETE_REQUEST,
    });
    const { data } = await Axios.post(
      "/prubli_ma/ss/supprimer_tarif_decouvert.php",
      formData
    );
    dispatch({
      type: PRIMED_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRIMED_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listPrimePret = () => async (dispatch) => {
  try {
    dispatch({
      type: PRIMEP_LIST_REQUEST,
    });

    const { data } = await Axios.get("/prubli_ma/ss/tarif_pret.php");
    dispatch({ type: PRIMEP_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRIMEP_LIST_FAIL, payload: error.message });
  }
};
//
//
export const listPrimeDec = () => async (dispatch) => {
  try {
    dispatch({
      type: PRIMED_LIST_REQUEST,
    });

    const { data } = await Axios.get("/prubli_ma/ss/tarif_decouvert.php");
    dispatch({ type: PRIMED_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRIMED_LIST_FAIL, payload: error.message });
  }
};

export const addPrimeDec = (contratData) => async (dispatch) => {
  dispatch({
    type: PRIMED_ADD_REQUEST,
  });

  try {
    const { data } = await Axios.post(
      "/prubli_ma/ss/ajout_tarif_decouvert.php",
      contratData
    );
    dispatch({ type: PRIMED_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRIMED_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
