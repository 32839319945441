

import {
  ACCOUNT_LIST_REQUEST,
  ACCOUNT_LIST_SUCCESS,
  ACCOUNT_LIST_NO_CONTENT,
  ACCOUNT_LIST_FAIL,
} from "../constants/accountConst";
import Axios from "axios";

export const listAccount = (accountData) => async (dispatch) => {
  try {
    dispatch({
      type: ACCOUNT_LIST_REQUEST,
    });
    const res = await Axios.post(
      "/prubli_ma/ss/recherche_client.php",
      accountData
    );
    console.log('bychrisme:',res);
    if(res.status===204)
    {
      dispatch({ type: ACCOUNT_LIST_NO_CONTENT, payload: res.statusText});
    }
    else
    {
      dispatch({ type: ACCOUNT_LIST_SUCCESS, payload: res.data });
    }    
  } catch (error) {
      dispatch({ type: ACCOUNT_LIST_FAIL, payload: 'Aucune Donnée' });
  }
};

