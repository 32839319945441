
import {
  AGENCE_LIST_REQUEST,
  AGENCE_LIST_SUCCESS,
  AGENCE_LIST_FAIL,
  AGENCE_ADD_REQUEST,
  AGENCE_ADD_SUCCESS,
  AGENCE_ADD_FAIL,
} from "../constants/agenceConst";


export const agenceListReducer = (
  state = { loading: true, agence: [] },
  action
) => {
  switch (action.type) {
    case AGENCE_LIST_REQUEST:
      return { loading: true, agence: [] };
    case AGENCE_LIST_SUCCESS:
      return {
        loading: false,
        agence: action.payload,
      };
    case AGENCE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const agenceAddReducer = (state = {}, action) => {
  switch (action.type) {
    case AGENCE_ADD_REQUEST:
      return { loading: true };
    case AGENCE_ADD_SUCCESS:
      return {
        loading: false,
        // successAdd: true,
        agence: action.payload,
      };
    // case CLEAR_SUCCESS:
    //   return {
    //     ...state,
    //     successAdd: false,
    //   };
    case AGENCE_ADD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
