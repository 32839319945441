import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  listPrimePret,
  deletePrimePret,
} from "./redux/actions/tauxPrimeActions";
import { Table, Button, Popconfirm, Spin } from "antd";
import PrimePretEdit from "./PrimePretEdit";
import AjoutPrimePret from "./AjoutPrimePret";

export default function PrimePret() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listPrimePret());
  }, [dispatch]);

  const primePret = useSelector((state) => state.primePret);
  const { primeP, loading } = primePret;
      const userSignin = useSelector((state) => state.userSignin);
      const { userInfo } = userSignin;


  const handleDelete = (record) => {
    const id = record.id;
    const formData = new FormData();
    formData.append("id", id);
    dispatch(deletePrimePret(formData));
    setTimeout(() => {
      dispatch(listPrimePret());
    }, 200);
  };
  const handleCancel = () => {
    console.log("Clicked cancel button");
  };

  const columns = [
    {
      title: "1 an",
      dataIndex: "annee1",
    },
    {
      title: "2 ans",
      dataIndex: "annee2",
    },
    {
      title: "3 ans",
      dataIndex: "annee3",
    },
    {
      title: "4 ans",
      dataIndex: "annee4",
    },
    {
      title: "5 ans",
      dataIndex: "annee5",
    },
    {
      title: "6 ans",
      dataIndex: "annee6",
    },
    {
      title: "7 ans",
      dataIndex: "annee7",
    },
    {
      title: "8 ans",
      dataIndex: "annee8",
    },
   
    {
      title: "Action",
      fixed: "right",
      key: "action",
      width: "11%",
      render: (text, record) => (
        <span>
          {(userInfo
            ? userInfo.code_type_utilisateur === "0" 
            : null) &&
            (
              <>
                <PrimePretEdit primeP={primeP} primeId={record.id} />

                <Popconfirm
                  title="Estes vous sur de vouloir supprimer?"
                  onConfirm={() => handleDelete(record)}
                  onCancel={handleCancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger" shape="circle" size="small">
                    D
                  </Button>
                </Popconfirm>
              </>
            )}
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }
  return (
    <>
      <h1>Prime Prêt </h1>
      {(userInfo ? userInfo.code_type_utilisateur === "0" : null) && (
        <div className="d-flex justify-content-between">
          {" "}
          <AjoutPrimePret />{" "}
        </div>
      )}
      <div>
        <Spin size="large" tip="Chargement..." spinning={loading}>
          {primeP.root_pret ? (
            <Table
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={primeP.root_pret}
              onChange={onChange}
            />
          ) : null}
        </Spin>{" "}
      </div>{" "}
    </>
  );
}
