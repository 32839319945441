import React from 'react';
import {ProSidebar, Menu, MenuItem,  SubMenu,  SidebarHeader,  SidebarFooter,  SidebarContent,} from 'react-pro-sidebar';
import "./styles/aside.css"
import { Link,} from "react-router-dom";
import sidebarBg from './assets/bg1.jpg';
import { Switch } from "antd";
import { useSelector } from "react-redux";
import nfc from "../assets/images/nfc.png";
import logo from "../assets/pb_insurance.png";    // 837*298 px
import picture from "../assets/picture.jpeg";    // 837*298 px

function choixProduit(name,libelleProduit) {
  localStorage.setItem('choixProduit',name);
  localStorage.setItem('libelleChoixProduit',libelleProduit);
}

const Aside = ({
  image,
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar,
  handleImageChange,
}) => {
    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo,loading } = userSignin;

    const codeRole = userInfo ? userInfo.code_type_utilisateur : null;
    const role =
      codeRole === "0"
        ? "Super Admin"
        : codeRole === "1"
        ? "Administrateur"
        : codeRole === "2"
        ? "Chef agence"
        : codeRole === "3"
        ? "Utilisateur"
        : codeRole === "5"
        ? "Gestionnaire Credit"
        : codeRole === "6"
        ? "Gestionnaire PBli"
        : codeRole === "7"
        ? "3C S"
        : "Autre Personnel"; 
  return (
    <ProSidebar
      image={image ? sidebarBg : false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
      style={{ zIndex: 10 }}
    >
      <SidebarHeader>
        <div
          style={{
            // padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <img src={logo} style={{ height: 80, width: "100%" }} alt="nfc" />
        </div>
        <div className="sidebar-header">
          <div className="user-pic">
            <img
              className="img-responsive img-rounded"
              src={picture}
              alt="User Picture"
            />
          </div>
          <div className="user-info">
            <span className="user-name">
              <strong>{userInfo ? userInfo[0].code_agence : null}</strong>
            </span>
            <span className="user-role"> {userInfo ? userInfo.nom : null}</span>
            <span className="user-status">
              {/* <i className="fa fa-circle"></i> */}
              <strong>{role}</strong>
            </span>
          </div>
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <span className=" ml-2">Accueil</span>

          <MenuItem
            className="mt-3"
            icon={<i class="fa fa-tachometer" aria-hidden="true"></i>}
            // suffix={<span className="badge red">new</span>}
          >
            Dashboard
            <Link to="/mainapp/dashboard" />
          </MenuItem>
          <MenuItem icon={<i className="fa fa-line-chart"></i>}>
            {" "}
            Transaction
            <Link to="/mainapp/reporting" onClick={() => choixProduit('all','Tous')}/>
          </MenuItem>
          <MenuItem icon={<i className="fa fa-pie-chart"></i>}>
            {" "}
            Reporting
            <Link to="/mainapp/reportingnew" />
          </MenuItem>
          {(userInfo
            ? userInfo.code_type_utilisateur === "3" 
            // || userInfo.code_type_utilisateur === "2"
            : null) &&
            !loading && (
              <>
                <span className=" ml-2">Produits</span>
                <MenuItem
                  icon={<i className="app-menu__icon fa fa-university"></i>}
                  // suffix={<span className="badge yellow">3</span>}
                >
                  {" "}
                  Emprunteur corporate
                  <Link to="/mainapp/empruncoorporate" />
                </MenuItem>
                <MenuItem
                  icon={<i className="fa fa-money"></i>}
                  className="mb-3"
                >
                  {" "}
                  Emprunteur particulier
                  <Link to="/mainapp/emprundirect" />
                </MenuItem>
                </>
            )}
            {(userInfo 
              ? userInfo.code_type_utilisateur === "3" 
              // ||  userInfo.code_type_utilisateur === "2"
              ||  userInfo.code_type_utilisateur === "7"
              : null) &&
              !loading && (
                <>  
                <MenuItem
                  icon={<i class="fa fa-user" aria-hidden="true"></i>}
                  className="mt-3"
                >
                  {" "}
                  Compte parrainé
                  <Link to="/mainapp/compteparraine" />
                </MenuItem>
                <MenuItem
                  icon={<i class="fa fa-credit-card" aria-hidden="true"></i>}
                  className="mt-3"
                >
                  {" "}
                  Safe Card
                  <Link to="/mainapp/safecard" />   
                </MenuItem>

                <MenuItem
                  icon={<i class="fa fa-money" aria-hidden="true"></i>}
                  className="mt-3"
                >
                  {" "}
                  Retraite Complémentaire
                  <Link to="/mainapp/retraitecomplementaire" />   
                </MenuItem>

                <MenuItem
                  icon={<i class="fa fa-money" aria-hidden="true"></i>}
                  className="mt-3"
                >
                  {" "}
                  Frais Funéraires
                  <Link to="/mainapp/fraisfuneraires" />   
                </MenuItem>
              </>
            )}

          {(userInfo
            ? userInfo.code_type_utilisateur === "0" ||
              userInfo.code_type_utilisateur === "5" ||
              userInfo.code_type_utilisateur === "6"
            : null) &&
            !loading && (
              <>
                <span className=" ml-2">Tarifs</span>

                <MenuItem
                  icon={<i class="fa fa-cogs" aria-hidden="true"></i>}
                  className="mb-3"
                >
                  {" "}
                  Tarif prêt
                  <Link to="/mainapp/primepret" />
                </MenuItem>
                <MenuItem
                  icon={<i class="fa fa-cogs" aria-hidden="true"></i>}
                  className="mt-3"
                >
                  {" "}
                  Tarif Découvert
                  <Link to="/mainapp/primedecouvert" />
                </MenuItem>
              </>
            )}
        </Menu>
        {(userInfo ? userInfo.code_type_utilisateur === "1" : null) &&
          !loading && (
            <>
              <span className=" ml-2">Gestion</span>

              <Menu iconShape="circle">
                <SubMenu
                  // suffix={<span className="badge yellow">3</span>}
                  title="Gestion Agences"
                  icon={<i class="fa fa-users" aria-hidden="true"></i>}
                >
                  <MenuItem>
                    Etat Agence <Link to="/mainapp/agence" />
                  </MenuItem>
                </SubMenu>
              </Menu>
            </>
          )}

        {(userInfo
          ?  userInfo.code_type_utilisateur === "1"         // Admin
          : null) &&
          !loading && (
            <>
              <span className=" ml-2">Gestion</span>
              <Menu iconShape="circle">
                <SubMenu
                  // suffix={<span className="badge yellow">3</span>}
                  title="Administration"
                  icon={<i class="fa fa-users" aria-hidden="true"></i>}
                >
                  {(userInfo
                    ? userInfo.code_type_utilisateur === "1" ||
                      userInfo.code_type_utilisateur === "0"
                    : null) &&
                    !loading && (
                      <>
                        <MenuItem>
                          <Link to="/mainapp/creercompte">Creer Compte</Link>
                        </MenuItem>{" "}
                        <MenuItem>
                          <Link to="/mainapp/users">Etat Compte</Link>
                        </MenuItem>{" "}
                        <MenuItem>
                          <Link to="/mainapp/resetpassword">Reset Password</Link>
                        </MenuItem>                      
                        <MenuItem>
                          <Link to="/mainapp/importdata">Importer Données</Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/mainapp/deletedata">Suprimer Données</Link>
                        </MenuItem>
                        
                        <MenuItem>
                          <Link to="/mainapp/validContrat">Validation Contrat</Link>
                        </MenuItem>
                      </>
                    )}{" "}
                </SubMenu>
              </Menu>
            </>
          )}

      {(userInfo
          ? userInfo.code_type_utilisateur === "0"         // SUPER ADMIN
          : null) &&
          !loading && (
            <>
              <span className=" ml-2">Gestion</span>
              <Menu iconShape="circle">
                <SubMenu
                  // suffix={<span className="badge yellow">3</span>}
                  title="Administration"
                  icon={<i class="fa fa-users" aria-hidden="true"></i>}
                >
                  {(userInfo
                    ? userInfo.code_type_utilisateur === "1" ||
                      userInfo.code_type_utilisateur === "0"
                    : null) &&
                    !loading && (
                      <>
                        <MenuItem>
                          <Link to="/mainapp/creercompte">Creer Compte</Link>
                        </MenuItem>{" "}
                        <MenuItem>
                          <Link to="/mainapp/users">Etat Compte</Link>
                        </MenuItem>{" "}
                        <MenuItem>
                          <Link to="/mainapp/resetpassword">Reset Password</Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/mainapp/exportdata">Exporter Données</Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/mainapp/importdata">Importer Données</Link>
                        </MenuItem>
                        <MenuItem>
                          <Link to="/mainapp/deletedata">Suprimer Données</Link>
                        </MenuItem>
                        
                        <MenuItem>
                          <Link to="/mainapp/validContrat">Validation Contrat</Link>
                        </MenuItem>
                      </>
                    )}{" "}
                </SubMenu>
              </Menu>
            </>
          )}
      </SidebarContent>

      <SidebarFooter style={{ textAlign: "center" }}></SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
