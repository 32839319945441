import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import Axios from "axios";
import { listAgence } from "./redux/actions/agenceActions";
import { useDispatch, useSelector } from "react-redux";
import { listUsers } from "./redux/actions/userActions";
import { notification } from "antd";

export default function RolesUser({ users, id }) {

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [rolechange, setroles] = useState("3")
  const dispatch = useDispatch();
  const agenceList = useSelector((state) => state.agenceList);
  const { agence } = agenceList;

  const details = users.filter((user, index) => {
    return user.id === id;
  });
  const showModal = () => {
    setVisible(true);
  };
     
  const userData = new FormData();
      userData.append("code_utilisateur", details[0].code_utilisateur);
      userData.append("role_user", rolechange);

       
  useEffect(()=>{
    console.log('nouveau role:'+rolechange);
  }, [rolechange])     


  const changeRoles = () => {
    Axios({
      method: "post",
      url: "/prubli_ma/ss/maj_changer_roles.php",
      data: userData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        //console.log(response);

        //console.log(response.data.message);

        localStorage.setItem("message_roles",response.data.message) ;
        
      })
      .catch(function (response) {
        //handle error
        //console.log(response);

        localStorage.setItem("message_roles",response.data.message) ;
      });
  };

  const openNotificationWithIcon = () => {
    notification.success({
      message: localStorage.getItem('message_roles'),
      description: "Update Roles.",
      style: {
        marginTop: 50,
      },
    });
  };

  const handleOk = () => {
      setConfirmLoading(true);
      openNotificationWithIcon ();
      changeRoles();
    setTimeout(() => {
        setVisible(false);
        dispatch(listUsers());
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };
  useEffect(() => {
    dispatch(listAgence());
  }, [dispatch]);

  return (
    <>
      <Button
        style={{
          backgroundColor: "blue",
          color: "white",
        }}
        shape="circle"
        size="small"
        onClick={showModal}
      >
        {" "}
        T
      </Button>
      <Modal
        title="Changer Roles Urilisateur"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        Nom: <h4> {details[0].nom} </h4>
        Code agence: <h4>{details[0].code_agence} </h4>
        Code utilisateur: <h4> {details[0].code_utilisateur} </h4>
        <label className="control-label mt-3">Choisir le Nouveau Roles: </label>
        <select
          className="select_compte mb-4"
          id="role"
          name="role"
          defaultValue={details[0].code_agence}
          value={rolechange}
          onChange={(e) => setroles(e.target.value)}

         
        >
           <option selected value={3}>
          Utilisateur
        </option>
        <option value={2}>Chef d'agence</option>
        <option value={5}>Gestionnaire crédit</option>
        <option value={4}>Autres</option>
        <option value={7}>3C</option>

          {/* {agence.root_liste_agence &&
            agence.root_liste_agence.map((item) => {
              return (
                <option key={item.code_agence} value={item.code_agence}>
                  {item.libelle_agence}
                </option>
              );
            })} */}
        </select>
      </Modal>
    </>
  );
};
