import {
  ADD_CONTRAT_REQUEST,
  ADD_CONTRAT_SUCCESS,
  ADD_CONTRAT_FAIL,
  CONTRAT_LIST_REQUEST,
  CONTRAT_LIST_SUCCESS,
  CONTRAT_LIST_FAIL,
  CLEAR_SUCCESS,
} from "../constants/ContratConst";


export const contratListReducer = (state = { contrats: [] }, action) => {
  switch (action.type) {
    case CONTRAT_LIST_REQUEST:
      return { loading: true, contrats:[]};
    case CONTRAT_LIST_SUCCESS:
      return { loading: false, contrats: action.payload };
    case CONTRAT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const contratAddReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_CONTRAT_REQUEST:
      return { loading: true };
    case ADD_CONTRAT_SUCCESS:
      return {
        loading: false,
        successAdd: true,
        contrat: action.payload,
      };
    case CLEAR_SUCCESS:
      return {
        ...state,
        successAdd: false,
      };
    case ADD_CONTRAT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
