import Axios from "axios";

import {
  ADD_CONTRAT_REQUEST,
  ADD_CONTRAT_SUCCESS,
  ADD_CONTRAT_FAIL,
  CONTRAT_LIST_REQUEST,
  CONTRAT_LIST_SUCCESS,
  CONTRAT_LIST_FAIL,
} from "../constants/ContratConst";   



export const addContrat = (contratData) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_CONTRAT_REQUEST,
    });

    const { data } = await Axios.post(
      "/prubli_ma/ss/save_contrat.php",
      contratData
    );
    dispatch({ type: ADD_CONTRAT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ADD_CONTRAT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listContrat = () => async (dispatch) => {
  dispatch({
    type: CONTRAT_LIST_REQUEST,
  });
  try {
    const { data } = await Axios.get(
      "/prubli_ma/ss/afficher_liste_contrat.php"
    );
    dispatch({ type: CONTRAT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CONTRAT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  } 
};

