import React, { useState } from "react";
import { Modal, Button, Form, Input, Col, Row } from "antd";
import { addPrimeDec, listPrimeDec } from "./redux/actions/tauxPrimeActions";
import { useDispatch } from "react-redux";

export default function AjoutPrimeDec() {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const dispatch = useDispatch();

  const [ageMin, setagemin] = useState();
  const [ageMax, setagemax] = useState();
  const [tauxClient, settauxclient] = useState();
  const [tauxPersonnel, settauxpersonnel] = useState();

  const contratData = new FormData();
  contratData.append("age_max", ageMax);
  contratData.append("age_min", ageMin);
  contratData.append("taux_client",tauxClient);
  contratData.append("taux_personnel", tauxPersonnel);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    dispatch(addPrimeDec(contratData));

    setTimeout(() => {
      setVisible(false);
      dispatch(listPrimeDec());

      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
  };

  return (
    <>
      <Button
        style={{
          backgroundColor: "#1B9CFC",
          color: "white",
        }}
        size="small"
        onClick={showModal}
      >
        {" "}
        Ajouter prime
      </Button>
      <Modal
        // width={1000}
        title="Title"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={24}>
            <Form.Item label="Age min">
              <Input
                value={ageMin}
                type="number"
                onChange={(e) => setagemin(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Age max">
              <Input
                value={ageMax}
                type="number"
                onChange={(e) => setagemax(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Taux client">
              <Input
                value={tauxClient}
                type="number"
                onChange={(e) => settauxclient(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Taux personnel">
              <Input
                value={tauxPersonnel}
                type="number"
                onChange={(e) => settauxpersonnel(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
