import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listUsers } from "./redux/actions/userActions";
import Axios from "axios"
import { resetpass } from "./redux/actions/userActions";
import {notification } from "antd";
import {  clearSuccess } from "./redux/actions/userActions";

//import {listAgence} from "./redux/actions/agenceActions"

export default function ResetPassword() {
  const [user, setuser] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [code_utilisateur, setcode_utilisateur] = useState("");

  const formData = new FormData();
  formData.append("code_utilisateur",code_utilisateur);
  formData.append("password1", password1);
  formData.append("password2", password2); 

  const clearState = () => {
    setuser("");
    setPassword1("");
    setPassword2("");
  };

  const openNotificationWithIcon = () => {
    notification.success({
      message: "Mot de Passe Mis à Jour",
      description: "Password Reset.",
      style: {
        marginTop: 50,
      },
    });
  };

  const userSignin = useSelector((state) => state.userSignin);
  const userList = useSelector((state) => state.usersList); 
  const userRegister = useSelector(state => state.userRegister)
  const { messageR } = userRegister; 

  useEffect(() => { 
    dispatch(listUsers());  
  }, []); 
  
  const { users } = userList;
  const { userInfo, loading } = userSignin;

  const dispatch = useDispatch();

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   dispatch(resetpass(formData));   
  // };
  
  const openNotificationWithIconError = () => {
    notification.error({
      message: "Echec De Mise à Jours",
      description: "Mise à jour non effectuée",
      style: {
        marginTop: 50,
      },
    });
  };

  const submitHandler = (e) => {

    e.preventDefault();             // Empeche le rechargement de la page

    console.log('messageR j y suis:'+messageR);

    Axios({
      method: "post",
      url: "/prubli_ma/ss/reset_password_admin.php",
      data: formData,
    })
    .then(function (response) {
      
      openNotificationWithIcon();

    })
    .catch(function (response) {

      openNotificationWithIconError(); 

    });

     setTimeout(() => {
        window.location.reload(true);
      }, 5000);

  };


  // useEffect(() => {
  //   //dispatch(resetpass());
  //     console.log('messageR:'+messageR);
  //  if (messageR) {
  //     openNotificationWithIcon();
  //     clearState();
  //     dispatch(clearSuccess());      
  //  }
  // }, [dispatch, messageR]);

  return (
    <>
      <section class="signup-content">
        <div className="signup-box" style={{"width" : "50%"}}>
          <form onSubmit={submitHandler}>
            <h5 className="login-head">
              <i className="fa fa-lg fa-fw fa-user"></i>Reset Password
            </h5>        
             
              <div className="col-12">              
               
                <div className="form-group">
                  {(userInfo ? userInfo.code_type_utilisateur === "1" : null) &&
                    !loading && (
                      <>
                        <label className="control-label">UTILISATEUR</label>
                        <select
                          className="form-control"
                          id="compte"
                          name="compte"
                          value={code_utilisateur}
                          onChange={(e) => setcode_utilisateur(e.target.value)}
                        >
                          { users.root_liste_users &&
                            users.root_liste_users.map((item) => {
                              return (
                                <option
                                  key={item.code_utilisateur}
                                  selected
                                  value={item.code_utilisateur}
                                >
                                  {item.nom+' '+item.prenoms+' ('+item.email+')'}
                                </option>
                              );
                            })}
                        </select>
                      </>
                    )}
                </div>{" "}
                <div className="form-group">
                  <label className="control-label">NOUVEAU MOT DE PASSE</label>
                  <input
                    className="form-control"
                    htmltype="password"
                    placeholder="password"
                    value={password1}
                    onChange={(e) => setPassword1(e.target.value)}
                  />
                </div>{" "}
                <div className="form-group">
                  <label className="control-label">CONFIRMER MOT DE PASSE</label>
                  <input
                    className="form-control"
                    htmltype="password"
                    placeholder="password"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                </div>{" "}
            
            </div>

            <div className="form-group btn-container">
              <button className="btn btn-primary btn-block" htmltype="submit">
                {loading && (
                  <div>
                    <i className="fa fa-refresh fa-spin mr-2"></i> Création du
                    compte
                  </div>
                )}
                {!loading && <div> Reset Password</div>}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
