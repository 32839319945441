import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signin, clearSuccess,} from "./redux/actions/userActions";
// import { Link } from "react-router-dom";
import {  UserOutlined,  LockOutlined,  EyeInvisibleOutlined,  EyeTwoTone,} from "@ant-design/icons";
import { Row, Col,Form, Input, Button} from "antd";
import bgi from "./assets/images/calc.jpeg"

export default function Signin(props) {
  const { location, history } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const formData = new FormData();
    formData.append("login", email);
    formData.append("password", password);



  const redirect = location.search
    ? location.search.split("=")[1]
    : "/mainapp/dashboard";

  const userSignin = useSelector((state) => state.userSignin);

  const { loading, isAuth, messageA } = userSignin;

  const dispatch = useDispatch();
    
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signin(formData));
  };

  useEffect(() => {
    if (isAuth) {
      history.push(redirect);
    } 
    // if (error) {
    //   errorAlert();
    //   dispatch(clearErrors());
    // }
    if (messageA) {
      // successAlert();
      dispatch(clearSuccess());
    }
  }, [
    history,
    dispatch,
    redirect,
    // userInfo,
    // error,
    isAuth,
    messageA,
        // errorAlert,
        // successAlert,
  ]);


  return (
    <>
      <Row style={{ marginTop:'3%'}}>

        <Col className="mt-5" span={12} offset={6} style={{ border: '10px solid rgba(0, 0, 0, 0.05)',padding:'2%' }}>  

          <div>

            <center><img src={bgi} alt="Logo" width='60%;'/></center>

         
          </div> 

            <h3 className="text-center mb-10" style={{ color: "grey",marginTop:'2%',marginBottom:'-2%' }}>
              Se connecter
            </h3>
            <Row style={{}}>
              <Col className="my-10" span={7}></Col>
              <Col className="mt-5" span={10}>
                <form
                  onSubmit={submitHandler}
                  name="normal_login"
                  className="login-form"
                >
                  <Form.Item
                    className="mb-4"
                    name="login"
                    rules={[
                      {
                        required: true,
                        message: "Enter votre login!",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      id="login"
                      name="login"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Login"
                    />
                  </Form.Item>
                  <Form.Item
                    className="mb-3"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Entrer votre mot de passe!",
                      },
                    ]}
                  >
                    <Input.Password
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="input password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>

                  <Button
                    block
                    type="danger"
                    htmlType="submit"
                    className="login-form-button mb-4"
                  >
                    {loading && (
                      <div>
                        <i className="fa fa-refresh fa-spin mr-2"></i> Connexion
                      </div>
                    )}
                    {!loading && <div> Sign In</div>}
                  </Button>
                </form>
              </Col>

              <Col className="mt-5" span={7}></Col>
            </Row>
          {/* </Card> */}
        </Col>
      </Row>
    </>
  );
}
