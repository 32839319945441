import React, { Component } from 'react'
import { Modal, Button } from "antd";
import CSVReader from "react-csv-reader";
export default class UpdateContrat extends Component {
  state = {
    isModalVisible: false,
  };
  showModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };
  handleOk = () => {
    this.setState({
      isModalVisible: false,
    });
      
  };
  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  render() {
    return (
      <>
        {" "}
        <Button type="primary" onClick={this.showModal}>
          Mise à jour
        </Button>
        <Modal
          width={1000}
          title="Mise à jour "
          visible={this.state.isModalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <CSVReader
            onFileLoaded={(data, fileInfo) => console.log(fileInfo, data)}
          />
        </Modal>
      </>
    );
  }
}
