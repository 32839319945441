import React,{ useState,useEffect } from "react";

const xaf = new Intl.NumberFormat("fr-FR", {
  style: "currency",
  currency: "XAF",
  minimumFractionDigits: 0,
}); 

export default function SimulateurContrat({ record, simul ,CalculPrime}) {
  const {    
    prime_brute,
    prime_ht,
    montant_tva,
    prime_ttc,
    date_naissance,   
    numero_police, 
    capital,   
  } = record;

 const { prime_base,surprime, primeHtf, tvaF, primeTtcf,ageClient, quote_part_partenaire,quote_part_prubli,accessoires } = simul;
  
 CalculPrime();

  return (
    <>
      <h4 style={{ color: "gray" }}>Numéro de Police:{numero_police}</h4>
      <div className="row">
        <div className="col-4">
          <h5 style={{ color: "gray" }}>Capital:</h5>
        </div>
        <div className="col-7" style={{ textAlign: "right" }}>
          <h4> {xaf.format(capital)} </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h5 style={{ color: "gray" }}> Age du client:</h5>
        </div>
        <div className="col-6">
          <h4>{ageClient} ans</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h5 style={{ color: "gray" }}>Prime de base:</h5>
        </div>
        <div className="col-6">
          <h4> {xaf.format(prime_base)} </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h5 style={{ color: "gray" }}>Accessoires:</h5>
        </div>
        <div className="col-6">
          <h4> {xaf.format(accessoires)} </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h5 style={{ color: "gray" }}>Supprime:</h5>
        </div>
        <div className="col-6">
          <h4> {xaf.format(surprime)} </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h5 style={{ color: "gray" }}>Prime hors taxe:</h5>
        </div>
        <div className="col-6">
          <h4>{xaf.format(primeHtf)}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h5 style={{ color: "gray" }}>Montant TVA :</h5>
        </div>
        <div className="col-6">
          <h4> {xaf.format(tvaF)} </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h5 style={{ color: "gray" }}>Prime TTC:</h5>
        </div>
        <div className="col-6">
          <h4> {xaf.format(primeTtcf)} </h4>
        </div>
      </div>
      <table style={{ width: "100%" }} className="mb-4  tabl">
        <tbody>
          <tr>
            <td className="tabl">
              <h5>Quote part NFC</h5>
            </td>
            <td className="tabl">
              <h5>Quote part PBLI</h5>
            </td>
          </tr>
          <tr>
            <td className="tabl">
              <h4> {xaf.format(quote_part_partenaire)} </h4>
            </td>
            <td className="tabl">
              <h4> {xaf.format(quote_part_prubli)} </h4>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}



