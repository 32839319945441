import React, { useState, useEffect } from "react";
import { Form} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deletecustomers } from "./redux/actions/userActions";
import { notification } from "antd";
import { clearSuccess } from "./redux/actions/userActions";
import Axios from "axios";

export default function DeleteData() {

  const [typecompte, settypecompte] = useState('D');
  const [infoclient, setinfoclient] = useState(0);
  const [compte, setcompte] = useState("");
  const [client, setclient] = useState("");

  const formData = new FormData();
  formData.append("numero_compte", compte);
  formData.append("type_client",typecompte.toString().substring(0,1));

  const clearState = () => {

    setTimeout(function () {
     window.location.reload(1); 
    }, 3000);

  };

  const openNotificationWithIcon = () => {

    notification.success({
      message: localStorage.getItem('message'),
      description: "Delete Data.",
      style: {
        marginTop: 50,
      },
    });

  };

  const userSignin = useSelector((state) => state.userSignin);
  const userRegister = useSelector(state => state.userRegister)
  const { messageR } = userRegister;

  useEffect(() => {

    const formData = new FormData();
    formData.append("numero_compte",compte);
    formData.append("type_client",typecompte.toString().substring(0,1));
    formData.append("type_recherche", "P");                               // P est une valeur aléatoire pour permetre que la recherche se fasse
    
    const findCustomers = () => {
      Axios({
        method: "post",
        url: "/prubli_ma/ss/recherche_client.php",
        data: formData,
      })
      .then(function (response) {
          
        if (typecompte.toString().substring(0,1).toUpperCase()==='C' && typeof response.data[0] !== 'undefined')
        {
          setclient(response.data[0].NOM_PROMOTEUR);
        }
        else if (typecompte.toString().substring(0,1).toUpperCase()==='P' && typeof response.data[0] !== 'undefined')
        {            
          setclient(response.data[0].LAST_NAME);
        }
        else
        {
          setclient('Client Introuvable');
        } 

      })
      .catch(function (response) {
        
        //handle error
        console.log(response);

      });
    };

    findCustomers();

    setinfoclient('Nom: '+client+' -- Compte:'+compte+' -- Type Client:'+typecompte);

    //console.log(infoclient);  // Particulier 0202820100346719    Corporate 0241730107477232

  }, [client, compte, infoclient, typecompte]);

 // const { loading } = userSignin;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(deletecustomers(formData));
    setinfoclient("");
    clearState();
  };

  useEffect(() => {

   if (messageR) {
      openNotificationWithIcon();
      clearState();
      dispatch(clearSuccess());
   }

  }, [dispatch, messageR]);

  return ( 
    <>
      <section class="signup-content">
        <div className="signup-box" style={{ "width": "55%" }}>
          <form onSubmit={submitHandler}>
            <h5 className="login-head">
              <i className="fa fa-lg fa-fw fa-user"></i>Suprimer Les Données D'une Police
            </h5>

            <div className="col-12">

            <Form.Item
                label="Choisir le type de Client"
                name="Type client"
              >
               <select
                  className="form-control"
                  value={typecompte}
                  onChange={(e) => settypecompte(e.target.value)}
                  id="compte"
                  name="compte"
                  required
                >                    
                  <option selected value={0}>
                    Type Clients
                  </option>
                  <option value={'corporate'}>Corporate</option>
                  <option value={'particulier'}>Particulier</option>
                  <option value={4}>Autres</option>     
              </select>                          
              </Form.Item>{" "}  
           
            </div>

            <div className="col-12">
              {" "}

              <Form.Item
                label="Numero De Compte"
                name="Numero de Compte"
              >      
              <input
                className="form-control"
                htmltype="text"
                placeholder="compte"
                value={compte}
                onChange={(e) => setcompte(e.target.value)}
              />                          
              </Form.Item>{" "}  
             
              <Form.Item
                  label='INFO:'
                >{infoclient}
             
                </Form.Item>

              <div style={{ textAlign: "right" } }>
                {/* <h5>Choisir le Fichier et Cliquer Ensuite sur Import Csv</h5> */}
                {/* <form > */}
                   

                    <button className="btn btn-primary btn-block" htmltype="submit">
                        DELETE
                
                    </button>
                {/* </form> */}
              </div>                      
              <div className="col-12">
            </div>    
            </div>     
          
          </form>
        </div>
      </section>
    </>
  );
}
