import {
  ACCOUNT_LIST_REQUEST,
  ACCOUNT_LIST_SUCCESS,
  ACCOUNT_LIST_NO_CONTENT,
  ACCOUNT_LIST_FAIL,
  GETACCOUNT_LIST_REQUEST,
  GETACCOUNT_LIST_SUCCESS,
  
  GETACCOUNT_LIST_FAIL,
} from "../constants/accountConst";

export const accountListReducer = (state = { comptes: [] }, action) => {
  switch (action.type) {
    case ACCOUNT_LIST_REQUEST:
      return { loading: true, comptes: [] };
    case ACCOUNT_LIST_SUCCESS:
      return { loading: false, comptes: action.payload };
    case ACCOUNT_LIST_NO_CONTENT:
      return { loading: false, nonContent: action.payload, comptes: []};
    case ACCOUNT_LIST_FAIL:
      return { loading: false,comptes: [], error: action.payload };
    default:
      return state;
  }
};
