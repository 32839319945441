import React, { Component } from 'react'
import EmprunteurDirectDetails from './print/EmprunteurDirectDetails';
import ParraineDetails from './print/ParraineDetails';
import SafeCardDetails from './print/SafeCardDetails';

export default class ContratDetails extends Component {

  render() {
    const { contrats, id, userInfo,beneInfo } = this.props;


  const details = contrats.filter((contrat, index) => {
    return contrat.id === id;
  });
    const typeClient = details[0].type_client;


  return (
    <>
      {(typeClient === "particulier" || typeClient === "corporate") && (
          <>
            {" "}
            <div>
              {details.map((contrat) => {
                return (
                  <div>
                    <EmprunteurDirectDetails
                      key={contrat.id}
                      contrat={contrat}
                      userInfo={userInfo}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}

      {typeClient === "parraine" && (
        <>
          <div>
            {details.map((contrat) => {
              return (
                <div>
                  <ParraineDetails
                    key={contrat.id}
                    contrat={contrat}
                    userInfo={userInfo}
                    beneInfo={beneInfo}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}

      {typeClient === "safe_card" && (
        <>
          <div>
            {details.map((contrat) => {
              return (
                <div>
                  <SafeCardDetails
                    key={contrat.id}
                    contrat={contrat}
                    userInfo={userInfo}
                    //beneInfo={beneInfo}
                  />
                </div>
              );
            })}
          </div>
        </>
          )}
    </>
  );

   }
}
