import {
  AGENCE_LIST_REQUEST,
  AGENCE_LIST_SUCCESS,
  AGENCE_LIST_FAIL,
  AGENCE_ADD_REQUEST,
  AGENCE_ADD_SUCCESS,
  AGENCE_ADD_FAIL,
} from "../constants/agenceConst";
import Axios from "axios";   


export const addAgence = (agenceData) => async (dispatch) => {
  try {
    dispatch({
      type: AGENCE_ADD_REQUEST,
    });

    const { data } = await Axios.post(
      "/prubli_ma/ss/create_agence.php",
      agenceData
    );
    dispatch({ type: AGENCE_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: AGENCE_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listAgence = () => async (dispatch) => {
  try {
    dispatch({
      type: AGENCE_LIST_REQUEST,
    });

    const { data } = await Axios.get("/prubli_ma/ss/afficher_liste_agence.php");
    {
      dispatch({ type: AGENCE_LIST_SUCCESS, payload: data });    
      
      localStorage.setItem('listAgence',JSON.stringify(data.root_liste_agence));
  
    } 
  } catch (error) {
    dispatch({ type: AGENCE_LIST_FAIL, payload: error.message });
  }
};