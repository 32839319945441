import {
  CALCULP_LIST_REQUEST,
  CALCULP_LIST_SUCCESS,
  CALCULP_LIST_FAIL,
  CLEAR_SUCCESS,
} from "../constants/calculPretConst";
import Axios from "axios"



export const calculPret = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: CALCULP_LIST_REQUEST,
    });

    const { data } = await Axios.post("/prubli_ma/ss/calculpret.php", formData);
    // const { data } = await Axios({
    //   method: "post",
    //   url: "/prubli_ma/ss/calculpret.php",
    //   data: formData,
    //   headers: { "Content-Type": "multipart/form-data" },
    // });
    dispatch({ type: CALCULP_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CALCULP_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const clearSuccess = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SUCCESS,
  });
};