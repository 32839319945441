

export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL';

// 

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';


export const USER_LOAD_REQUEST = "USER_LOAD_REQUEST";
export const USER_LOAD_SUCCESS = "USER_LOAD_SUCCESS";
export const USER_LOAD_FAIL = "USER_LOAD_FAIL";


export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";




export const USER_SIGNOUT_SUCCESS = "USER_SIGNOUT_SUCCESS";
export const USER_SIGNOUT_FAIL = "USER_SIGNOUT_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";

export const USER_RESET_REQUEST = "USER_RESET_REQUEST";
export const USER_RESET_FAIL = "USER_RESET_FAIL";
export const USER_RESET_SUCCESS = "USER_RESET_SUCCESS";

   