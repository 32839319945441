import React, { useState } from "react";
import { Modal, Button, Form, Input, Col, Row } from "antd";
import {
  updatePrimePret,
  listPrimePret,
} from "./redux/actions/tauxPrimeActions";
import {  useDispatch } from "react-redux";

export default function PrimePretEdit(props) {
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
    const { primeP, primeId } = props;
    
     const dispatch = useDispatch()

  const details = primeP.root_pret.filter((prime, index) => {
    return prime.id === primeId;
  });
    
  // console.log(details)
    const [age, setage] = useState(`${details[0].age}`);
    const [annee1, setannee1] = useState(`${details[0].annee1}`);
    const [annee2, setannee2] = useState(`${details[0].annee2}`);
    const [annee3, setannee3] = useState(`${details[0].annee3}`);
    const [annee4, setannee4] = useState(`${details[0].annee4}`);
    const [annee5, setannee5] = useState(`${details[0].annee5}`);
    const [annee6, setannee6] = useState(`${details[0].annee6}`);
    const [annee7, setannee7] = useState(`${details[0].annee7}`);
    const [annee8, setannee8] = useState(`${details[0].annee8}`);
    // const [annee9, setannee9] = useState(`${details[0].annee9}`);
    // const [annee10, setannee10] = useState(`${details[0].annee10}`);

  


    
  const contratData = new FormData();
  contratData.append("id", primeId);
  // contratData.append("age", age);
  contratData.append("taux_annee1", annee1);
  contratData.append("taux_annee2", annee2);
  contratData.append("taux_annee3", annee3);
  contratData.append("taux_annee4", annee4);
  contratData.append("taux_annee5", annee5);
  contratData.append("taux_annee6", annee6);
  contratData.append("taux_annee7", annee7);
  contratData.append("taux_annee8", annee8);
  // contratData.append("taux_annee9", annee9);
  // contratData.append("taux_annee10", annee10);

const id = primeId;


// console.log(id)
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
     dispatch(updatePrimePret(contratData,id));

    setTimeout(() => {
      setVisible(false);
           dispatch(listPrimePret());

      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setVisible(false);
    };
    



  return (
    <>
      <Button
        style={{
          backgroundColor: "#1B9CFC",
          color: "white",
        }}
        shape="circle"
        size="small"
        onClick={showModal}
      >
        {" "}
        E
      </Button>
      <Modal
        // width={1000}
        title="Prime edit"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div>
          <Row>
            <Col>
              {/* <Form.Item label="Age">
                  <Input
                    value={age}
                    type="number"
                    onChange={(e) => setage(e.target.value)}
                  />
                </Form.Item> */}
              <Form.Item label="1 an">
                <Input
                  value={annee1}
                  type="number"
                  onChange={(e) => setannee1(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="2 ans">
                <Input
                  value={annee2}
                  type="number"
                  onChange={(e) => setannee2(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="3 ans">
                <Input
                  value={annee3}
                  type="number"
                  onChange={(e) => setannee3(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="4 ans">
                <Input
                  value={annee4}
                  type="number"
                  onChange={(e) => setannee4(e.target.value)}
                />
              </Form.Item>{" "}
            </Col>
            <Col>
              <Form.Item label="5 ans">
                <Input
                  value={annee5}
                  type="number"
                  onChange={(e) => setannee5(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="6 ans">
                <Input
                  value={annee6}
                  type="number"
                  onChange={(e) => setannee6(e.target.value)}
                />
              </Form.Item>{" "}
              <Form.Item label="7 ans">
                <Input
                  value={annee7}
                  type="number"
                  onChange={(e) => setannee7(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="8 ans">
                <Input
                  value={annee8}
                  type="number"
                  onChange={(e) => setannee8(e.target.value)}
                />
              </Form.Item>
            </Col>
            {/* <Col className="">
              <Form.Item label="9 ans">
                  <Input
                                value={annee9}
                    type="number"
                    onChange={(e) => setannee9(e.target.value)}
                  />
                </Form.Item>{" "}
                <Form.Item label="10 ans">
                  <Input
                                value={annee10}
                    type="number"
                    onChange={(e) => setannee10(e.target.value)}
                  />
                </Form.Item>
            </Col> */}
          </Row>
        </div>
      </Modal>
    </>
  );
}
