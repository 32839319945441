import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { listPrimeDec, deletePrimeDec } from "./redux/actions/tauxPrimeActions";
import { Table, Button, Popconfirm,Spin   } from "antd";
import PrimeDecouvertEdit from "./PrimeDecouvertEdit";
import AjoutPrimeDec from "./AjoutPrimeDec"

export default function PrimeDecouvert()

{
     const dispatch = useDispatch();

     useEffect(() => {
       dispatch(listPrimeDec());
     }, [dispatch]);

     const primeDec = useSelector((state) => state.primeDec);
  const { primeD, loading } = primeDec;
      const userSignin = useSelector((state) => state.userSignin);
      const { userInfo } = userSignin;

  const handleDelete = (record) => {
    const id = record.id;
    const formData = new FormData();
    formData.append("id", id);
    dispatch(deletePrimeDec(formData));
    setTimeout(() => {
      dispatch(listPrimeDec());
    }, 200);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
  };
    const columns = [
      {
        title: "Age Min",
        dataIndex: "age_min",
      },

      {
        title: "Age Max",
        dataIndex: "age_max",
      },
      {
        title: "Taux client",
        dataIndex: "taux_client",
      },
      {
        title: "Taux personnel",
        dataIndex: "taux_personnel",
      },
      {
        title: "Action",
        fixed: "right",
        key: "action",
        width: "11%",
        render: (text, record) => (
          <span>
            {(userInfo ? userInfo.code_type_utilisateur === "0" : null) && (
              <>
                {" "}
                <PrimeDecouvertEdit primeD={primeD} primeId={record.id} />
                <Popconfirm
                  title="Estes vous sur de vouloir supprimer?"
                  onConfirm={() => handleDelete(record)}
                  onCancel={handleCancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger" shape="circle" size="small">
                    D
                  </Button>
                </Popconfirm>
              </>
            )}
          </span>
        ),
      },
    ];

    function onChange(pagination, filters, sorter, extra) {
      console.log("params", pagination, filters, sorter, extra);
    }

  return (
    <>
      <div>
        {" "}
        <h1>Prime découvert </h1>
        {(userInfo ? userInfo.code_type_utilisateur === "0" : null) && (
          <div className="d-flex justify-content-between">
            {" "}
            <AjoutPrimeDec />{" "}
          </div>
        )}
        <Spin size="large" tip="Chargement..." spinning={loading}>
          {primeD.root_tarif_decouvert ? (
            <Table
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={primeD.root_tarif_decouvert}
              onChange={onChange}
            />
          ) : null}
        </Spin>
      </div>
    </>
  );
}
