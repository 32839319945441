import {
  ADD_FM_REQUEST,
  ADD_FM_SUCCESS,
  ADD_FM_FAIL,
  GET_FM_REQUEST,
  GET_FM_SUCCESS,
  GET_FM_FAIL,
} from "../constants/fmConst";

export const qmGetReducer = (state = { QMedical: [] }, action) => {
  switch (action.type) {
    case GET_FM_REQUEST:
      return { loading: true, QMedical: [] };
    case GET_FM_SUCCESS:
      return { loading: false, QMedical: action.payload };
    case GET_FM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const fmAddReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_FM_REQUEST:
      return { loading: true };
    case ADD_FM_SUCCESS:
      return {
        loading: false,
        fm: action.payload,
      };
    case ADD_FM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
