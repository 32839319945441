import {
  PRIMEP_LIST_REQUEST,
  PRIMEP_LIST_SUCCESS,
  PRIMEP_LIST_FAIL,
  PRIMEP_UPDATE_REQUEST,
  PRIMEP_UPDATE_SUCCESS,
  PRIMEP_UPDATE_FAIL,
  PRIMEP_DELETE_FAIL,
  PRIMEP_DELETE_REQUEST,
  PRIMEP_DELETE_SUCCESS,
  PRIMED_DELETE_FAIL,
  PRIMED_DELETE_REQUEST,
  PRIMED_DELETE_SUCCESS,
  PRIMED_LIST_REQUEST,
  PRIMED_LIST_SUCCESS,
  PRIMED_LIST_FAIL,
  PRIMEP_ADD_FAIL,
  PRIMEP_ADD_REQUEST,
  PRIMEP_ADD_SUCCESS,
  PRIMED_UPDATE_REQUEST,
  PRIMED_UPDATE_SUCCESS,
  PRIMED_UPDATE_FAIL,
  PRIMED_ADD_FAIL,
  PRIMED_ADD_REQUEST,
  PRIMED_ADD_SUCCESS,
} from "../constants/tauxPrimeConst";



export const primePretUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRIMEP_UPDATE_REQUEST:
      return { loading: true };
    case PRIMEP_UPDATE_SUCCESS:
      return {
        loading: false,
        primeP: action.payload,
      };
    case PRIMEP_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const primeDecUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRIMED_UPDATE_REQUEST:
      return { loading: true };
    case PRIMED_UPDATE_SUCCESS:
      return {
        loading: false,
        primeD: action.payload,
      };
    case PRIMED_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const primeAddReducer = (state = {}, action) => {
  switch (action.type) {
    case PRIMEP_ADD_REQUEST:
      return { loading: true };
    case PRIMEP_ADD_SUCCESS:
      return {
        loading: false,
        primeP: action.payload,
      };

    case PRIMEP_ADD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


export const primePretListReducer = (
  state = { loading: true, primeP: [] },
  action
) => {
  switch (action.type) {
    case PRIMEP_LIST_REQUEST:
      return { loading: true, primeP: [] };
    case PRIMEP_LIST_SUCCESS:
      return {
        loading: false,
        primeP: action.payload,
      };
    case PRIMEP_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const primeDecListReducer = (
  state = { loading: true, primeD: [] },
  action
) => {
  switch (action.type) {
    case PRIMED_LIST_REQUEST:
      return { loading: true, primeD: [] };
    case PRIMED_LIST_SUCCESS:
      return {
        loading: false,
        primeD: action.payload,
      };
    case PRIMED_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const primePretDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRIMEP_DELETE_REQUEST:
      return { loading: true };
    case PRIMEP_DELETE_SUCCESS:
      return {
        loading: false,
        primeP: action.payload,
      };
    case PRIMEP_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const primeDecDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRIMED_DELETE_REQUEST:
      return { loading: true };
    case PRIMED_DELETE_SUCCESS:
      return {
        loading: false,
        primeD: action.payload,
      };
    case PRIMED_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const primeDecAddReducer = (state = {}, action) => {
  switch (action.type) {
    case PRIMED_ADD_REQUEST:
      return { loading: true };
    case PRIMED_ADD_SUCCESS:
      return {
        loading: false,
        primeD: action.payload,
      };

    case PRIMED_ADD_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
