import React, { useState, useEffect } from "react";
import { Form} from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { listUsers } from "./redux/actions/userActions"; 
import { importdon } from "./redux/actions/userActions";
import { notification } from "antd";
import { clearSuccess } from "./redux/actions/userActions";
import particulier_csv from "./assets/particulier.csv";  
import corporate_csv from "./assets/corporate.csv";   



export default function ImportData() { 

  const [optionchoisie, setoptionchoisie] = useState(0);
  const [file, setFile] = useState();
  const [charge, setCharge] = useState(0);

  const formData = new FormData();
  formData.append("optionchoisie", optionchoisie);
  formData.append("file", file);

  const clearState = () => {

    setTimeout(function () {
   /*   window.location.reload(1);*/
    }, 5000);
  };

  const openNotificationWithIcon = () => {
    notification.success({
      message: localStorage.getItem('message'),
      description: "Import Data.",
      style: {
        marginTop: 50,
      },
    });
  };

  const userSignin = useSelector((state) => state.userSignin);
  const userRegister = useSelector(state => state.userRegister)
  const { messageR } = userRegister;

  useEffect(() => {
    console.log('valeur choisie:'+optionchoisie)
  }, [optionchoisie]);

  const { loading } = userSignin;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(importdon(formData));
    setCharge(0);
    
  };

  useEffect(() => {
    //dispatch(importdon());
    if (messageR) {
      openNotificationWithIcon();
      clearState();
      dispatch(clearSuccess());
    }
  }, [dispatch, messageR]);

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
      setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e) => {
      e.preventDefault();

      if (file) {
        fileReader.onload = function (event) {
            const csvOutput = event.target.result;

            console.log('csvOutput:'+csvOutput);

            console.log('bouton charger cliqué');

            setFile(file);

            setCharge(1); 
        };
        if(file != null && file.size > 0)
        {
          fileReader.readAsText(file);
        }             
      }
  };

  useEffect(()=>{
    console.log('file:'+JSON.stringify(file));    
  }, [file]) 

  return (
    <>
      <section class="signup-content">
        <div className="signup-box" style={{ "width": "50%" }}>
          <form onSubmit={submitHandler}>
            <h5 className="login-head">
              <i className="fa fa-lg fa-fw fa-user"></i>Importer Les Données
            </h5>

            <div className="col-12">
            </div>

            <div className="col-12">
              {" "}

              <Form.Item
                label="Type de Donnée (Format CSV)"
                name="Type de Donnée (Format CSV)"
              >
               <select
                  className="form-control"
                  value={optionchoisie}
                  onChange={(e) => setoptionchoisie(e.target.value)}
                  id="options"
                  name="options"
                  required
                >                    
                  <option selected value={0}>
                    Type Clients
                  </option>
                  <option value={'corporate'}>Corporate</option>
                  <option value={'particulier'}>Particulier</option>
                  <option value={4}>Autres</option>     
              </select>                          
              </Form.Item>{" "}  
             
             { (optionchoisie === "corporate")&& (
                <Form.Item
                label="Modèle de Fichier Corporate"
                name="Modèle de Fichier Corporate"
                >
                  <a href={corporate_csv} download>Télécharger</a>   

                </Form.Item>)
              }
              {" "}
              
              { (optionchoisie === "particulier")&& (

                <Form.Item
                  label="Modèle de Fichier Particulier"
                  name="Modèle de Fichier Particulier"
                >
                <a href={particulier_csv}  download>Télécharger</a>

                </Form.Item>)
              }
              {" "}

              <div style={{ textAlign: "right" } }>
                {/* <h5>Choisir le Fichier et Cliquer Ensuite sur Import Csv</h5> */}
                {/* <form > */}
                    <input
                        type={"file"}
                        id={"csvFileInput"}
                        accept={".csv"}
                        onChange={handleOnChange}
                    />

                    <button
                        onClick={(e) => {
                            handleOnSubmit(e);
                        }}
                    >
                        IMPORT CSV
                    </button>
                {/* </form> */}
              </div>                      
              <div className="col-12">
            </div>    
            </div>
            
          

            <div className="form-group btn-container" style={{ marginTop: "10px" } }>
            {(charge===1)&&(optionchoisie==='corporate'||optionchoisie==='particulier')&&<button className="btn btn-primary btn-block" htmltype="submit">
                {/* {charge && ( */}
                  {/* <div> */}
                    {/* <i className="fa fa-refresh fa-spin mr-2"></i> Importer Données */}
                  {/* </div> */}
                {/* )} */}
                {!loading && <div>Importer Données</div>}
              </button> }
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
