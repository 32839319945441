import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Signin from "./Signin";
import MainApps from "./container/MainApps"
import ProtectedRoute from "./routes/ProtectedRoute"

// const RestrictedRoute = ({ component: Component, isAuth, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       isAuth ? (
//         <Component {...props} />
//       ) : (
//         <Redirect
//           to={{
//             pathname: "/signin",
//           }}
//         />
//       )
//     }
//   />
// );

export default function NextApp() { 

  return (
    <Router>
      <Switch>
        <Redirect exact from="/" to="/mainapp" />
        <Route path="/signin" component={Signin} />
        <ProtectedRoute  path="/mainapp" component={MainApps} />
      </Switch>
    </Router>
  );
}
