import React, { Component } from "react";
import ReactToPrint from "react-to-print";
import { Modal, Button } from "antd";
import ParrainePrint from "./ParrainePrint";

export default class PrintPa extends Component {
  state = {
    isModalVisible: false,
  };
  showModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  render() {
    const { printData } = this.props;

    return (
      <div>
        <Button type="primary" onClick={this.showModal}>
          Imprimer
        </Button>
        <Modal
          width={1200}
          title="Model print"
          visible={this.state.isModalVisible}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Fermer
            </Button>,
            <ReactToPrint
              trigger={() => (
                <Button key="submit" type="primary">
                  Imprimer
                </Button>
              )}
              content={() => this.componentRef}
            />,
          ]}
        >
          <ParrainePrint
            ref={(el) => (this.componentRef = el)}
            printData={printData}
          />
        </Modal>
      </div>
    );
  }
}
