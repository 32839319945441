  import Axios from "axios";
  import {USER_SIGNIN_REQUEST,USER_SIGNIN_FAIL,USER_SIGNIN_SUCCESS,USER_SIGNOUT_SUCCESS,USER_SIGNOUT_FAIL,USER_REGISTER_REQUEST,
          USER_REGISTER_SUCCESS,USER_REGISTER_FAIL,USER_LOAD_REQUEST,USER_LOAD_SUCCESS,USER_LOAD_FAIL,CLEAR_ERRORS,CLEAR_SUCCESS,
          USER_LIST_REQUEST,USER_LIST_SUCCESS,USER_LIST_FAIL,USER_RESET_REQUEST,USER_RESET_FAIL,USER_RESET_SUCCESS,
  } from "../constants/userConstants";

  import React, { setState, useEffect } from "react";

  import {saveAs} from 'file-saver';

  const setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/";
  };

  // load user
  export const loadUser = (tokenData) => async (dispatch) => {
    try {
      dispatch({ type: USER_LOAD_REQUEST });
      const { data } = await Axios.post(
        "/prubli_ma/ss/consulter_user.php",
        tokenData
      );
      dispatch({
        type: USER_LOAD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_LOAD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const signin = (formData) => async (dispatch) => {
    try {
      dispatch({ type: USER_SIGNIN_REQUEST });
    
      const { data } = await Axios.post(
        "/prubli_ma/ss/connexion.php",
        formData
      );
      dispatch({
        type: USER_SIGNIN_SUCCESS,
        payload: data,
      });
      setCookie("token", data.token, 300000);
      // localStorage.setItem("userInfo", JSON.stringify(data))
    } catch (error) {
      dispatch({
        type: USER_SIGNIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }; 

  export const register = (formData) => async (dispatch) => {
    try {
          dispatch({
            type: USER_REGISTER_REQUEST,
          });

      const { data } = await Axios.post(
        "/prubli_ma/ss/create_user.php",
        formData
      );
      dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  export const resetpass = (formData) => async (dispatch) => {
    try {
          dispatch({
            type: USER_RESET_REQUEST,               
          });

      const { data } = await Axios.post(
        "/prubli_ma/ss/reset_password_admin.php",
        formData
      );
      dispatch({ type: USER_RESET_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: USER_RESET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const exportdon = (formData) => async (dispatch) => {
  try {
        dispatch({
          type: USER_RESET_REQUEST,               
        });

    const { data } = await Axios.post(
      "/prubli_ma/ss/export_to_alass.php",
      formData
    );
    
    console.log(data.message);     

    var FileSaver = require('file-saver');
    var blob = new Blob(data.data, {type: "text/plain;charset=utf-8"});
    FileSaver.saveAs(blob, 'fileToOlass'+formData.get('datefin')+'.txt');

     dispatch({ type: USER_REGISTER_SUCCESS, payload: data });    

  } catch (error) {
    dispatch({
      type: USER_RESET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const importdon = (formData) => async (dispatch) => {
  try {
        dispatch({
          type: USER_RESET_REQUEST,               
        });

    const { data } = await Axios.post(
      "/prubli_ma/ss/import_compte.php",
      formData
    );
    
    console.log(data.message);

    dispatch({ type: USER_REGISTER_SUCCESS, payload: data }); 
    
    localStorage.setItem("message", data.message) ;

  } catch (error) {  
    dispatch({
      type: USER_RESET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const validproduction = (formData) => async (dispatch) => {
  try {
        dispatch({
          type: USER_RESET_REQUEST,               
        });

    const { data } = await Axios.post(
      "/prubli_ma/ss/valid_production.php",
      formData
    );
    
    console.log(data.message);

    dispatch({ type: USER_REGISTER_SUCCESS, payload: data }); 
    
    localStorage.setItem("message", data.message) ;

  } catch (error) {  
    dispatch({
      type: USER_RESET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deletecustomers = (formData) => async (dispatch) => {
  try {
        dispatch({
          type: USER_RESET_REQUEST,               
        });

    const { data } = await Axios.post(
      "/prubli_ma/ss/supprimer_client.php",
      formData
    );
    
    console.log('supression client userActions:'+data);

    dispatch({ type: USER_REGISTER_SUCCESS, payload: data }); 
    
    localStorage.setItem("message", data.message) ;

  } catch (error) {
    dispatch({
      type: USER_RESET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type:CLEAR_ERRORS
  })
}
export const clearSuccess = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SUCCESS,
  });
};

export const logout = (tokenData) => async (dispatch) => {
  try {
    await Axios.post("/prubli_ma/ss/deconnexion.php", tokenData);
    dispatch({
      type: USER_SIGNOUT_SUCCESS,
    });
document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  } catch (error) {
    dispatch({
      type: USER_SIGNOUT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const listUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    const { data } = await Axios.get("/prubli_ma/ss/afficher_liste_users.php");
    dispatch({ type: USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: USER_LIST_FAIL, payload: error.message });
  }
};
